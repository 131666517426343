import Row from "react-bootstrap/Row";
import "./Counters.css";
import { Counter } from "./Counter";
import { FaUsers, FaTicketAlt } from "react-icons/fa/";

import { useState, useEffect } from "react";
import { db } from "../configs/firebase";
import { collection, getCountFromServer } from "firebase/firestore";

export const DashBoard = () => {
  const [usersLength, setUsers] = useState(0);
  const [eventsLength, setEvents] = useState(0);

  //Get Stats
  useEffect(() => {
    (async () => {
      //get Events
      const eventsRef = collection(db, "events");
      const eventsSnapshot = await getCountFromServer(eventsRef);
      setEvents(eventsSnapshot.data().count);

      //Get All Users
      const usersRef = collection(db, "users");
      const usersSnapshot = await getCountFromServer(usersRef);
      setUsers(usersSnapshot.data().count);
    })();

  }, []);

  const TicketsIcon = () => (
    <FaTicketAlt
      style={{
        fontSize: "4.6rem",
        color: "#f5f5f5",
      }}
    />
  );
  const UsersIcon = () => (
    <FaUsers
      style={{
        fontSize: "4.6rem",
        color: "#f5f5f5",
      }}
    />
  );
  return (
    <Row className="counters">
      <Counter
        className="col-12 col-sm-6 col-md-6"
        variable="Events"
        quantity={eventsLength}
        styles={{
          backgroundColor: "lime",
          color: "black",
        }}
        icon={TicketsIcon()}
      />
      <Counter
        variable="Users"
        quantity={usersLength}
        className="col-sm-12 col-md-6"
        styles={{
          backgroundColor: "#ffc107",
          color: "black",
        }}
        icon={UsersIcon()}
      />
    </Row>
  );
};
