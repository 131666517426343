import React, { useState, useEffect, useContext } from 'react';
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";
import axios from 'axios';

// mui datagrid
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import usePagination from '@mui/material/usePagination';
// Mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

// context
import { ChurchContext } from '../contexts/ChurchContext';

const columns = [
   { field: 'id', headerName: 'ID', width: 90 },
   {
      field: 'name',
      headerName: 'Name',
      width: 250,
   },
   {
      field: 'price',
      headerName: 'Amount Paid',
      width: 160,
   },
   {
      field: 'paidAt',
      headerName: 'Paid At',
      width: 160,
   },
   {
      field: 'status',
      headerName: 'Payment Status',
      width: 160,
   },
   {
      field: 'reference',
      headerName: 'Reference',
      width: 200,
   },
   {
      field: 'registrationStatus',
      headerName: 'Registration Status',
      width: 200,
   },
   {
      field: 'billingEmail',
      headerName: 'Billing Email',
      width: 250,
   },
   {
      field: 'emails',
      headerName: 'Registrant(s) Email(s)',
      width: 250,
   },
   {
      field: 'phones',
      headerName: 'Registrants Phones',
      width: 250,
   },
   {
      field: 'numberOfTickets',
      headerName: 'Number of Tickets',
      width: 250,
   },
   {
      field: 'registeredVia',
      headerName: 'Registered Via',
      width: 250,
   }
];

export const PaystackTransactionsTable = ({ selectedEvent, registrants, successfulRegistrations }) => {
   const PAYSTACK_PUBLIC_API_KEY = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
   const PAYSTACK_SECRET_API_KEY = process.env.REACT_APP_PAYSTACK_SECRET_KEY;
   const PAYSTACK_BASE_URL = process.env.REACT_APP_PAYSTACK_BASE_URL;

   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const { church } = useContext(ChurchContext);

   const [transactions, setTransactions] = useState(null);
   const [transactionsPerPage, setTransactionsPerPage] = useState(25);
   const [from, setFrom] = useState('2023-02-10'); //TODO: Default to current date
   const [to, setTo] = useState(new Date().toLocaleDateString().replace(/\//g, '-'));  //TODO: Default to current date
   const [rows, setRows] = useState([]);

   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);

   const [paginationModel, setPaginationModel] = useState({
      pageSize: 20,
      page: 0,
   });

   const fetchTransactions = async () => {
      setLoading(true);
      setError(null);
      let url = `${PAYSTACK_BASE_URL}/transaction/?perPage=${transactionsPerPage}`;
      console.log(url);
      if (from) {
         url = `${PAYSTACK_BASE_URL}/transaction/?perPage=${transactionsPerPage}&from=${from}&to=${to}`;
      }
      try {
         axios.get(url, {
            headers: {
               'Authorization': `Bearer ${PAYSTACK_SECRET_API_KEY}`
            }
         }).then(response => {
            let transactionsData = response.data.data.map((transaction, index) => {
               console.log({ transaction });
               let paidAt = new Date(transaction.paidAt);
               let paidAtDate = paidAt.toLocaleDateString();
               let paidAtTime = paidAt.toLocaleTimeString();
               let paidAtDateTime = `${paidAtDate} ${paidAtTime}`;
               let paidAtOG = transaction.paidAt;

               let amountPaid = Intl
                  .NumberFormat('en-US', { style: 'currency', currency: selectedEvent?.currency?.ticker })
                  .format(transaction.amount / 100);
               let transctionFee = Intl
                  .NumberFormat('en-US', { style: 'currency', currency: selectedEvent?.currency?.ticker })
                  .format(transaction.fees / 100);

               let status = transaction.status === 'success' ? 'Successful' : 'Failed';
               let billingName = transaction.customer?.first_name + ' ' + transaction.customer?.last_name;
               let billingEmail = transaction.customer?.email;

               let registrationStatus = successfulRegistrations
                  ?.map(registration => {
                     return registration?.reference;
                  })
                  ?.includes(transaction.reference) ? 'Successful' : 'Failed';

               let customField = {};

               transaction
                  .metadata
                  ?.custom_fields
                  .filter((field, index) => {
                     return field?.value;
                  })
                  .map((field, index) => {
                     return customField[field.variable_name] = field.value;
                  });

               return {
                  id: index + 1,
                  name: billingName,
                  price: amountPaid,
                  status: status,
                  billingEmail: billingEmail,
                  emails: customField?.registrant_emails ? customField?.registrant_emails : "N/A",
                  phones: customField?.registrant_phone ? customField?.registrant_phone : "N/A",
                  billingPhone: customField?.registrant_phone?.split(',')[0] || "N/A",
                  numberOfTickets: customField?.number_of_registrants || "N/A",
                  registeredVia: transaction?.source?.type || "N/A",
                  cardDetails: transaction.authorization,
                  paidAt: paidAtDateTime,
                  customField: customField,
                  transactionFee: transctionFee || "N/A",
                  paidAtOG: paidAtOG,
                  reference: transaction.reference,
                  registrationStatus: registrationStatus,
               }
            });
            console.log({ transactionsData })
            setTransactions(transactionsData.sort((a, b) => new Date(b.paidAtOG) - new Date(a.paidAtOG)));
            setTransactions(transactionsData);
         }).catch(error => {
            console.error(error);
         });
      } catch (error) {
         setError(error);
         enqueueSnackbar(error.message, { variant: "error" });
      }
      setLoading(false);
   };

   useEffect(() => {
      fetchTransactions();
   }, [selectedEvent]);

   function CustomToolbar() {
      return (
         <GridToolbarContainer>
            <GridToolbarExport />
         </GridToolbarContainer>
      );
   }

   function NoRowsOverlay() {
      return (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <h3>No Transactions</h3>
         </div>
      );
   }

   if (transactions === null) {
      return (
         <Container
            style={{
               textAlign: "center",
               alignItems: "center",
               justifyContent: "center",
               height: "100vh",
               display: "flex",
            }}
         >
            <ReactLoading type={"bubbles"} color={"blue"} height={100} width={100} />
         </Container>
      );
   }

   return (
      <div>
         <h1>Paystack Transactions Table</h1>
         <DataGrid
            rows={transactions}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            autoHeight={true}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            density="comfortable"
            components={{
               Toolbar: CustomToolbar,
               NoRowsOverlay: NoRowsOverlay,
            }}
            pageSize={7}
            compomentProps={{
               toolbar: {
                  value: "Export To Csv",
                  onClick: () => { console.log("Export to csv") },
                  style: { backgroundColor: "red" },
                  csvOptions: {
                     fileName: `${selectedEvent ? selectedEvent?.title : ""} Transactions`,
                     allColumns: true,
                     onlySelected: true,
                     separator: ",",
                     ignoreHeader: false,
                     withBOM: true,
                     utf8WithBom: true,
                     exportMode: "selected",
                  },
               },
            }}
            sx={{
               boxShadow: 2,
               '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
               },
               minHeight: 400,
            }}
         />
      </div >
   )
}