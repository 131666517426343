import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "../../components/css/ResourcesList.css";
import "../../components/css/CoursesGrid.css";
import { AddConnectGroup } from "../forms/AddConnectGroup";
import { ConnectGroupCard } from "../../components/ConnectGroupCard";
import { AddMember } from "../forms/AddMember";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

import { useState, useEffect } from "react";
import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const ConnectGroups = () => {
  const [connectGroups, setConnectGroups] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/ConnectGroups");
    } else {
      navigate("/Login");
    }
  }, [navigate]);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "connect-groups"),
      (snapshot) => {
        const connectGroupsList = snapshot.docs.map((connectGroupItem) => {
          return {
            ...connectGroupItem.data(),
            id: connectGroupItem.id,
          };
        });
        setConnectGroups(connectGroupsList);
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <>
      <ResponsiveNavbar />
      <Container fluid style={{
        marginTop: "5%",
      }}>
        <Row>
          <Col>
            <h3 className="header text-center">Add Connect Group</h3>
            <AddConnectGroup />
          </Col>
          {/* <Col
            style={{
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <h3 className="header text-center">Add Member</h3>
            <AddMember connectGroups={connectGroups} />
          </Col> */}
          <Col>
            <h3 className="header text-center">Connect Groups</h3>
            <div
              style={{
                overflowY: "scroll",
                width: "80%",
                height: "90vh",
                marginTop: "50px",
                marginBottom: "1rem",
                marginLeft: "10%",
                padding: "5%",
                backgroundColor: "white",
                borderRadius: "10px",
                borderTop: "1px solid #0000ff",
                borderBottom: "1px solid #0000ff",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              }}
              className="removeScollBar courses-grid"
            >
              {connectGroups &&
                connectGroups.map((connectGroup) => {
                  return (
                    <ConnectGroupCard
                      connectGroup={connectGroup}
                      key={connectGroup.id}
                      id={connectGroup.id}
                      connectGroups={connectGroups}
                      style={{
                        marginTop: "0.5rem",
                        height: "200px",
                        borderColor: "#00bc",
                        borderWidth: "1px",
                      }}
                    />
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
