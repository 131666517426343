import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { InformationPopover } from "../../components/InformationPopover";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Expo } from "expo-server-sdk";
import { useSnackbar } from "notistack";

import { Timestamp, collection, addDoc, onSnapshot } from "firebase/firestore";
import { db, auth, storage } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const MySwal = withReactContent(Swal);

// registerLocale("en", en);
export const AddNotification = ({
  roles,
  setRoles,
  selectedRoles,
  setSelectedRoles,
  selectedUsers,
  setSelectedUsers,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [notification, setNotification] = useState({
    title: "",
    message: "",
    image: null,
    data: {
      notificationLink: null,
      linkTitle: null,
    },
    targetUsers: [],
    createdAt: null,
    scheduledTime: null,
  });

  let imageFile = null;
  let coverPhotoRef = useRef(null);
  const [coverPhotoUploadProgress, setCoverPhotoUploadProgress] = useState(null);

  // useEffect(() => {
  //   setNotification({
  //     ...notification,
  //     roles: selectedRoles,
  //   });
  // }, [selectedRoles, notification]);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "tags") {
      setSelectedRoles([...selectedRoles]);
    } else if (e.target.name === "title") {
      setNotification({
        ...notification,
        title: e.target.value,
      });
    } else {
      setNotification({
        ...notification,
        [e.target.name]: e.target.value,
      });
    }
  };

  //get All users
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersList = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          categories: doc.data().categories,
          name: doc.data().name,
          surname: doc?.data()?.surname,
          expoPushToken: doc?.data()?.expoPushNotificationToken?.token,
        };
      });
      setUsers(usersList);
    });
    return () => unsubscribe();
  }, []);

  const submitNotification = async (event) => {
    event.preventDefault();
    const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
    try {
      await addDoc(collection(db, "notifications"), {
        ...notification,
        createdAt: date || null,
        createdBy: auth.currentUser.uid,
        scheduledTime: new Date(notification?.scheduledTime) || null,
      }).then(() => {

        const pushTokens = users.map((user) => user.expoPushToken);
        const expo = new Expo();
        const messages = [];
        pushTokens.forEach((pushToken) => {
          if (Expo.isExpoPushToken(pushToken)) {
            messages.push({
              to: pushToken,
              sound: "default",
              title: notification.title,
              body: `${notification.message}`,
              priority: "high",
              data: {
                ...notification.data,
              }
            });
          } else {
            console.error(`Push token ${pushToken} is not a valid Expo push token`);
          }
        });

        let chunks = expo.chunkPushNotifications(messages);
        let tickets = [];

        (async () => {
          for (let chunk of chunks) {
            try {
              let ticketChunk = await expo.sendPushNotificationsAsync(chunk);
              tickets.push(...ticketChunk);
            } catch (error) {
              console.log("Sending notification error");
              console.log(error);
            }
          }
        })();

        let receiptIds = [];
        for (let ticket of tickets) {
          // NOTE: Not all tickets have IDs; for example, tickets for notifications
          // that could not be enqueued will have error information and no receipt ID.
          if (ticket.id) {
            receiptIds.push(ticket.id);
          }
        }

        let receiptIdChunks = expo.chunkPushNotificationReceiptIds(receiptIds);
        (async () => {
          // Like sending notifications, there are different strategies you could use
          // to retrieve batches of receipts from the Expo service.
          for (let chunk of receiptIdChunks) {
            try {
              let receipts = await expo.getPushNotificationReceiptsAsync(chunk);

              // The receipts specify whether Apple or Google successfully received the
              // notification and information about an error, if one occurred.
              for (let receiptId in receipts) {
                let { status, message, details } = receipts[receiptId];
                if (status === 'ok') {
                  continue;
                } else if (status === 'error') {
                  console.error(
                    `There was an error sending a notification: ${message}`
                  );
                  if (details && details.error) {
                    // The error codes are listed in the Expo documentation:
                    // https://docs.expo.io/push-notifications/sending-notifications/#individual-errors
                    // You must handle the errors appropriately.
                    console.error(`The error code is ${details.error}`);
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        })();
      });
      MySwal.fire({
        title: "Success!",
        text: "Notification Sent!",
        icon: "success",
        confirmButtonText: "DONE",
      });
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "CLOSE",
      });
      console.log(err);
    }
  };

  async function handleCoverPhotoUpload(file) {
    if (!file) {
      MySwal.fire({
        title: "Warning!",
        text: "Please select a cover image",
        icon: "warning",
        confirmButtonText: "CLOSE",
      });
      return;
    }

    if (file.size > 1000000) {
      enqueueSnackbar("Image file size should be less than 1MB", {
        variant: "warning",
      });
      return;
    }
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `notifications/cover-images/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setCoverPhotoUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setNotification({
            ...notification,
            image: downloadURL,
          });

          enqueueSnackbar("Cover photo uploaded successfully", {
            variant: "success",
          });
          setCoverPhotoUploadProgress(null);
        });
      }
    );
  };

  return (
    <Form
      style={{
        width: "80%",
        height: "80vh",
        marginTop: "50px",
        marginBottom: "50px",
        marginLeft: "10%",
        padding: "5%",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        submitNotification(e);
      }}
    >
      <Row>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput1">
          {/* <RolesSelection
            roles={roles}
            setRoles={setRoles}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setSelectedRoles={setSelectedRoles}
            onSubmit={submitNotification}
          /> */}
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Title
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={notification.title}
            title="title"
            name="title"
            placeholder="title"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Scheduled Time
          </Form.Label>
          <Form.Control
            type="datetime-local"
            defaultValue={notification.scheduledTime}
            title="scheduledTime"
            placeholder="scheduledTime"
            name="scheduledTime"
            onChange={handleChange}
          />
        </Form.Group>

        {
          coverPhotoUploadProgress ? (<ProgressBar now={coverPhotoUploadProgress} label={`${parseInt(coverPhotoUploadProgress, 10)}%`} />) : null
        }
        <Form.Group className="mb-3" >
          <Form.Label style={{
            color: "black"
          }}>Cover Photo in Landscape</Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          <input
            required
            ref={coverPhotoRef}
            encType="multipart/form-data"
            type="file"
            name="coverImage"
            id="coverImage"
            accept="image/*"
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #000",
              borderRadius: "5px",
              padding: "10px",
              color: "#000",
              fontSize: "16px",
              outline: "none",
              cursor: "pointer"
            }}
            onChange={e => {
              imageFile = e.target.files[0];
              handleCoverPhotoUpload(imageFile);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Message
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={8}
            name="message"
            defaultValue={notification.message}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          variant="primary"
          type={"submit"}
          disabled={notification.image ? false : true}
          className="btn btn-block"
          style={{
            width: "50%",
            height: "10%",
            fontSize: "1.2rem",
            marginLeft: "49%",
          }}>
          Send
        </Button>
      </Row>
    </Form>
  );
};