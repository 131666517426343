import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Container from '@mui/material/Container';
import { useMediaQuery } from 'react-responsive'

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

import { getNames } from '../helpers/Validators';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import uuid from 'react-uuid';

export const ComboBox = ({ dataList, label, selectedValue, setSelectedValue }) => {
   const [inputValue, setInputValue] = useState('');
   const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
   const colors = [
      "FF0000",
      "FF7F00",
      "00FF00",
      "0000FF",
      "4B0082",
      "8B00FF",
   ];
   return (
      <Autocomplete
         disablePortal
         disableClearable={false}
         id="combo-box-demo"
         options={dataList}
         value={selectedValue}
         onChange={(event, newValue) => {
            setSelectedValue(newValue);
            console.log({ newValue });
         }}
         inputValue={inputValue}
         onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            console.log({ newInputValue });
         }}
         sx={{ width: "100%" }}
         renderInput={(params) => <TextField {...params} label={label} />}
         getOptionLabel={(option) => option.label}
         getOptionSele
         renderOption={(props, option) => {
            const { firstName, lastName, fullName } = getNames(option);
            let color = colors[Math.floor(Math.random() * colors.length)];
            return (
               <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <UserComponent
                     firstName={firstName}
                     lastName={lastName}
                     fullName={fullName}
                     id={option.id}
                     email={option.email}
                     avatarUrl={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${color}&color=fff`}
                     color={color}
                  />
               </Box>
            )
         }}
         clearIcon={<ClearAllIcon color="primary" />}
         clearOnEscape
         autoComplete
         autoHighlight
         autoSelect
      />
   );
}

const UserComponent = ({ firstName, lastName, fullName, id, email, avatarUrl, color }) => {
   return (
      <Container
         sx={{
            borderRadius: 2,
            backgroundColor: color,
         }}
      >
         <ListItem
            key={id}
         >
            <ListItemAvatar>
               <Avatar alt="Full Name" src={avatarUrl} />
            </ListItemAvatar >
            <ListItemText
               primary={fullName}
               secondary={email}
            />
         </ListItem >
      </Container >
   )
};
