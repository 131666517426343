import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { InformationPopover } from "../../components/InformationPopover";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { SelectEvent } from "../../components/SelectEvent";

import { useSnackbar } from "notistack";

import { collection, addDoc, serverTimestamp, onSnapshot, query, orderBy } from "firebase/firestore";
import { db, auth, storage } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const MySwal = withReactContent(Swal);

export const AddBanner = () => {
   const { enqueueSnackbar } = useSnackbar();
   const [banner, setBanner] = useState({
      campainName: "",
      image: null,
      link: {
         buttonTitle: "",
         eventId: null,
         navigateToEvent: false,
         navigateToWebPage: false,
         url: null,
      },
      isActive: true,
      seenBy: [],
      createdAt: null,
      startTime: null,
      endTime: null,
      showAt: [],
   });

   const [events, setEvents] = useState([]);
   const [selectedEvent, setSelectedEvent] = useState(null);

   let imageFile = null;
   let coverPhotoRef = useRef(null);
   const [coverPhotoUploadProgress, setCoverPhotoUploadProgress] = useState(null);

   const handleChange = (e) => {
      e.preventDefault();
      if (e.target.name === "startTime" || e.target.name === "endTime") {
         setBanner({
            ...banner,
            [e.target.name]: new Date(e.target.value),
         });
      } else if (e.target.name === "campaignName") {
         setBanner({
            ...banner,
            campainName: e.target.value,
         });
      } else if (e.target.name === "url") {
         setBanner({
            ...banner,
            link: {
               ...banner.link,
               url: e.target.value,
            },
         });
      } else if (e.target.name === "buttonTitle") {
         if (e.target.value.length <= 15) {
            setBanner({
               ...banner,
               link: {
                  ...banner.link,
                  buttonTitle: e.target.value,
               },
            });
         } else {
            enqueueSnackbar("Button title cannot be more than 15 characters long.", { variant: "error" });
         }

      } else if (e.target.name === "eventId") {
         setBanner({
            ...banner,
            link: {
               ...banner.link,
               eventId: e.target.value,
            },
         });
      } else if (e.target.name === "navigateToEvent") {
         setBanner({
            ...banner,
            link: {
               ...banner.link,
               navigateToEvent: e.target.checked,
            },
         });
      } else if (e.target.name === "navigateToWebPage") {
         setBanner({
            ...banner,
            link: {
               ...banner.link,
               navigateToWebPage: e.target.checked,
            },
         });
      } else {
         setBanner({
            ...banner,
            [e.target.name]: e.target.value,
         });
      }
   };

   const submitNotification = async (event) => {
      event.preventDefault();
      try {
         await addDoc(collection(db, "banners"), {
            ...banner,
            createdAt: serverTimestamp(),
            createdBy: auth.currentUser.uid
         })
            .then(() => {
               setBanner({
                  campainName: "",
                  image: null,
                  link: {
                     buttonTitle: "",
                     eventId: null,
                     navigateToEvent: false,
                     navigateToWebPage: false,
                     url: null,
                  },
                  isActive: true,
                  seenBy: [],
                  createdAt: null,
                  startTime: null,
                  endTime: null,
                  showAt: [],
               });
               coverPhotoRef.current.value = "";
               setCoverPhotoUploadProgress(null);
            });
         MySwal.fire({
            title: "Campaign Created!",
            text: "Your campaign has been created successfully and will be sent to users in the range of time selected.",
            icon: "success",
            confirmButtonText: "DONE",
         });
      } catch (err) {
         MySwal.fire({
            title: "Campaign Creation Failed!",
            text: err.message,
            icon: "error",
            confirmButtonText: "CLOSE",
         });
         console.log(err);
      }
   };

   async function handleCoverPhotoUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a cover image",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 2000000) {
         enqueueSnackbar("Image file size should be less than 2MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `banner-campaigns/cover-images/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setCoverPhotoUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setBanner({
                  ...banner,
                  image: downloadURL,
               });

               enqueueSnackbar("Banner image uploaded successfully", {
                  variant: "success",
               });
               setCoverPhotoUploadProgress(null);
            });
         }
      );
   };

   useEffect(() => {
      const q = query(collection(db, "events"), orderBy("createdAt", "desc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
         const events = [];
         querySnapshot.forEach((doc) => {
            events.push({ ...doc.data(), id: doc.id });
         });
         setEvents(events);
      });
      return () => {
         unsubscribe();
      };
   }, []);

   return (
      <Form
         style={{
            width: "80%",
            height: "80vh",
            marginTop: "50px",
            marginBottom: "50px",
            marginLeft: "10%",
            padding: "5%",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
         }}
         onSubmit={(e) => {
            e.preventDefault();
            submitNotification(e);
         }}
      >
         <Row>
            <Form.Group
               className="mb-3"
               controlId="exampleForm.ControlInput1">
               <Form.Label
                  style={{
                     color: "black",
                     fontSize: "1.1rem",
                     fontWeight: "500",
                  }}
               >
                  Campaign Name
               </Form.Label>
               <Form.Control
                  type="text"
                  defaultValue={banner.title}
                  title="campaignName"
                  name="campaignName"
                  placeholder="Campaign Name"
                  onChange={handleChange}
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label
                  style={{
                     color: "black",
                     fontSize: "1.1rem",
                     fontWeight: "500",
                  }}
               >
                  Campaign Starts On
               </Form.Label>
               <Form.Control
                  type="datetime-local"
                  defaultValue={banner.startTime}
                  title="startTime"
                  placeholder="Campaign Starts On"
                  name="startTime"
                  onChange={handleChange}
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label
                  style={{
                     color: "black",
                     fontSize: "1.1rem",
                     fontWeight: "500",
                  }}
               >
                  Campaign Ends On
               </Form.Label>
               <Form.Control
                  type="datetime-local"
                  defaultValue={banner.endTime}
                  title="endTime"
                  placeholder="Campaign Ends On"
                  name="endTime"
                  onChange={handleChange}
               />
            </Form.Group>

            {
               coverPhotoUploadProgress ? (<ProgressBar now={coverPhotoUploadProgress} label={`${parseInt(coverPhotoUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3" >
               <Form.Label style={{
                  color: "black"
               }}>Banner Image</Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={coverPhotoRef}
                  encType="multipart/form-data"
                  type="file"
                  name="coverImage"
                  id="coverImage"
                  accept="image/*"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     imageFile = e.target.files[0];
                     handleCoverPhotoUpload(imageFile);
                  }}
               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black",
                     fontSize: "1.1rem",
                     fontWeight: "500",
                  }}
               >
                  Button Title
               </Form.Label>
               <Form.Control
                  required
                  type="text"
                  name="buttonTitle"
                  defaultValue={banner?.link?.buttonTitle}
                  onChange={handleChange}
               />
            </Form.Group>
         </Row>
         <SelectEvent
            events={events}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
         />
         <Row style={{ marginTop: '5%' }}>
            <Button
               variant="primary"
               type={"submit"}
               disabled={banner.image ? false : true}
               className="btn btn-block"
               style={{
                  width: "50%",
                  height: "10%",
                  fontSize: "1.2rem",
                  marginLeft: "49%",
               }}>
               Send
            </Button>
         </Row>
      </Form>
   );
};