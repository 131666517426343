import React, { useState, useEffect, useContext } from 'react';

import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'
import ReactLoading from "react-loading";
import { NoEvents } from '../../components/NoEvents';
import { EventBookingForm } from '../forms/EventBookingForm';

// firebase
import { db } from "../../configs/firebase";
import { onSnapshot, collection } from "firebase/firestore";

// context
import { ChurchContext } from "../../contexts/ChurchContext";


export const EventBooking = () => {
   const { church } = useContext(ChurchContext);
   const [events, setEvents] = useState(null);

   const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
   const isBigScreen = useMediaQuery({ minWidth: 1824 })
   const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
   const isPortrait = useMediaQuery({ orientation: 'portrait' })
   const isRetina = useMediaQuery({ minResolution: '2dppx' })


   useEffect(() => {
      const unsubscribe = onSnapshot(collection(db, "events"), (snapshot) => {
         const eventsList = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

         // filter events to events that have a startingTime that is greater than the current time
         const filteredEvents = eventsList.filter((event) => {
            const currentTime = new Date().getTime();
            const eventTime = event.startingTime.toDate().getTime();
            return eventTime > currentTime;
         });

         console.log(filteredEvents);
         // sort by closest event first
         filteredEvents.sort((a, b) => {
            const aTime = a.startingTime.toDate().getTime();
            const bTime = b.startingTime.toDate().getTime();
            return aTime - bTime;
         });
         setEvents(filteredEvents);
      });

      return () => unsubscribe();
   }, []);

   if (events === null) {
      return (
         <Container
            fluid
            style={{
               textAlign: "center",
               alignItems: "center",
               justifyContent: "center",
               height: "100vh",
               display: "flex",
            }}
         >
            <ReactLoading type={"bubbles"} color={"blue"} height={100} width={100} />
         </Container>
      );
   }

   if (events.length === 0) {
      return <NoEvents />;
   }

   return (
      <Container
         fluid
         style={{
            width: isDesktopOrLaptop ? "60%" : "100%",
            marginTop: "5%",
         }}
         className="lg-"
      >
         <EventBookingForm events={events} />
      </Container>
   );
};


