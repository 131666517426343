const data = (data) => `
 <html>
 <head>
 </head>
 <body style="width: 70%;">
 <div style="background-color: #f5f5f5; padding: 10px; text-align: center;">
    <a href="${data.churchWebsite}">
       <img src="${data.churchLogo}" alt="${data.churchName} Logo" style="height: 100px;">
    </a>
    <h2 style="color: #444;">${data.churchName}</h2>
 </div>
 <p style="font-size: 16px; font-weight: 600; margin-bottom: 5px;">Hi, ${data.registrantName},</p>
 <p style="font-size: 16px; margin-bottom: 5px;">
 Thank you for registering for the ${data.eventTitle}. 
 We cannot wait to see you there! 
 Please find your ticket details below for reference. This is only a virtual ticket and will not be mailed to you.
 </p>
 <div style="background-color: tomato; padding: 20px; width: 300px; margin: 0 auto; border-radius: 10px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); font-family: sans-serif;">
 <img style="display: block; margin: auto; margin-bottom: 20px; width: 100px; height: auto;" src="${data.churchLogo}" alt="Church Logo">
 
 <h2 style="text-align: center; font-size: 24px; font-weight: bold; margin-bottom: 10px;">${data.eventTitle.toUpperCase()}</h2>
 
 <ul style="list-style: none; align-self: center; margin: auto 0;">
 <li style="margin-bottom: 5px; font-size: 24px; font-weight: 600; font-family: 'Courier New', monospace;">${data.eventDate}</li>
 <li style="margin-bottom: 5px; font-size: 24px; font-weight: 600; font-family: 'Courier New', monospace;">${data.eventTime}</li>
 <li style="margin-bottom: 5px; font-size: 16px;">${data.eventLocation}</li>
 </ul>
 
 <img style="display:block; margin-top: 20px; width: 250px; height: auto; margin-left: auto; margin-right: auto; align-self: center;" src="${data.qrSource}" alt="QR Code">
 <p style="margin-bottom: 5px; font-size: 16px; font-weight: 600; font-style: italics;">${data.ticketNumber}</p>
 <p style="margin-top: 30px; text-align: center; font-size: 12px;">Please present this ticket at the entrance.</p>
 </div>
 <div class="footer">
    <p>Best Regards,</p>
    <p>${data.churchName} Team</p>
    <div style="background-color: #f5f5f5; padding: 10px; text-align: left; margin-top: 20px;">
       <p style="color: #555;">${data.churchName}</p>
       <p style="color: #555;">${data.churchAddress}</p>
       <p style="color: #555;">${data.churchPhone}</p>
       <p style="color: #555;">${data.churchEmail}</p>
       <p style="color: #555;">&copy; ${new Date().getFullYear()} ${data.churchName}. All rights reserved.</p>
    </div>
    </div>
 </body>
 </html>
 `;

export const TicketHtml = (ticketData) => {
   return data(ticketData);
}