import React, { useEffect } from 'react';
import { TicketHtml } from './ticket/TicketHtml';
import { useNavigate, useLocation } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { useSnackbar } from "notistack";

// Mui
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { jsPDF } from "jspdf";


export const PaymentSuccess = () => {
   const { state } = useLocation();
   const { enqueueSnackbar } = useSnackbar();
   console.log(state)
   const navigate = useNavigate();
   useEffect(() => {
      if (state.isPaid) {
         // const doc = new jsPDF("portrait", "px", "a4");
         // if (state.registrants.length > 1) {
         //    let tickets = document.querySelector("#tickets");
         //    doc.html(tickets, {
         //       callback: function (doc) {
         //          doc.save("tickets.pdf");
         //       },
         //       x: 0,
         //       y: 0
         //    });
         // } else {
         //    let ticket = document.querySelector("#ticket");
         //    doc.html(ticket, {
         //       callback: function (doc) {
         //          doc.save("ticket.pdf");
         //       },
         //       x: 0,
         //       y: 0
         //    });
         // }
      } else {
         alert("Payment not successful")
         navigate("/EventBooking")
      }
   }, [state.isPaid, navigate])
   return (
      <div id={state.registrants > 1 ? "tickets" : "ticket"}>

         {
            state.registrants && state.registrants.map((registrant, index) => {
               let qrData = registrant.ticketNumber + ":" + registrant.transactionId + ":" + state.selectedEvent.id;
               let qrSource = `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${qrData}`;
               let date = new Timestamp(state.selectedEvent.startingTime.seconds, state.selectedEvent.startingTime.nanoseconds).toDate();
               let time = new Timestamp(state.selectedEvent.startingTime.seconds, state.selectedEvent.startingTime.nanoseconds).toDate().toLocaleTimeString();
               return (
                  <TicketHtml
                     data={registrant.ticketNumber + registrant.transactionId}
                     qrSource={qrSource}
                     eventCoverImage={state.selectedEvent.image}
                     eventTitle={state.selectedEvent.title}
                     eventDate={date}
                     eventTime={time}
                     eventLocation={state.selectedEvent.venue.name}
                     eventPrice={registrant.price}
                     eventTotalPrice={registrant.price}
                     numberOfTickets={"1"}
                     ticketNumber={registrant.ticketNumber}
                     churchName={state.church.name}
                     churchAddress={state.selectedEvent.venue.address}
                     key={index}
                  />
               )
            })
         }
      </div>
   )
}