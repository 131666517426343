import React, { useState, useEffect, useRef } from 'react';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from "react-bootstrap/Button";

import { Timestamp, collection, addDoc, onSnapshot, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db, auth, storage } from "../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { useSnackbar } from "notistack";

export const DocumentUploader = ({ state, setState, stateVariable, documentType }) => {
   const { enqueueSnackbar } = useSnackbar();
   const MySwal = withReactContent(Swal);

   let documentFile = null;
   let documentRef = useRef(null);
   const [documentUploadProgress, setDocumentUploadProgress] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);

   const handleChange = async (e) => {
      console.log(e.target.name);
      if (e.target && e.target.name === "document") {
         documentFile = e.target.files[0];
         console.log(documentFile);

         //if the file size is greater than 10MB, show error
         if (documentFile.size > 10000000) {
            enqueueSnackbar("Cover image size must be less than 10MB", {
               variant: "error",
            });
            return;
         }

         // return if the file type doesn't match documentType
         if (!documentFile.type.includes(documentType)) {
            enqueueSnackbar(`File type must be ${documentType}`, {
               variant: "error",
            });
            return;
         }

         if (documentFile) {
            await handleDocumentUpload(documentFile);
         }
      }
   };

   async function handleDocumentUpload(file) {
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `files/auto-uploaded/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setDocumentUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setState({
                  ...state,
                  [stateVariable]: downloadURL,
               });

               if (documentType === "image") {
                  setImageUrl(downloadURL);
               }
               enqueueSnackbar("File uploaded successfully", {
                  variant: "success",
               });
               setDocumentUploadProgress(null);
            });
         }
      );
   };

   return (
      <div>
         <div className="mb-3">
            <label htmlFor="document" className="form-label">
               {stateVariable === "coverImage" ? "Cover Image" : "Document"}
            </label>
            <input
               className="form-control"
               type="file"
               id="document"
               name="document"
               onChange={handleChange}
            />
         </div>
         {
            imageUrl ? (
               <div style={{
                  display: "flex",
                  flexDirection: "column",
               }}>
                  <img src={imageUrl} alt="cover" style={{
                     height: "5%",
                     marginBottom: "1%",
                     marginTop: "2%",
                     borderRadius: "5px",
                     width: "20%"
                  }} />
                  <Button variant="danger" onClick={() => {
                     setState({
                        ...state,
                        [stateVariable]: null
                     })
                     setImageUrl(null);
                  }
                  }
                     style={{
                        width: "20%",
                     }}
                  >
                     Remove
                  </Button>
               </div>) : null}
         {documentUploadProgress && (
            <ProgressBar
               animated
               now={documentUploadProgress}
               label={`${documentUploadProgress}%`}
            />
         )}
      </div>
   );
};
