import { useState, useEffect } from "react";
// import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

// import { NavigationBar } from "../../components/NavigationBar";
// import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { AddDevotional } from "../../components/AddDevotional";
// import { db } from "../../configs/firebase";
// import { collection, onSnapshot } from "firebase/firestore";

export const Devotionals = () => {

   const navigate = useNavigate();
   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/Devotionals");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   return (
      <Container
         style={{
            position: "relative"
         }}
      >
         <h1>Devotions</h1>
         <AddDevotional />
      </Container>
   )
}