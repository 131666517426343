import { useState } from "react";
import Select from "react-select";

const roleOptions = [
  { value: "Admin", label: "Admin" },
  { value: "Leaders", label: "Leader" },
  { value: "General", label: "General" },
  { value: "Connect Group Leaders", label: "Connect Group Leader" },
  { value: "Intercessors", label: "Intercessor" }
];

export const RolesSelection = ({ setSelectedRoles, selectedRoles }) => {
  const [roles, setRoles] = useState([]);


  const handleChange = (selected) => {
    setRoles([...selected.map((role) => role.value)]);
    setSelectedRoles([...selected.map((role) => role.value)]);
  };
  return (
    <Select
      isMulti
      onChange={handleChange}
      options={roleOptions}
      name="roles"
      isClearable={true}
      isSearchable={true}
      backspaceRemovesValue={true}
      captureMenuScroll={true}
      autoFocus={true}
      required
      placeholder="Add Roles"
    />
  );
};
