import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { Form, Button, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Select from 'react-select';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";

import { storage } from '../../configs/firebase';
import { db, auth } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, onSnapshot, doc, updateDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const GrowthTracks = () => {
   const { enqueueSnackbar } = useSnackbar();
   const [resourceUrl, setResourceUrl] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);

   let resourceFile = null;
   let imageFile = null;

   const coverPhotoRef = useRef(null);
   const resourceRef = useRef(null);

   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");

   const [resourceUploadProgress, setResourceUploadProgress] = useState(null);
   const [imageUploadProgress, setImageUploadProgress] = useState(null);

   const [growthTracks, setGrowthTracks] = useState([]);
   const [selectedGrowthTrack, setSelectedGrowthTrack] = useState(null);
   const [selectedGrowthTrackResources, setSelectedGrowthTrackResources] = useState([]);

   const navigate = useNavigate();
   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/GrowthTracks");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   // Get growth tracks from firebase
   useEffect(() => {
      const unsubscribe = onSnapshot(collection(db, "growth-tracks"), (snapshot) => {
         let growthTracksList = snapshot.docs.map((doc) => {
            return { label: doc.data().title, value: doc.id, ...doc.data() };
         });
         setGrowthTracks(growthTracksList);
      });
      return () => {
         unsubscribe();
      };
   }, []);


   const handleSubmit = async (e) => {
      e.preventDefault();
      if (title === "" || description === "") {
         MySwal.fire({
            title: "Warning!",
            text: "Please fill all the fields",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      let resource = {
         title: title,
         description: description,
         date: new Date(),
         resourceLink: resourceUrl,
         coverImage: imageUrl,
      };

      console.log([resource, ...selectedGrowthTrackResources])

      if (resourceUrl && imageUrl) {
         console.log("Creating Doc")

         // Update the track document
         await updateDoc(doc(db, "growth-tracks", selectedGrowthTrack.value), {
            resources: [...selectedGrowthTrackResources, resource],
         }).then(() => {
            MySwal.fire({
               title: "Success!",
               text: "Resource Published Successfully!",
               icon: "success",
               confirmButtonText: "CLOSE",
            });
         })
            .catch((error) => {
               console.log(error);
               MySwal.fire({
                  title: "Failed To Publish Resource",
                  text: error.message,
                  icon: "error",
                  confirmButtonText: "CLOSE",
               });
            });

         // Clear the form
         setTitle("");
         setDescription("");
         setResourceUrl(null);
         setImageUrl(null);
         coverPhotoRef.current.value = "";
         resourceRef.current.value = "";
         window.location.reload();
      } else {
         MySwal.fire({
            title: "File Upload Failed",
            text: "Please try again later or contact support for assistance.",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
      }
   };

   async function handleCoverPhotoUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a cover image",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 1000000) {
         enqueueSnackbar("Image file size should be less than 1MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `growth-tracks/cover-images/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setImageUrl(downloadURL);

               enqueueSnackbar("Cover photo uploaded successfully", {
                  variant: "success",
               });
               setImageUploadProgress(null);
            });
         }
      );
   };

   async function handleResourceUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select file",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 100000000) {
         enqueueSnackbar("file size should be less than 100MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `growth-tracks/auto-uploaded/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setResourceUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setResourceUrl(downloadURL);

               enqueueSnackbar("Resource uploaded successfully", {
                  variant: "success",
               });

               setResourceUploadProgress(null);
            });
         }
      );
   };

   const handleTrackChange = (selected) => {
      // event.preventDefault();
      setSelectedGrowthTrack(selected);
      setSelectedGrowthTrackResources(selected.resources);
   };
   return (
      <>
         <ResponsiveNavbar />
         <Form
            onSubmit={handleSubmit}
            style={{
               width: "50%",
               margin: "10% auto",
               padding: "3%",
               backgroundColor: "white",
               borderRadius: "10px",
               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
         >
            <h3 className="text-center mt-1">Add Resourses To Tracks</h3>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Select Track
               </Form.Label>
               <Select
                  options={growthTracks}
                  isClearable={true}
                  isSearchable={true}
                  backspaceRemovesValue={true}
                  captureMenuScroll={true}
                  autoFocus={true}
                  required
                  onChange={handleTrackChange}
                  placeholder="Select Track"
                  name="trackId"
               />
            </Form.Group>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Resource Title
               </Form.Label>
               <Form.Control
                  id="titleId"
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={
                     (e) => {
                        setTitle(e.target.value);
                     }
                  }

               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Description
               </Form.Label>
               <Form.Control
                  id="DescriptionId"
                  as="textarea"
                  rows="3"
                  placeholder="Enter description"
                  value={description}
                  onChange={
                     (e) => {
                        if (e.target.value.length > 100) {
                           enqueueSnackbar("Description should be less than 100 characters", {
                              variant: "warning",
                           });
                        } else {
                           setDescription(e.target.value);
                        }
                     }
                  }
               />
            </Form.Group>
            {
               resourceUploadProgress ? (<ProgressBar now={resourceUploadProgress} label={`${parseInt(resourceUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3">
               <Form.Label style={{
                  color: "black"
               }}>Resource file</Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={resourceRef}
                  encType="multipart/form-data"
                  type="file"
                  name="resourceFile"
                  id="resourceFile"
                  accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .mp4, .mov, .avi, .mp3, .wav, .jpeg, .jpg, .png, .gif, .svg, .webp"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     resourceFile = e.target.files[0];
                     handleResourceUpload(resourceFile);
                  }}
               />
            </Form.Group>
            {
               imageUploadProgress ? (<ProgressBar now={imageUploadProgress} label={`${parseInt(imageUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3" >
               <Form.Label style={{
                  color: "black"
               }}>
                  Cover Photo in Landscape
               </Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={coverPhotoRef}
                  encType="multipart/form-data"
                  type="file"
                  name="coverImage"
                  id="coverImage"
                  accept="image/*"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     imageFile = e.target.files[0];
                     handleCoverPhotoUpload(imageFile);
                  }}
               />
            </Form.Group>
            <Button
               variant="primary"
               type={"submit"}
               disabled={(imageUrl && resourceUrl) ? false : true}
               className="btn btn-block"
               style={{
                  width: "50%",
                  height: "10%",
                  fontSize: "1.2rem",
                  marginLeft: "50%",
               }}>
               Upload
            </Button>
         </Form>
      </>
   );
};