import { useState } from "react";
import { AddChapterModal } from "../../components/AddChapterModal";

export const AddChapter = ({ courses }) => {
  const [course, setCourse] = useState(null);
  const [chapter, setChapter] = useState({
    title: "",
    notes: "",
    video: "",
    resources: [],
  });
  return (
    <AddChapterModal
      courses={courses}
      setCourse={setCourse}
      course={course}
      chapter={chapter}
      setChapter={setChapter}
    />
  );
};
