import React, { useContext, useState } from "react";
import validate from "../../pages/forms/ValidateInfo";
import useForm from "../../pages/forms/useForm";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";

//context
import { ChurchContext } from "../../contexts/ChurchContext";

export function LoginForm({ submitForm }) {
  const { switchToSignup } = useContext(AccountContext);
  const { church } = useContext(ChurchContext);
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  return (
    <BoxContainer>
      <FormContainer
        onSubmit={handleSubmit}
        noValidate
      >
        <Input
          type="email"
          placeholder="Email"
          name="email"
          value={values.email}
          onChange={handleChange}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          name="password"
          value={values.password}
          onChange={handleChange}
          required
        />
      </FormContainer>
      {errors.email && <p>{errors.email}</p>}
      {errors.password && <p>{errors.password}</p>}
      <Marginer direction="vertical" margin={10} />
      <MutedLink onClick={switchToSignup}>Forget your password?</MutedLink>
      <Marginer direction="vertical" margin="1.6em" />
      <SubmitButton
        onClick={handleSubmit}
      >
        Signin
      </SubmitButton>
      <Marginer direction="vertical" margin="4em" />
      {/* Copyright */}
      <p
        style={{
          color: "#cccccc",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        {`© ${church?.name ? church.name : ""} ${new Date().getFullYear()}`}
      </p>
    </BoxContainer>
  );
}
