import { useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

//Select Course
export const CourseSelection = ({ courses, setCourse, course }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleChange = (selected) => {
    // event.preventDefault();
    setSelectedCourse(selected);
    setCourse(courses.find((course) => course.id === selected.value));
  };
  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Select Course</Form.Label>
        <Select
          options={courses.map((course) => {
            return {
              value: course.id,
              label: course.name,
            };
          })}
          isClearable={true}
          isSearchable={true}
          backspaceRemovesValue={true}
          captureMenuScroll={true}
          autoFocus={true}
          required
          onChange={handleChange}
          placeholder="Select Course"
          name="courseId"
        />
      </Form.Group>
    </>
  );
};
