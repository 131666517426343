import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from '../../components/ResponsiveNavbar';
import { Form, Button, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";


import { storage } from '../../configs/firebase';
import { db, auth } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const OurLeaders = () => {
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();
   const [imageUrl, setImageUrl] = useState(null);

   let imageFile = null;

   const profilePicture = useRef(null);

   const [name, setName] = useState("");
   const [position, setPosition] = useState("");
   const [index, setIndex] = useState(Infinity);
   const [branch, setBranch] = useState("");


   const [imageUploadProgress, setImageUploadProgress] = useState(null);

   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/OurLeaders");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (name === "" || position === "") {
         MySwal.fire({
            title: "Warning!",
            text: "Please fill all the fields",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }
      if (index === Infinity) {
         setIndex(1000);
      }
      let leader = {
         name,
         position,
         date: new Date(),
         profilePicture: imageUrl,
         index: index,
         branch: branch,
      }

      console.log(leader);

      if (imageUrl) {
         console.log("Creating Doc")
         await addDoc(collection(db, "leaders"), {
            ...leader,
         }).then(() => {
            MySwal.fire({
               title: "Success!",
               text: "New Leader Created Successfully!",
               icon: "success",
               confirmButtonText: "CLOSE",
            });
         })
            .catch((error) => {
               console.log(error);
               MySwal.fire({
                  title: "Failed To Add New Leader",
                  text: error.message,
                  icon: "error",
                  confirmButtonText: "CLOSE",
               });
            });
      } else {
         MySwal.fire({
            title: "File Upload Profile Picture",
            text: "Please try again later or contact support for assistance.",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
      }
   }

   async function handleCoverPhotoUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a cover image",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 1000000) {
         enqueueSnackbar("Image file size should be less than 1MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `newsletters/cover-images/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setImageUrl(downloadURL);

               enqueueSnackbar("Cover photo uploaded successfully", {
                  variant: "success",
               });
               setImageUploadProgress(null);
            });
         }
      );
   };

   return (
      <>
         <ResponsiveNavbar />
         <Form
            onSubmit={handleSubmit}
            style={{
               width: "50%",
               margin: "4% auto",
               padding: "3%",
               backgroundColor: "white",
               borderRadius: "10px",
               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
               marginTop: "10%",
            }}
         >
            <h3 className="text-center mt-1">Add New Leader</h3>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Name
               </Form.Label>
               <Form.Control
                  id="nameId"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={
                     (e) => {
                        setName(e.target.value);
                     }
                  }

               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Position
               </Form.Label>
               <Form.Control
                  id="PositionId"
                  type="text"
                  placeholder="Position"
                  value={position}
                  onChange={
                     (e) => {
                        setPosition(e.target.value);
                     }
                  }
               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Branch
               </Form.Label>
               <Form.Control
                  id="BranchId"
                  type="text"
                  placeholder="Branch"
                  value={branch}
                  onChange={
                     (e) => {
                        setBranch(e.target.value);
                     }
                  }
               />
            </Form.Group>
            {
               imageUploadProgress ? (<ProgressBar now={imageUploadProgress} label={`${parseInt(imageUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3" >
               <Form.Label style={{
                  color: "black"
               }}>
                  Cover Photo in Landscape
               </Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={profilePicture}
                  encType="multipart/form-data"
                  type="file"
                  name="coverImage"
                  id="coverImage"
                  accept="image/*"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     imageFile = e.target.files[0];
                     handleCoverPhotoUpload(imageFile);
                  }}
               />
            </Form.Group>
            <Button
               variant="primary"
               type={"submit"}
               disabled={imageUrl ? false : true}
               className="btn btn-block"
               style={{
                  width: "50%",
                  height: "10%",
                  fontSize: "1.2rem",
                  marginLeft: "50%",
               }}>
               Upload
            </Button>
         </Form>
      </>
   );
};