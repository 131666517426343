import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "../../components/css/ResourcesList.css";
import "../../components/css/CoursesGrid.css";
import { AddConnectGroup } from "../forms/AddConnectGroup";
import { ConnectGroupCard } from "../../components/ConnectGroupCard";
import { AddMember } from "../forms/AddMember";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

// mui datagrid
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ProgressBar } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from 'react-select';
import { CircularProgress, Paper } from '@mui/material';
import ReactLoading from "react-loading";

import { useState, useEffect } from "react";
import { db } from "../../configs/firebase";
import { collection, onSnapshot, updateDoc, deleteDoc, doc, orderBy, limit, query, addDoc, Transaction, runTransaction } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

// helper
import { getNames } from "../../helpers/Validators";

// Animations
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

const usersTableColumns = [
   { field: 'id', headerName: 'ID', width: 70 },
   { field: 'fullName', headerName: 'Full name', width: 130 },
   { field: 'email', headerName: 'Email', width: 130 },
   { field: 'phoneNumber', headerName: 'Phone number', width: 130 },
   { field: 'connectGroup', headerName: 'Connect Group', width: 130 }
];

const connectGroupsTableColumns = [
   { field: 'id', headerName: 'ID', width: 200 },
   { field: 'name', headerName: 'Name', width: 200 },
   { field: 'leader', headerName: 'Leaders', width: 300 },
   { field: 'members', headerName: 'Members', width: 200 },
   { field: 'email', headerName: 'Email', width: 200 },
   { field: 'phone', headerName: 'Phone Number', width: 200 },
   { field: 'location', headerName: 'Location', width: 200 },
   { field: 'description', headerName: 'Description', width: 200 },
];

function NoRowsOverlay() {
   return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         <h3>No groups created yet</h3>
      </div>
   );
}

const CustomToolbar = () => {
   return (
      <GridToolbarContainer>
         <GridToolbarExport />
      </GridToolbarContainer>
   );
}

const CustomPaper = (props) => {
   return (
      <Paper
         elevation={0}
         sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 400,
         }}
         className="col-md-6 col-sm-12 col-6"
      >
         {props.children}
      </Paper>
   );
}

const CustomGrid = (props) => {
   return (
      <Box sx={{ height: 400, width: '100%' }}>
         <DataGrid
            {...props}
            components={props.components}
         />
      </Box>
   );
}

export const UsersTable = () => {
   const [users, setUsers] = useState(null);
   const [usersRows, setUsersRows] = useState(null);
   const [connectGroups, setConnectGroups] = useState(null);
   const [connectGroupRows, setConnectGroupRows] = useState(null);
   const [selectedConnectGroup, setSelectedConnectGroup] = useState(null);
   const [selectedUser, setSelectedUser] = useState(null);
   const [connectGroupOptions, setConnectGroupOptions] = useState(null);
   const [connectGroupMembers, setConnectGroupMembers] = useState(null);
   const [connectGroupLeader, setConnectGroupLeader] = useState(null);
   const [connectGroupLeaderOptions, setConnectGroupLeaderOptions] = useState(null);
   const [connectGroupMemberOptions, setConnectGroupMemberOptions] = useState(null);
   const [connectGroupMember, setConnectGroupMember] = useState(null);
   const [connectGroupMemberToAdd, setConnectGroupMemberToAdd] = useState(null);
   const [connectGroupMemberToRemove, setConnectGroupMemberToRemove] = useState(null);
   const [connectGroupMemberToAddOptions, setConnectGroupMemberToAddOptions] = useState(null);
   const [connectGroupMemberToRemoveOptions, setConnectGroupMemberToRemoveOptions] = useState(null);

   const [checkboxSelection, setCheckboxSelection] = useState(true);
   const [selectionModel, setSelectionModel] = useState([]);

   const navigate = useNavigate();

   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/GroupsDashboard");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   useEffect(() => {
      const unsubscribe = onSnapshot(
         collection(db, "users"),
         (snapshot) => {
            const usersList = snapshot.docs.map((userItem) => {
               const { firstName, lastName, fullName } = getNames(userItem.data());
               return {
                  id: userItem.id,
                  firstName,
                  lastName,
                  fullName,
                  ...userItem.data(),
               };
            });
            setUsers(usersList);
         }
      );
      return () => unsubscribe();
   }, []);

   useEffect(() => {
      const unsubscribe = onSnapshot(
         collection(db, "connect-groups"),
         (snapshot) => {
            const connectGroupsList = snapshot.docs.map((connectGroupItem) => {
               let leaders = connectGroupItem.data().leaders.map((leader) => {
                  if (leader?.name) {
                     return leader.name;
                  } else {
                     return leader?.leaderName;
                  }
               });
               let connectGroupLeader;

               if (leaders.length > 1) {
                  connectGroupLeader = leaders.join(" & ");
               } else if (leaders.length === 1) {
                  connectGroupLeader = leaders[0];
               } else {
                  connectGroupLeader = "";
               }

               let row = {};
               row.id = connectGroupItem.id;
               row.name = connectGroupItem.data().name;
               row.leader = connectGroupLeader;
               row.members = connectGroupItem.data().members.length.toString();
               row.description = connectGroupItem.data().description;
               row.phone = connectGroupItem.data().contactNumber;
               row.email = connectGroupItem.data().contactEmail;

               return {
                  id: connectGroupItem.id,
                  row,
                  ...connectGroupItem.data(),
               };
            });
            setConnectGroups(connectGroupsList);
            setConnectGroupRows(connectGroupsList.map((connectGroup) => connectGroup.row));
         }

      );
      return () => unsubscribe();
   }, []);

   useEffect(() => {
      if (connectGroups) {
         const connectGroupOptionsList = connectGroups.map((connectGroup) => {
            return {
               value: connectGroup.id,
               label: connectGroup.name,
            };
         });
         setConnectGroupOptions(connectGroupOptionsList);
      }
   }, [connectGroups]);

   useEffect(() => {
      if (connectGroups && selectedConnectGroup) {
         const connectGroupMembersList = connectGroups.find((connectGroup) => connectGroup.id === selectedConnectGroup.value)?.members;
         const connectGroupLeader = connectGroups.find((connectGroup) => connectGroup.id === selectedConnectGroup.value).leader;
         setConnectGroupMembers(connectGroupMembersList);
         setConnectGroupLeader(connectGroupLeader);
      }
   }, [connectGroups, selectedConnectGroup]);

   useEffect(() => {
      if (connectGroupMembers) {
         const connectGroupMemberOptionsList = connectGroupMembers.map((connectGroupMember) => {
            return {
               value: connectGroupMember,
               label: connectGroupMember,
            };
         });
         setConnectGroupMemberOptions(connectGroupMemberOptionsList);
      }
   }, [connectGroupMembers]);

   useEffect(() => {
      if (connectGroupLeader) {
         const connectGroupLeaderOptionsList = [{
            value: connectGroupLeader,
            label: connectGroupLeader,
         }];
         setConnectGroupLeaderOptions(connectGroupLeaderOptionsList);
      }
   }, [connectGroupLeader]);

   useEffect(() => {
      if (users && connectGroupMembers) {
         const connectGroupMemberToAddOptionsList = users.filter((user) => !connectGroupMembers.includes(user.fullName)).map((user) => {
            return {
               value: user.id,
               label: user.fullName,
            };
         });
         setConnectGroupMemberToAddOptions(connectGroupMemberToAddOptionsList);
      }

   }, [users, connectGroupMembers]);

   useEffect(() => {
      if (users && connectGroupMembers) {
         const connectGroupMemberToRemoveOptionsList = users.filter((user) => connectGroupMembers.includes(user.fullName)).map((user) => {
            return {
               value: user.id,
               label: user.fullName,
            };
         });
         setConnectGroupMemberToRemoveOptions(connectGroupMemberToRemoveOptionsList);
      }

   }, [users, connectGroupMembers]);

   const handleConnectGroupChange = (event) => {
      setSelectedConnectGroup(event);
   };

   const handleUserChange = (event) => {
      setSelectedUser(event);
   };

   const handleConnectGroupMemberToAddChange = (event) => {
      setConnectGroupMemberToAdd(event);
   };

   const handleConnectGroupMemberToRemoveChange = (event) => {
      setConnectGroupMemberToRemove(event);
   };

   const handleConnectGroupMemberChange = (event) => {
      setConnectGroupMember(event);
   };

   const handleConnectGroupLeaderChange = (event) => {
      setConnectGroupLeader(event);
   };

   const handleAddConnectGroupMember = () => {
      if (connectGroupMemberToAdd) {
         const connectGroupMemberToAddFullName = users.find((user) => user.id === connectGroupMemberToAdd.value).fullName;
         const connectGroupMemberToAddList = [...connectGroupMembers, connectGroupMemberToAddFullName];
         const connectGroupMemberToAddOptionsList = connectGroupMemberToAddOptions.filter((connectGroupMemberToAddOption) => connectGroupMemberToAddOption.value !== connectGroupMemberToAdd.value);
         setConnectGroupMembers(connectGroupMemberToAddList);
         setConnectGroupMemberToAddOptions(connectGroupMemberToAddOptionsList);
         setConnectGroupMemberToAdd(null);
      }
   };

   const handleRemoveConnectGroupMember = () => {
      if (connectGroupMemberToRemove) {

         const connectGroupMemberToRemoveFullName = users.find((user) => user.id === connectGroupMemberToRemove.value).fullName;
         const connectGroupMemberToRemoveList = connectGroupMembers.filter((connectGroupMember) => connectGroupMember !== connectGroupMemberToRemoveFullName);
         const connectGroupMemberToRemoveOptionsList = [...connectGroupMemberToRemoveOptions, {
            value: connectGroupMemberToRemoveFullName,
            label: connectGroupMemberToRemoveFullName,
         }];
         setConnectGroupMembers(connectGroupMemberToRemoveList);
         setConnectGroupMemberToRemoveOptions(connectGroupMemberToRemoveOptionsList);
         setConnectGroupMemberToRemove(null);
      }
   };

   const handleAddConnectGroupLeader = () => {
      if (connectGroupMember) {
         const connectGroupMemberFullName = users.find((user) => user.id === connectGroupMember.value).fullName;
         const connectGroupMemberList = [...connectGroupMembers, connectGroupMemberFullName];
         const connectGroupMemberOptionsList = connectGroupMemberOptions.filter((connectGroupMemberOption) => connectGroupMemberOption.value !== connectGroupMember.value);
         setConnectGroupMembers(connectGroupMemberList);
         setConnectGroupMemberOptions(connectGroupMemberOptionsList);
         setConnectGroupMember(null);
      }

      if (connectGroupLeader) {
         const connectGroupLeaderFullName = users.find((user) => user.id === connectGroupLeader.value).fullName;
         const connectGroupLeaderList = [connectGroupLeaderFullName];
         const connectGroupLeaderOptionsList = connectGroupLeaderOptions.filter((connectGroupLeaderOption) => connectGroupLeaderOption.value !== connectGroupLeader.value);
         setConnectGroupLeader(connectGroupLeaderList);
         setConnectGroupLeaderOptions(connectGroupLeaderOptionsList);
         setConnectGroupLeader(null);
      }
   };

   const handleRemoveConnectGroupLeader = () => {
      if (connectGroupLeader) {
         const connectGroupLeaderFullName = users.find((user) => user.id === connectGroupLeader.value).fullName;
         const connectGroupLeaderList = connectGroupMembers.filter((connectGroupMember) => connectGroupMember !== connectGroupLeaderFullName);
         const connectGroupLeaderOptionsList = [...connectGroupLeaderOptions, {
            value: connectGroupLeaderFullName,
            label: connectGroupLeaderFullName,
         }];
         setConnectGroupMembers(connectGroupLeaderList);
         setConnectGroupLeaderOptions(connectGroupLeaderOptionsList);
         setConnectGroupLeader(null);
      }
   };

   const handleAddConnectGroup = async () => {
      if (selectedUser && connectGroupMembers && connectGroupLeader) {
         const connectGroupToAdd = {
            name: selectedUser.value,
            members: connectGroupMembers,
            leader: connectGroupLeader,
         };
         await addDoc(collection(db, "connect-groups"), connectGroupToAdd);
         setSelectedUser(null);
         setConnectGroupMembers(null);
         setConnectGroupLeader(null);
      }
   };

   const handleUpdateConnectGroup = async () => {
      if (selectedConnectGroup && connectGroupMembers && connectGroupLeader) {
         const connectGroupToUpdate = {
            name: selectedConnectGroup.value,
            members: connectGroupMembers,
            leader: connectGroupLeader,
         };
         await updateDoc(doc(db, "connect-groups", selectedConnectGroup.id), connectGroupToUpdate);
         setSelectedConnectGroup(null);
         setConnectGroupMembers(null);
         setConnectGroupLeader(null);
      }
   };

   const handleDeleteConnectGroup = async () => {
      if (selectedConnectGroup) {
         await deleteDoc(doc(db, "connect-groups", selectedConnectGroup.id));
         setSelectedConnectGroup(null);
         setConnectGroupMembers(null);
         setConnectGroupLeader(null);
      }
   };

   const handleResetForm = () => {
      setSelectedUser(null);
      setSelectedConnectGroup(null);
      setConnectGroupMembers(null);
      setConnectGroupLeader(null);
   };

   const onSelectionModelChange = (newSelection) => {
      console.log(newSelection);
   };

   const userOptions = users && users.map((user) => {
      return {
         value: user.id,
         label: user.fullName,
      };
   });

   const DataGridHeader = () => {
      return (
         <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{ margin: "2%" }}>
               CONNECT GROUPS
            </Typography>
            <CustomToolbar />
         </Box>
      );
   };

   return (
      <div className="GroupsDashboard">
         <div className="GroupsDashboard__heading" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4" style={{ alignSelf: "center" }}>CONNECT GROUPS DASHBOARD</Typography>
         </div>
         <div className="GroupsDashboard__container row m-4">
            {
               selectedConnectGroup ? (
                  <MembersTable
                     connectGroups={connectGroups}
                     selectedConnectGroupId={selectedConnectGroup.id}
                     users={users}
                  />) : (
                  <Paper className="GroupsDashboard__form col-md-6 col-sm-12 col-6 p-4" elevation={4}>
                     {/* <div className="GroupsDashboard__form__select m-4">
                        <label htmlFor="select-user">Select User</label>
                        <Select
                           id="select-user"
                           value={selectedUser}
                           onChange={handleUserChange}
                           options={userOptions}
                           placeholder="Select User"
                        />
                     </div>
                     <div className="GroupsDashboard__form__select  m-4">
                        <label htmlFor="select-connect-group">Select Connect Group</label>
                        <Select
                           id="select-connect-group"
                           value={selectedConnectGroup}
                           onChange={handleConnectGroupChange}
                           options={connectGroupOptions}
                           placeholder="Select Connect Group"
                        />
                     </div>
                     <div className="GroupsDashboard__form__select  m-4">
                        <label htmlFor="select-connect-group-member">Select Connect Group Member</label>
                        <Select
                           id="select-connect-group-member"
                           value={connectGroupMember}
                           onChange={handleConnectGroupMemberChange}
                           options={connectGroupMemberOptions}
                           placeholder="Select Connect Group Member"
                        />
                     </div>
                     <div className="GroupsDashboard__form__select  m-4">
                        <label htmlFor="select-connect-group-member-to-add">Select Connect Group Member To Add</label>
                        <Select

                           id="select-connect-group-member-to-add"
                           value={connectGroupMemberToAdd}
                           onChange={handleConnectGroupMemberToAddChange}
                           options={connectGroupMemberToAddOptions}
                           placeholder="Select Connect Group Member To Add"
                        />
                     </div>
                     <div className="GroupsDashboard__form__select  m-4">
                        <label htmlFor="select-connect-group-member-to-remove">Select Connect Group Member To Remove</label>
                        <Select
                           id="select-connect-group-member-to-remove"
                           value={connectGroupMemberToRemove}
                           onChange={handleConnectGroupMemberToRemoveChange}
                           options={connectGroupMemberToRemoveOptions}
                           placeholder="Select Connect Group Member To Remove"
                        />
                     </div>
                     <div className="GroupsDashboard__form__select  m-4">
                        <label htmlFor="select-connect-group-leader">Select Connect Group Leader</label>
                        <Select
                           id="select-connect-group-leader"
                           value={connectGroupLeader}
                           onChange={handleConnectGroupLeaderChange}
                           options={connectGroupLeaderOptions}
                           placeholder="Select Connect Group Leader"
                        />
                     </div>
                     <div className="GroupsDashboard__form__buttons  m-4" style={{ display: "flex" }}>
                        <button className="btn btn-primary btn-danger m-2" onClick={handleDeleteConnectGroup}>Delete Connect Group</button>
                        <button className="btn btn-primary btn-warning m-2" onClick={handleUpdateConnectGroup}>Update Connect Group</button>
                        <button className="btn btn-primary btn-success m-2" onClick={handleAddConnectGroup}>Add Connect Group</button>
                     </div> */}
                  </Paper>
               )
            }
            {/* Connect Groups Datagrid */}
            {
               connectGroupRows ? (
                  <div className="GroupsDashboard__datagrid col-md-6 col-sm-12 col-6">
                     <CustomGrid
                        rows={connectGroupRows}
                        columns={connectGroupsTableColumns}
                        pageSize={6}
                        rowsPerPageOptions={[10, 20]}
                        checkboxSelection={true}
                        disableSelectionOnClick
                        selectionModel={selectionModel}
                        onSelectionModelChange={(selection) => {
                           console.log(selection);
                           setSelectedConnectGroup({
                              id: selection[selection.length - 1],
                              value: selection[selection.length - 1],
                           });

                           if (selection.length > 1) {
                              setSelectionModel([selection[selection.length - 1]]);
                           } else {
                              setSelectionModel(selection);
                           }

                           console.log({ selectionModel });
                           console.log({ selectedConnectGroup });
                        }}
                        autoHeight={true}
                        density="comfortable"
                        components={{
                           Toolbar: DataGridHeader,
                           NoRowsOverlay: NoRowsOverlay,
                        }}
                        compomentProps={{
                           toolbar: {
                              value: "Export To Csv",
                              onClick: () => { console.log("Export to csv") },
                              style: { backgroundColor: "red" },
                              csvOptions: {
                                 fileName: `Connect Groups ${new Date().toLocaleDateString()}`,
                                 allColumns: true,
                                 onlySelected: true,
                                 separator: ",",
                                 ignoreHeader: false,
                                 withBOM: true,
                                 utf8WithBom: true,
                                 exportMode: "selected",
                              },
                           },
                        }}
                        sx={{
                           boxShadow: 2,
                           '& .MuiDataGrid-cell:hover': {
                              color: 'primary.main',
                           },
                           minHeight: 500,
                        }}
                        columnVisibilityModel={{
                           // Hide columns status and traderName, the other columns will remain visible
                           id: false,
                           location: false,
                        }}
                     />
                  </div>)
                  : (
                     <div
                        className="GroupsDashboard__loading col-md-6 col-sm-12 col-6"
                        style={{
                           display: "flex",
                           height: "100%",
                           justifyContent: "center",
                           alignItems: "center"
                        }}
                     >
                        <CircularProgress
                           color="primary"
                           size={50}
                        />
                     </div>
                  )
            }
         </div>
      </div>
   );
};

const membersTableColumns = [
   {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: false,
   },
   {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: false,
   },
   {
      field: "surname",
      headerName: "Surname",
      width: 250,
      editable: false,
   },
   {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
   },
   {
      field: "phone",
      headerName: "Phone",
      width: 200,
      editable: false,
   },
   {
      field: "branch",
      headerName: "Branch",
      width: 200,
      editable: false,
   },
];

const MembersTable = ({ connectGroups, selectedConnectGroupId, users }) => {
   const [membersUserObjects, setMembersUserObjects] = useState(null);
   const [membersRows, setMembersRows] = useState(null);
   const [selectionModel, setSelectionModel] = useState([]);
   const [selectedMember, setSelectedMember] = useState(null);

   useEffect(() => {
      if (connectGroups && users) {
         console.log({ connectGroups });
         let selectedConnectGroup = connectGroups.find((connectGroup) => connectGroup.id === selectedConnectGroupId);
         let connectGroupMembers = selectedConnectGroup?.members;
         let membersUserObjectsList = connectGroupMembers.map((member) => {
            return users.find((user) => user.id === member);
         });

         if (membersUserObjectsList) {
            let membersRows = membersUserObjectsList.map((member, index) => {
               return {
                  id: index + 1,
                  name: member?.name ? member.name : "No Name",
                  surname: member?.surname ? member.surname : "No Surname",
                  email: member?.email ? member.email : "No Email",
                  phone: member?.phone ? member.phone : "No Phone",
                  branch: member?.location?.name ? member.location.name : "No Branch",
               };
            })
               .filter((member) => member.name !== "No Name");
            setMembersRows(membersRows);
         }

         setMembersUserObjects(membersUserObjectsList);
      }
   }, [connectGroups, selectedConnectGroupId, users]);


   if (!membersRows) {
      return (
         <div
            className="MembersTable__loading col-md-6 col-sm-12 col-6"
            style={{
               display: "flex",
               height: "100%",
               justifyContent: "center",
               alignItems: "center"
            }}
         >
            <CircularProgress
               color="primary"
               size={50}
            />
         </div>
      )
   }

   const DataGridHeader = () => {
      return (
         <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{ margin: "2%" }}>
               SELECTED GROUP MEMBERS
            </Typography>
            <CustomToolbar />
         </Box>
      );
   };

   return (

      <AnimatePresence mode="wait">
         <motion.div
            className="MembersTable__datagrid col-md-6 col-sm-12 col-6"
            style={{ marginBottom: "20%" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
         >
            {
               membersRows ? (
                  <CustomGrid
                     rows={membersRows}
                     columns={membersTableColumns}
                     pageSize={6}
                     rowsPerPageOptions={[10, 20]}
                     checkboxSelection={true}
                     disableSelectionOnClick
                     selectionModel={selectionModel}
                     onSelectionModelChange={(selection) => {
                        console.log(selection);
                        setSelectedMember({
                           id: selection[selection.length - 1],
                           value: selection[selection.length - 1],
                        });

                        if (selection.length > 1) {
                           setSelectionModel([selection[selection.length - 1]]);
                        }
                        else {
                           setSelectionModel(selection);
                        }
                     }
                     }
                     autoHeight={true}
                     density="comfortable"
                     components={{
                        NoRowsOverlay: NoRowsOverlay,
                        Toolbar: DataGridHeader,
                     }}
                     compomentProps={{
                        toolbar: {
                           value: "Export To Csv",
                           onClick: () => { console.log("Export to csv") },
                           style: { backgroundColor: "red" },
                           csvOptions: {
                              fileName: `Connect Group Members ${new Date().toLocaleDateString()}`,
                              allColumns: true,
                              onlySelected: true,
                              separator: ",",
                              ignoreHeader: false,
                              withBOM: true,
                              utf8WithBom: true,
                              exportMode: "selected",
                           },
                        },
                     }}
                     sx={{
                        boxShadow: 2,
                        '& .MuiDataGrid-cell:hover': {
                           color: 'primary.main',
                        },
                        minHeight: 500,
                     }}
                     columnVisibilityModel={{
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                     }}
                  />
               ) : (
                  <div
                     className="MembersTable__loading col-md-6 col-sm-12 col-6"
                     style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                     }}
                  >

                     <CircularProgress
                        color="primary"
                        size={50}
                     />
                  </div>
               )
            }
         </motion.div>
      </AnimatePresence>
   );
};


export const GroupsDashboard = () => {
   const [connectGroups, setConnectGroups] = useState(null);
   const [users, setUsers] = useState(null);

   const navigate = useNavigate();

   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/GroupsDashboard");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   useEffect(() => {
      const unsubscribe = onSnapshot(
         collection(db, "connect-groups"),
         (snapshot) => {
            const connectGroupsList = snapshot.docs.map((connectGroupItem) => {
               return {
                  id: connectGroupItem.id,
                  ...connectGroupItem.data(),
               };
            });
            setConnectGroups(connectGroupsList);
         }
      );
      return () => unsubscribe();
   }, []);

   useEffect(() => {
      const unsubscribe = onSnapshot(
         collection(db, "users"),
         (snapshot) => {
            const usersList = snapshot.docs.map((userItem) => {
               const { firstName, lastName, fullName } = getNames(userItem.data());
               return {
                  id: userItem.id,
                  firstName,
                  lastName,
                  fullName,
                  ...userItem.data(),
               };
            });
            setUsers(usersList);
         }
      );
      return () => unsubscribe();
   }, []);

   if (!connectGroups || !users) {
      return (
         <div className="loading" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", }}>
            <ReactLoading type={"bars"} color={"blue"} />
         </div>
      );
   }

   return (
      <>
         <ResponsiveNavbar />
         <Container fluid style={{
            marginTop: "5%",
         }}>
            <UsersTable />
         </Container>
      </>
   );
};
