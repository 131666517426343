export const TicketHtml = ({
  data,
  qrSource,
  eventCoverImage,
  eventTitle,
  eventDate,
  eventTime,
  eventLocation,
  eventPrice,
  numberOfTickets,
  ticketNumber,
  churchName,
  churchAddress
}) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
                  <html>
                  <head>
                  <style>
                  body {
                      background-color: Thistle;
                      font-family: "Yanone Kaffeesatz", sans-serif;
                      font-weight: 600;
                      margin-left: 3%;
                    }
                    
                    .event-cover {
                      max-width: 100%;
                      height: auto;
                    }
                    .address{
                      font-size: 12px;

                    }
                    .address-details{
                      font-size: 12px;
                      font-weight: 600;
                      padding-top: 0,
                      padding-bottom: 0,
                      margin-top: 0,
                      margin-bottom: 0,
                      line-height: 1.5;
                    }

                    .qr-code {

                      max-width: 100%;
                      height: auto;
                      display: block;
                      align-items: center;
                      justify-content: center;
                      margin: 0 auto;
                      padding-bottom: 5%;
                    }
                    
                    .ticket {
                      width: 400px;
                      height: 775px;
                      background-color: tomato;
                      margin: 25px auto;
                      position: relative;
                    }
                    
                    .holes-top {
                      height: 50px;
                      width: 50px;
                      background-color: Thistle;
                      border-radius: 50%;
                      position: absolute;
                      left: 50%;
                      margin-left: -25px;
                      top: -25px;
                    }
                    .holes-top:before, .holes-top:after {
                      content: "";
                      height: 50px;
                      width: 50px;
                      background-color: Thistle;
                      position: absolute;
                      border-radius: 50%;
                    }
                    .holes-top:before {
                      left: -200px;
                    }
                    .holes-top:after {
                      left: 200px;
                    }
                    
                    .holes-lower {
                      position: relative;
                      margin: 25px;
                      border: 1px dashed #aaa;
                    }
                    .holes-lower:before, .holes-lower:after {
                      content: "";
                      height: 50px;
                      width: 50px;
                      background-color: Thistle;
                      position: absolute;
                      border-radius: 50%;
                    }
                    .holes-lower:before {
                      top: -25px;
                      left: -50px;
                    }
                    .holes-lower:after {
                      top: -25px;
                      left: 350px;
                    }
                    
                    .title {
                      padding: 50px 25px 10px;
                      text-align: center;
                    }
                    .poster {
                      position: relative;
                    }
                    
                    .ticket-number {
                      color: #000;
                      font-size: 1.8rem;
                    }
                    
                    .admit-one {
                      font-size: 2.5rem;
                      color: #fff;
                    }
                    
                    .info {
                      padding: 15px 25px;
                    }
                    
                    table {
                      width: 100%;
                      font-size: 18px;
                      margin-bottom: 15px;
                    }
                    table tr {
                      margin-bottom: 10px;
                    }
                    table th {
                      text-align: left;
                    }
                    table th:nth-of-type(1) {
                      width: 38%;
                    }
                    table th:nth-of-type(2) {
                      width: 40%;
                    }
                    table th:nth-of-type(3) {
                      width: 15%;
                    }
                    table td {
                      width: 20%;
                      font-size: 0.8rem;
                    }
                    
                    .bigger {
                      font-size: 48px;
                    }
                    
                    .serial {
                      padding: 25px;
                    }
                    .serial table {
                      border-collapse: collapse;
                      margin: 0 auto;
                    }
                    .serial td {
                      width: 3px;
                      height: 50px;
                    }
                    
                    .numbers td {
                      font-size: 16px;
                      text-align: center;
                    }
                    #price{
                      font-size: 1.3rem;
                    }
                    #date{
                      font-size: 0.8rem;
                      width: 60%;
                    }
                    .event-title{
                      font-size: 1.5rem;
                      text-align: center;
                      padding: 0 25px;
                    }
                </style>
                  </head> 
                  <body>    
                  <div class="ticket">
                  <div class="holes-top"></div>
                  <div class="title">
                    <p class="ticket-number">${ticketNumber}</p>
                    <p class="admit-one">ADMIT    1</p>
                  </div>
                      <img class="qr-code" src=${qrSource} />
                      <p class="event-title">
                        ${eventTitle}
                      </p>
                  <div class="info">
                  <table>
                    <tr>
                      <th>PRICE</th>
                      <th>DATE</th>
                      <th>TIME</th>
                    </tr>
                    <tr>
                      <td id="price">${Number(eventPrice).toLocaleString('en-US', {
          style: 'currency',
          currency: 'ZAR',
        })}</td>
                      <td id="date">${eventDate}</td>
                      <td>${eventTime}</td>
                    </tr>
                  </table>

                  <div class="address">
                      <p class="address-details">${churchName}</p>
                      <p class="address-details">${eventLocation}</p>
                      <p class="address-details" >${churchAddress}</p>
                  </div>
                  </div>
                  <div class="holes-lower"></div>
                </div>
                </body>
                </html>
   `
      }}
    />)
}