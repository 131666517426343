import React, { useState, useContext, useEffect } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

import { db } from "../../configs/firebase";
import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactLoading from "react-loading";

// mui datagrid
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import { ComboBox } from '../../components/ComboBox';

// context
import { ChurchContext } from "../../contexts/ChurchContext";

// Helpers
import { formatPhoneNumber, getNames } from '../../helpers/Validators';

// Icons
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AdjustIcon from '@mui/icons-material/Adjust';

const MySwal = withReactContent(Swal);

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
  },
  {
    field: 'appAdmin',
    headerName: 'App Admin Role',
    width: 160,
  },
  {
    field: 'cmsAdmin',
    headerName: 'CMS Admin Role',
    width: 160,
  },
  {
    field: 'leader',
    headerName: 'Leadership Role',
    width: 160,
  },
  {
    field: 'location',
    headerName: 'Location',
    width: 150,
  },
  {
    field: 'connectGroups',
    headerName: 'Connect Groups',
    width: 110,
  }
];

export const Users = () => {
  const [users, setUsers] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const { church } = useContext(ChurchContext);
  const { enqueueSnackbar } = useSnackbar();

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })

  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/Users");
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  // Get All Users
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const usersList = snapshot.docs.map((userItem) => {
        return {
          ...userItem.data(),
          label: userItem?.data()?.email ? userItem?.data()?.email : "Anonymous",
          id: userItem.id,
        };
      });
      setUsers(usersList);
      if (usersList) {
        setSelectedUser(usersList[0]);
      }
    });
    return () => unsubscribe();
  }, []);

  // Table Rows
  useEffect(() => {
    if (users?.length > 0) {
      let rows = users.map((person, index) => {
        let tel = formatPhoneNumber(person.phone);
        let { fullName } = getNames(person);
        let appAdmin = person?.roles?.map((role) => role.toLowerCase()).includes("admin") ? "Yes" : "No";
        let cmsAdmin = person?.role === "admin" ? "Yes" : "No";
        let leader = person
          ?.categories
          ?.map((category) => category.toLowerCase())
          .includes("leaders") ? "Yes" : "No";

        console.log("Tel", tel);
        return {
          id: person?.id ? person?.id : index,
          name: fullName,
          email: person.email,
          phone: tel,
          appAdmin: appAdmin,
          cmsAdmin: cmsAdmin,
          leader: leader,
          location: person?.location?.name ? person?.location?.name : "No Location",
          connectGroups: person?.connectGroups?.length > 0 ? person?.connectGroups?.length : "No Connect Group",
        }
      })

      setTableRows(rows)
    }
  }, [users])

  if (users === null) {
    return (
      <Container
        fluid
        style={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          display: "flex",
        }}
      >
        <ReactLoading type={"bubbles"} color={"blue"} height={100} width={100} />
      </Container>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function NoRowsOverlay() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <h3>No Users</h3>
      </div>
    );
  }

  return (
    <>
      <ResponsiveNavbar />
      <div
        fluid
        style={{
          width: isDesktopOrLaptop ? "60%" : "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: isDesktopOrLaptop ? "10%" : "20%",
          paddingLeft: isDesktopOrLaptop ? "1%" : "10%",
          paddingRight: isDesktopOrLaptop ? "1%" : "10%",
        }}
        className="lg-"
      >
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-title" style={{ textAlign: "center", marginTop: "2%" }}>
              <h2>Assign Role</h2>
            </div>
            <div className="card-body">
              <ComboBox
                dataList={users}
                label="Search User By Email"
                selectedValue={selectedUser}
                setSelectedValue={setSelectedUser}
              />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedRole}
                    label="Role"
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <MenuItem value={"null"}>Select Role</MenuItem>
                    <MenuItem value={"leaders"}>Leader</MenuItem>
                    <MenuItem value={"admin"}>App Admin</MenuItem>
                    <MenuItem value={"cms-admin"}>CMS Admin</MenuItem>
                  </Select>
                </FormControl>

              </div>
              <Button
                variant="contained"
                endIcon={<AssignmentIndIcon />}
                size="large"
                style={{ marginTop: "2%", backgroundColor: "#3f51b5", color: "white", float: "right" }}
                onClick={() => {
                  if (selectedRole === "null") {
                    enqueueSnackbar("Please Select a Role", { variant: "error" });
                    return;
                  }
                  if (!selectedUser) {
                    enqueueSnackbar("Please Select a User", { variant: "error" });
                    return;
                  }
                  let userDoc = doc(db, "users", selectedUser.id);
                  if (selectedRole === "cms-admin") {
                    if (selectedUser?.role !== "admin") {
                      updateDoc(userDoc, {
                        role: "admin",
                      });
                      enqueueSnackbar("CMS Admin Role Assigned Successfully", { variant: "success" });
                    } else {
                      enqueueSnackbar("User Already Has CMS Admin Role", { variant: "error" });
                    }
                  }

                  if (selectedRole === "admin") {
                    if (selectedUser?.role !== "admin") {
                      updateDoc(userDoc, {
                        roles: [...selectedUser.roles, "admin"],
                      });
                      enqueueSnackbar("App Admin Role Assigned Successfully", { variant: "success" });
                    } else {
                      enqueueSnackbar("User Already Has App Admin Role", { variant: "error" });
                    }
                  }

                  if (selectedRole === "leaders") {
                    if (selectedUser?.categories?.map((category) => category.toLowerCase()).includes("leaders")) {
                      enqueueSnackbar("User Already Has Leadership Role", { variant: "error" });
                      return;
                    } else {
                      updateDoc(userDoc, {
                        categories: [...selectedUser.categories, "leaders"],
                      });
                      enqueueSnackbar("Leadership Role Assigned Successfully", { variant: "success" });
                    }
                  }
                  console.log("Selected User", selectedUser);
                  console.log("Selected Role", selectedRole);
                }}
              >
                Assign Role
              </Button>
            </div>
          </div>
          <Container
            style={{
              padding: "10px",
              borderRadius: "10px",
              alignSelf: "center",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1%"
            }}
          >
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-title" style={{ textAlign: "center", marginTop: "2%" }}>
                  <h2>Users</h2>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Box sx={{ width: '100%' }}>
                      {
                        (users?.length > 0) && (
                          <DataGrid
                            rows={tableRows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            autoHeight={true}
                            checkboxSelection
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            density="comfortable"
                            components={{
                              Toolbar: CustomToolbar,
                              NoRowsOverlay: NoRowsOverlay,
                            }}
                            compomentProps={{
                              toolbar: {
                                value: "Export To Csv",
                                onClick: () => { console.log("Export to csv") },
                                style: { backgroundColor: "red" },
                                csvOptions: {
                                  fileName: `${church?.name ? church?.name : ""}Users`,
                                  allColumns: true,
                                  onlySelected: true,
                                  separator: ",",
                                  ignoreHeader: false,
                                  withBOM: true,
                                  utf8WithBom: true,
                                  exportMode: "selected",
                                },
                              },
                            }}
                            sx={{
                              boxShadow: 2,
                              '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                              },
                              minHeight: 400,
                            }}
                          />
                        )
                      }
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
