import React from "react";
import CreatableSelect from "react-select/creatable";

export const TagsSelection = ({
  tags,
  setTags,
  onSubmit,
  selectedTags,
  setSelectedTags,
}) => {
  const handleChange = (selected) => {
    setSelectedTags([...selected.map((tag) => tag.value)]);
  };
  return (
    <CreatableSelect
      isMulti
      onChange={handleChange}
      onSubmit={onSubmit}
      options={tags.map((tag) => {
        return {
          value: tag,
          label: tag,
        };
      })}
      name="tags"
      isClearable={true}
      isSearchable={true}
      backspaceRemovesValue={true}
      captureMenuScroll={true}
      autoFocus={true}
      required
      placeholder="Add Tags"
    />
  );
};
