import { BannersListItem } from "./BannersListItem";
import ListGroup from "react-bootstrap/ListGroup";
import "./css/ResourcesList.css";

export const BannersList = ({ banners }) => {
   return (
      <ListGroup
         style={{
            overflowY: "scroll",
            width: "80%",
            height: "80vh",
            marginTop: "50px",
            marginBottom: "1rem",
            marginLeft: "10%",
            padding: "5%",
            backgroundColor: "white",
            borderRadius: "10px",
            borderTop: "1px solid #0000ff",
            borderBottom: "1px solid #0000ff",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
         }}
         className="removeScollBar removeScollBar::-webkit-scrollbar"
      >
         {banners.map((banner, index) => {
            return (
               <BannersListItem
                  key={index}
                  banner={banner}
               />
            );
         })}
      </ListGroup>
   );
};
