import React, { createContext, useState } from 'react';

export const EventRegistrantsContext = createContext([]);

export const EventRegistrantsProvider = ({ children }) => {
   const [registrants, setRegistrants] = useState([]);
   const [totalPrice, setTotalPrice] = useState(0);
   const [registraa, setRegistraa] = useState(null);
   const [selectedEvent, setSelectedEvent] = useState(null);
   const [isPaid, setIsPaid] = useState(false);
   const [registeredUsers, setRegisteredUsers] = useState([]);

   return (
      <EventRegistrantsContext.Provider
         value={{
            registrants,
            setRegistrants,
            totalPrice,
            setTotalPrice,
            registraa,
            setRegistraa,
            selectedEvent,
            setSelectedEvent,
            isPaid,
            setIsPaid,
            registeredUsers,
            setRegisteredUsers,
         }}
      >
         {children}
      </EventRegistrantsContext.Provider>
   );
}