import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "../../components/css/ResourcesList.css";
import "../../components/css/CoursesGrid.css";
import { AddCourse } from "../forms/AddCourse";
import { CourseCard } from "../../components/CourseCard";
import { AddChapter } from "../forms/AddChapter";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

import { useState, useEffect } from "react";
import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const Courses = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/Courses");
    } else {
      navigate("/Login");
    }
  }, [navigate]);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "courses"), (snapshot) => {
      const coursesList = snapshot.docs.map((courseItem) => {
        return {
          ...courseItem.data(),
          id: courseItem.id,
        };
      });
      setCourses(coursesList);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <ResponsiveNavbar />
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8%",
        }}
      >
        <Row>
          <Col
            style={{
              width: "50%",
              marginLeft: "2%",
              marginBottom: "2%",
              padding: "3%",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
          >
            <h3 className="header text-center">Add Course</h3>
            <AddCourse />
          </Col>
          <Col
            style={{
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              marginLeft: "1%",
              marginRight: "1%",
              height: "15vh",
            }}

          >
            <h3 className="header text-center pt-3">Add Chapters</h3>
            <AddChapter courses={courses} />
          </Col>
          <Col
            style={{
              width: "50%",
              marginRight: "2%",
              marginBottom: "2%",
              padding: "3%",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
          >
            <h3 className="header text-center">Courses</h3>
            <div
              style={{
                height: "80vh",
                overflowY: "scroll",
                marginBottom: "1rem",
                borderTop: "1px solid #0000ff",
                borderBottom: "1px solid #0000ff",
              }}
              className="removeScollBar courses-grid"
            >
              {courses &&
                courses.map((course) => {
                  return (
                    <CourseCard
                      course={course}
                      key={course.id}
                      id={course.id}
                      courses={courses}
                      style={{
                        marginTop: "0.5rem",
                        height: "200px",
                        borderColor: "#00bc",
                        borderWidth: "1px",
                      }}
                    />
                  );
                })}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
