import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Notistack
import { SnackbarProvider } from 'notistack'

//context
import { ChurchProvider } from "./contexts/ChurchContext";
import { EventRegistrantsProvider } from "./contexts/EventRegistrantsContext";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";

//Import routes
import { Sermons } from "./pages/routes/Sermons";
import { SignUp } from "./pages/routes/SignUp";
import { Users } from "./pages/routes/Users";
import { Login } from "./pages/routes/Login";
import { Courses } from "./pages/routes/Courses";
import { Resourses } from "./pages/routes/Resources";
import { ConnectGroups } from "./pages/routes/ConnectGroups";
import { Notifications } from "./pages/routes/Notifications";
import { BannerCampaigns } from "./pages/routes/BannerCampaign";
import { PrayerRequests } from "./pages/routes/PrayerRequests";
import { UnAuthorized } from "./pages/routes/UnAuthorized";
import { Shorts } from "./pages/routes/Shorts";
import { NewsLetter } from "./pages/routes/NewsLetter";
import { PdfDevotional } from "./pages/routes/PdfDevotionals";
import { GrowthTracks } from "./pages/routes/GrowthTracks";
import { Devotionals } from "./pages/routes/Devotionals";
import { OurLeaders } from "./pages/routes/OurLeaders";
import { Locations } from "./pages/routes/Locations";
import { CreateNewEvent } from "./pages/routes/CreateNewEvent";
import { EventBooking } from "./pages/routes/EventBooking";
import { BookingSummaryTable } from "./components/BookingSummaryTable";
import { PaymentSuccess } from "./components/PaymentSuccess";
import EventRegistrationDashboard from "./pages/routes/EventRegistrationDashboard";
import { GroupsDashboard } from "./pages/routes/GroupsDashboard";

// Material Theme
import { ThemeProvider, CssBaseline } from "@mui/material";
import DarkTheme from "./styles/theme/DarkTheme";
import LightTheme from "./styles/theme/LightTheme";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider
      theme={LightTheme}
    >
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={10000}
      >
        <ChurchProvider>
          <CurrentUserProvider>
            <EventRegistrantsProvider>
              <Router>
                <Routes>
                  <Route path="/SignUp" element={<SignUp />} />
                  <Route path="/Users" element={<Users />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/" element={<App />} />
                  <Route path="/Sermons" element={<Sermons />} />
                  <Route path="/Courses" element={<Courses />} />
                  <Route path="/Resources" element={<Resourses />} />
                  <Route path="/ConnectGroups" element={<ConnectGroups />} />
                  <Route path="/Notifications" element={<Notifications />} />
                  <Route path="/BannerCampaigns" element={<BannerCampaigns />} />
                  <Route path="/NewsLetter" element={<NewsLetter />} />
                  <Route path="/PdfDevotional" element={<PdfDevotional />} />
                  <Route path="/GrowthTracks" element={<GrowthTracks />} />
                  <Route path="/PrayerRequests" element={<PrayerRequests />} />
                  <Route path="/UnAuthorized" element={<UnAuthorized />} />
                  <Route path="/Shorts" element={<Shorts />} />
                  <Route path="/Devotionals" element={<Devotionals />} />
                  <Route path="/OurLeaders" element={<OurLeaders />} />
                  <Route path="/Locations" element={<Locations />} />
                  <Route path="/EventBooking" element={<EventBooking />} />
                  <Route path="/CreateNewEvent" element={<CreateNewEvent />} />
                  <Route path="/BookingSummaryTable" element={<BookingSummaryTable />} />
                  <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
                  <Route path="/EventRegistrationDashboard" element={<EventRegistrationDashboard />} />
                  <Route path="/GroupsDashboard" element={<GroupsDashboard />} />
                </Routes>
              </Router>
            </EventRegistrantsProvider>
          </CurrentUserProvider>
        </ChurchProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
