const validateEmail = (email) => {
   const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if (re.test(String(email).toLowerCase()) === false) {
      return false;
   } else {
      return true;
   }
};

const validatePassword = (password) => {
   const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
   if (re.test(String(password))) {
      return true;
   } else {
      return false;
   }
};

const validatePhone = (phone) => {
   const re = /^\d{10}$/;
   if (re.test(String(phone))) {
      return true;
   } else {
      return false;
   }
};

const validateName = (name) => {
   const re = /^[a-zA-Z ]{2,50}$/;
   if (re.test(String(name))) {
      return true;
   } else {
      return false;
   }
};


const cleanUpText = (text) => {
   return text.trim().replace(/\s+/g, ' ');
}

const getNames = (currentUser) => {
   let firstName;
   let lastName;

   if (currentUser?.name && currentUser.surname === undefined) {
      const nameArray = currentUser.name.split(" ");
      firstName = nameArray[0];
      lastName = nameArray[1];
   } else {
      firstName = currentUser?.name || "Anonymous";
      lastName = currentUser?.surname || "";
   }

   return {
      firstName,
      lastName,
      fullName: firstName + " " + lastName
   }
}

const getNamesFromPaystack = (metadata) => {
   let firstName;
   let lastName;
   let fullName;

   let displayName = metadata?.custom_fields[0]?.display_name;
   fullName = metadata?.custom_fields[0]?.variable_name;

}

let formatPhoneNumber = (str) => {
   //Filter only numbers from the input
   let cleaned = ('' + str).replace(/\D/g, '');

   //Check if the input is of correct length
   let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

   if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
   };

   return null
};

const formatLink = (link) => {
   if (!link) return;
   if (link.startsWith('http://') || link.startsWith('https://')) {
      return link;
   }
   return `https://${link}`;
};

const validateLink = (link) => {
   if (!link) return;
   if (link.startsWith('http://') || link.startsWith('https://')) {
      return true;
   }
   return false;
};

export {
   validateEmail,
   validatePassword,
   validatePhone,
   validateName,
   cleanUpText,
   getNames,
   formatPhoneNumber,
   formatLink,
   validateLink,
};
