import React, { useState, useEffect, useContext } from 'react';
import { ChurchContext } from '../contexts/ChurchContext';
import { EventRegistrantsContext } from '../contexts/EventRegistrantsContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from 'react-responsive';
import { Timestamp } from 'firebase/firestore';
import { TicketHtml } from './ticket/TicketEmail';

// Mui
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import ReactLoading from "react-loading";
import { RegistrationPaymentLoading, LoadingScreen } from './RegistrationPaymentLoading';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

// Icons
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';

import uuid from 'react-uuid';

// Helpers
import { updatePaidEventWithNewRegistrant } from '../helpers/EventRegistration';

// Firebase
import { db } from '../configs/firebase';
import { addDoc, collection } from 'firebase/firestore';

// Paystack
import { usePaystackPayment } from 'react-paystack';

export const BookingSummaryTable = ({ route }) => {
   const { church } = useContext(ChurchContext);
   const { registrants, totalPrice } = useContext(EventRegistrantsContext);
   const [isPaid, setIsPaid] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();

   const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
   const isBigScreen = useMediaQuery({ minWidth: 1824 })
   const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
   const isPortrait = useMediaQuery({ orientation: 'portrait' })
   const isRetina = useMediaQuery({ minResolution: '2dppx' })

   useEffect(() => {
      if (registrants.length <= 0 && !isLoading) {
         navigate('/EventBooking');
      }
   }, [isLoading]);

   if (isLoading) <LoadingScreen />;

   return (
      <Container
         style={{
            width: isDesktopOrLaptop ? "50%" : "100%",
            marginTop: "5%",
         }}
      >
         <Paper
            elevation={3}
            style={{
               backgroundColor: "#f5f5f5",
               borderRadius: 10,
               padding: 20,
            }}
         >
            <Typography
               variant="h5"
               style={{
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "center"
               }}
            >
               Booking Summary
            </Typography>
            <Stack direction="row" spacing={2} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "1%", height: "20%" }}>
               <img
                  style={{ width: 30, height: 30, borderRadius: 15, boxShadow: "0 0 10px rgba(0,0,0,0.2)" }}
                  alt={church?.name ? church.name : ""}
                  src={church?.branding?.appIcon ? church.branding.appIcon : ""}
               />

               <Typography
                  variant="p"
                  style={{
                     color: "#000",
                     fontWeight: "300",
                     textAlign: "center",
                     fontSize: 15
                  }}
               >
                  {church?.name ? church.name : ""}
               </Typography>
            </Stack>
         </Paper>
         <Paper
            elevation={3}
            style={{
               backgroundColor: "#f5f5f5",
               borderRadius: 10,
               padding: 20,
               marginTop: 20,
            }}
         >
            <RegistrantsList registrants={registrants} />
            <Stack
               direction="row"
               spacing={2}
               style={{
                  width: "100%",
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               <Typography
                  variant="p"
                  style={{
                     color: "#000",
                     fontWeight: "300",
                     textAlign: "center",
                     fontSize: 15
                  }}
               >
                  Total
               </Typography>

               <Typography
                  variant='h2'
                  style={{
                     color: "#000",
                     fontWeight: "700",
                     textAlign: "center",
                     fontSize: 30
                  }}
               >
                  {totalPrice.toLocaleString('en-US', {
                     style: 'currency',
                     currency: 'ZAR',
                  })}
               </Typography>
            </Stack>
            <Stack
               direction="column"
               spacing={2}
               style={{
                  width: "100%",
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: "center"
               }}
            >
               <PaystackHookButton
                  totalPrice={totalPrice}
                  church={church}
                  isPaid={isPaid}
                  setIsPaid={setIsPaid}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
               />
            </Stack>
         </Paper>
      </Container >
   );
};

const RegistrantComponent = ({ registrant }) => {
   return (
      <Container
         sx={{
            borderRadius: 2,
            backgroundColor: "#5f5f5f",
         }}
      >
         <ListItem
            key={uuid()}
         >
            <ListItemAvatar>
               <Avatar>
                  <PersonIcon />
               </Avatar>
            </ListItemAvatar>
            <ListItemText
               primary={registrant.name}
               secondary={Number(registrant.price).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'ZAR',
               })
               }
            />
         </ListItem>
      </Container>
   )
};

const RegistrantsList = ({ registrants }) => {
   return (
      <div>

         {
            registrants?.length > 0 ? (
               <div style={{
                  width: '100%',
                  display: 'grid',
                  gridGap: 10,
               }}>
                  {registrants?.map((registrant, index) => {
                     return (
                        <RegistrantComponent registrant={registrant} key={uuid() + index} />
                     )
                  })}
               </div>

            ) : (
               <p
                  style={{
                     alignSelf: 'center',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     justifyContent: 'center',
                     fontSize: 20,
                     color: "black",
                     fontWeight: '300',
                  }}
               >
                  No Registrants
               </p>)
         }

      </div>
   )
}

function PaystackHookButton({ totalPrice, church, isPaid, setIsPaid, isLoading, setIsLoading }) {
   const { enqueueSnackbar } = useSnackbar();
   const navigate = useNavigate();

   const { setRegistrants, registraa, selectedEvent, registrants, setRegisteredUsers, registeredUsers } = useContext(EventRegistrantsContext);
   const [allRegistrants, setAllRegistrants] = useState(null);

   let firstName = registraa?.name?.split(" ")[0];
   let lastName = registraa?.name?.split(" ")[1];

   let ticketNumbers = allRegistrants?.map((registrant) => {
      if (registrant?.ticketNumber) return registrant?.ticketNumber;
   }).join(", ");

   console.log("====================ALL REGISTRANTS=============================");
   console.log(allRegistrants);
   console.log("======================TICKET NUMBERS===========================");
   console.log("ticketNumbers From Test", ticketNumbers);
   console.log("=================================================");

   const initializePayment = usePaystackPayment({
      reference: new Date().getTime(),
      email: registraa?.email,
      amount: totalPrice * 100,
      currency: "ZAR",
      firstname: firstName,
      lastname: lastName,
      fullName: registraa?.name,
      billingEmail: registraa?.email,
      metadata: {
         custom_fields: [
            {
               display_name: "Ticket Numbers",
               variable_name: "ticket_numbers",
               value: ticketNumbers
            },
            {
               display_name: "Event Name",
               variable_name: "event_name",
               value: selectedEvent?.title,
            },
            {
               display_name: "Church Name",
               variable_name: "church_name",
               value: church?.name,
            },
            {
               display_name: "Event ID",
               variable_name: "event_id",
               value: selectedEvent?.id,
            },
            {
               display_name: "Number of Registrants",
               variable_name: "number_of_registrants",
               value: registrants?.length,
            },
            {
               display_name: "Registrant Names",
               variable_name: "registrant_names",
               value: registrants?.map((registrant) => {
                  return registrant?.name
               }
               ).join(", "),
            },
            {
               display_name: "Registrant Emails",
               variable_name: "registrant_emails",
               value: registrants?.map((registrant) => {
                  return registrant?.email
               }
               ).join(", "),
            },
            {
               display_name: "Registrant Phone Numbers",
               variable_name: "registrant_phone",
               value: registrants?.map((registrant) => {
                  return registrant?.phone
               }
               ).join(", "),
            }
         ],
      },
      publicKey: church?.billing?.events?.publicKey,
   });

   const onSuccess = (reference) => {
      console.log(reference);

      handleCheckout({
         registrants,
         church,
         payment: reference
      });

   };

   const addEmailToFirebase = async (data) => {
      const html = TicketHtml(data);
      const toEmail = registrants[0]?.email;
      const subject = `${data.churchName} | Ticket for ${data.eventTitle}`;
      const text = `${data.churchName} | Ticket for ${data.eventTitle}

      Thank you for registering for the ${data.eventTitle}.
      We cannot wait to see you there!
      Please find your ticket details below for reference. This is only a virtual ticket and will not be mailed to you.

      Ticket Details:
      Ticket Number: ${data.ticketNumber}
      Event Date: ${data.eventDate}
      Event Time: ${data.eventTime}
      Event Location: ${data.eventLocation}
      Price: ${data.eventPrice}

      Please present this ticket at the entrance.

      Best Regards,
      ${data.churchName} Team
      `;

      const message = {
         subject,
         text,
         html,
      };

      const to = toEmail

      console.log({ to, message });

      try {
         const collectionRef = collection(db, "mail");
         const mailDoc = {
            to,
            message
         };
         const docRef = await addDoc(collectionRef, mailDoc);
         console.log("Document written with ID: ", docRef.id);

         enqueueSnackbar("Email Sent", {
            variant: "success",
            anchorOrigin: {
               vertical: "top",
               horizontal: "center",
            },
         });
      } catch (e) {
         console.error("Error adding document: ", e);
         enqueueSnackbar("Email Not Sent", {
            variant: "error",
            anchorOrigin: {
               vertical: "top",
               horizontal: "center",
            },
         });
      }
   };

   const onClose = () => {
      console.log("closed");
      enqueueSnackbar("Payment Cancelled", {
         variant: "error",
         anchorOrigin: {
            vertical: "top",
            horizontal: "center",
         },
      });
   };

   const handleCheckout = async ({ registrants, church, payment }) => {
      setIsLoading(true);
      console.log(registrants, church, payment);
      let registeredRegistrants = [];
      for (let i = 0; i < registrants.length; i++) {
         let { success, message, ticketNumber, transactionId } = await updatePaidEventWithNewRegistrant({
            church,
            registrant: registrants[i],
            interestedGuest: registraa,
            selectedEvent: selectedEvent,
            payment
         });
         let person = registrants[i];
         person = { ...registrants[i], ticketNumber, transactionId };
         console.log({ success, message, ticketNumber, transactionId, person });
         registeredRegistrants.push(person);
         ticketNumbers = ticketNumbers ? ticketNumbers + "|" + ticketNumber : ticketNumber;
      }

      console.log({ ticketNumbers })

      setAllRegistrants(registeredRegistrants);
      setRegisteredUsers(registeredRegistrants);
      console.log({ registeredUsers });
      enqueueSnackbar("Payment Successful", {
         variant: "success",
         anchorOrigin: {
            vertical: "top",
            horizontal: "center",
         },
      });

      setIsPaid(true);
      setIsLoading(false);

      // Send Ticket Email
      let qrData = registraa.ticketNumber + ":" + registraa.transactionId + ":" + selectedEvent.id;
      let qrSource = `https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=${qrData}`;
      let date = new Timestamp(selectedEvent.startingTime.seconds, selectedEvent.startingTime.nanoseconds).toDate().toDateString();
      let time = new Timestamp(selectedEvent.startingTime.seconds, selectedEvent.startingTime.nanoseconds).toDate().toLocaleTimeString();

      /* Required email data
            qrSource,
            eventTitle,
            eventDate,
            eventTime,
            eventLocation,
            eventPrice,
            ticketNumber,
            churchName,
            churchAddress,
            registrantName,
            churchPhone,
            churchEmail,
            churchLogo,
            churchCoverImage,
            churchWebsite
      */
      const data = {
         qrSource: qrSource,
         eventTitle: selectedEvent?.title,
         eventDate: date,
         eventTime: time,
         eventLocation: selectedEvent?.venue?.address,
         eventPrice: totalPrice,
         ticketNumber: registraa?.ticketNumber,
         churchName: church?.name,
         churchAddress: selectedEvent?.venue?.address,
         registrantName: registraa?.name,
         churchPhone: process?.env?.COMPANY_PHONE ? process?.env?.COMPANY_PHONE : church?.contact?.phone,
         churchEmail: process?.env?.COMPANY_EMAIL ? process?.env?.COMPANY_EMAIL : church?.contact?.email,
         churchLogo: church?.branding?.appIcon,
         churchCoverImage: selectedEvent?.image,
         churchWebsite: church?.contact?.website,

         // email: registraa?.email,
         // numberOfTickets: registrants?.length,
         // ticketNumbers: ticketNumbers,
         // registraa: registraa,
         // eventID: selectedEvent?.id,
         // registrants: registrants,
         // church: church,
         // payment: reference,
         // eventCoverImage: selectedEvent?.image,
         // reply_to: 'info@freshwavemvnt.com'
      };

      console.log({ data });

      addEmailToFirebase(data);

      navigate("/PaymentSuccess", {
         state: {
            registrants: registeredRegistrants,
            selectedEvent: selectedEvent,
            isPaid: true,
            church: church,
         }
      })
   };

   return (
      <Button
         variant="contained"
         startIcon={<PaidIcon />}
         style={{
            backgroundColor: "#000",
            color: "#fff",
            width: "100%",
            borderRadius: 5,
            height: 50,
            fontSize: 15,
         }}
         size="large"
         onClick={() => {
            initializePayment(onSuccess, onClose);
         }}
      >
         Pay Now
      </Button>
   );
};
