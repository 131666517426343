import Form from "react-bootstrap/Form";
import Select from "react-select";

export const SelectSermon = ({ sermons, setSermon, sermon, handleChange }) => {
   const handleUpdate = (selected) => {
      // event.preventDefault();
      console.log(selected);

      if (selected.value) {
         let title = unescapeHtml(selected.snippet.title);
         let coverPhoto = selected.snippet.thumbnail;
         let description = unescapeHtml(selected.snippet.description);
         setSermon({
            ...sermon,
            url: selected.value,
            title: title,
            coverPhoto: coverPhoto,
            description: description,
         });
      } else {
         setSermon({
            ...sermon,
            url: '',
            title: '',
         });
      }
   };
   return (
      <>
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label styles={{ color: '#000' }}>Select YouTube Video</Form.Label>
            <Select
               options={sermons}
               isClearable={true}
               isSearchable={true}
               backspaceRemovesValue={true}
               captureMenuScroll={true}
               onChange={handleUpdate}
               placeholder="Select Sermon Video"
               name="sermonId"
               defaultValue={sermons[0]}
            />
         </Form.Group>
      </>);
};

function unescapeHtml(unsafe) {
   return unsafe
      .replace(/&#39;/g, "\'")
      .replace(/&quot;/g, '\"')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&nbsp;/g, ' ')
      .replace(/&rsquo;/g, '\'')
      .replace(/&ldquo;/g, '\"')
      .replace(/&rdquo;/g, '\"')
      .replace(/&mdash;/g, '-')
      .replace(/&hellip;/g, '...')
      .replace(/&eacute;/g, 'e')
      .replace(/&ndash;/g, '-')
      .replace(/&uuml;/g, 'u')
      .replace(/&auml;/g, 'a')
      .replace(/&ouml;/g, 'o')
      .replace(/&Ouml;/g, 'O')
      .replace(/&Auml;/g, 'A')
      .replace(/&Uuml;/g, 'U')
      .replace(/&szlig;/g, 'ss')
      .replace(/&iexcl;/g, 'i')
      .replace(/&iquest;/g, 'i')
      .replace(/&i;/g, 'i')
      .replace(/&iacute;/g, 'i')
      .replace(/&ntilde;/g, 'n')
}