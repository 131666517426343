/* eslint-disable no-multi-str */
import React, { useState } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { FormGroup } from 'react-bootstrap';
// import { isIntro } from '../helpers/Bible';


// const BIBLE_API_KEY = '1e87472daacadfd6252844605ae660e7';

export const AddDevotional = () => {
   // const [devotional, setDevotional] = useState({
   //    title: null,
   //    message: null, //TODO: Submit via CKEditor5
   //    verse: {
   //       // TODO: Get Verses from Bible API
   //       reading: 'For God, so loved the world. That He gave His only Son.\
   //       That whosoever believes in Him shall not perish but have eternal Life.',
   //       reference: {
   //          book: 'John',
   //          chapter: 3,
   //          from: 16,
   //          to: null,
   //          bible: null,
   //       }
   //    },
   //    image: null,
   //    video: null,
   //    scheduledDateTime: null,

   // })
   return (
      <Container>
         <Row>
            <Col>
               <FormGroup>
                  <label htmlFor="title">Title</label>
                  <input type="text" name="title" id="title" />
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>
               <FormGroup>
                  <label htmlFor="message">Message</label>
                  <textarea name="message" id="message" cols="30" rows="10"></textarea>
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>
               <FormGroup>
                  <label htmlFor="verse">Verse</label>
                  <input type="text" name="verse" id="verse" />
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>
               <FormGroup>
                  <label htmlFor="image">Image</label>
                  <input type="file" name="image" id="image" />
               </FormGroup>
            </Col>
         </Row>
         <Row>
            <Col>
               <FormGroup>
                  <label htmlFor="video">Video</label>
                  <input type="file" name="video" id="video" />
               </FormGroup>
            </Col>
         </Row>
      </Container>
   );
}