import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import { TagsSelection } from "../../components/TagsSelection";
import { RolesSelection } from "../../components/RolesSelection";
import { InformationPopover } from "../../components/InformationPopover";
import { DocumentUploader } from "../../components/DocumentUploader";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";

import { Timestamp, collection, addDoc } from "firebase/firestore";
import { db } from "../../configs/firebase";

const MySwal = withReactContent(Swal);

export const AddResource = ({
  tags,
  setTags,
  selectedTags,
  setSelectedTags
}) => {
  const [resource, setResource] = useState({
    name: "",
    topic: "",
    createdAt: "",
    resourceLink: "",
    coverImage: "",
    description: "",
    tags: [],
    roles: []
  });
  const [resourceFile, setResourceFile] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setResource({
      ...resource,
      tags: selectedTags,
    });
  }, [selectedTags]);

  const handleChange = (e) => {
    if (e.target.name === "tags") {
      setSelectedTags([...selectedTags]);
    } else {
      setResource({
        ...resource,
        [e.target.name]: e.target.value,
      });
    }
  };

  const submitResource = async (event) => {
    event.preventDefault();
    const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
    const resourceObject = {
      name: resource.name,
      topic: resource.name,
      createdAt: date,
      resourceLink: resource.resourceLink,
      coverImage: resource.coverImage,
      description: resource.description,
      tags: selectedTags,
      roles: selectedRoles,
    };

    console.log({ resourceObject });

    if (!resourceObject.name) {
      enqueueSnackbar("Please enter a resource name", { variant: "error", });
      return;
    }

    if (!resourceObject.topic) {
      enqueueSnackbar("Please enter a resource topic", { variant: "error" });
      return;
    }

    if (!resourceObject.resourceLink) {
      enqueueSnackbar("Please upload a resource", { variant: "error" });
      return;
    }

    if (!resourceObject.coverImage) {
      enqueueSnackbar("Cover image not uploaded.", { variant: "warning" });
      resourceObject.coverImage = encodeURI(`https://placehold.co/600x800/000000/FFFFFF/png?text=Resource\nFile&font=roboto`);
    }

    if (!resourceObject.description) {
      enqueueSnackbar("You did not submit the resource description", { variant: "warning" });
      resourceObject.description = resourceObject.name;
    }

    if (resourceObject?.tags.length === 0) {
      enqueueSnackbar("Please select at least one tag", { variant: "error" });
      return;
    }

    if (resourceObject?.roles.length === 0) {
      enqueueSnackbar("Please select at least one role", { variant: "error" });
      return;
    }

    if (!resourceObject?.createdAt) {
      enqueueSnackbar("Failed to Create Resource in time. Please try again.", { variant: "error" });
      return;
    }

    try {
      await addDoc(collection(db, "resources"), resourceObject)
        .then(() => {
          MySwal.fire({
            title: "Success!",
            text: "Resource has been added",
            icon: "success",
            confirmButtonText: "OK",
          });
        });
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <Form
      style={{
        width: "50%",
        margin: "auto",
        padding: "2rem",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
      }}
      onSubmit={submitResource}
    >
      <h3 className="header text-center">Add Resource</h3>
      <Row>
        <p
          style={{
            color: "black",
            fontSize: "1.1rem",
            fontWeight: "500",
          }}
        >
          Add Resource Tags
        </p>
        <TagsSelection
          tags={tags}
          setTags={setTags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <p
          style={{
            color: "black",
            fontSize: "1.1rem",
            fontWeight: "500",
          }}
        >
          Who Should See This Resource?
        </p>
        <RolesSelection
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
        />
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Resource Name
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={resource.name}
            name="name"
            placeholder="name"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Resource
          </Form.Label>
          <DocumentUploader
            setState={setResource}
            state={resource}
            stateVariable={"resourceLink"}
            documentType={"pdf"}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Cover Image
          </Form.Label>
          <DocumentUploader
            setState={setResource}
            state={resource}
            stateVariable={"coverImage"}
            documentType={"image"}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            Resource Description
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            defaultValue={resource.description}
            rows={3}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          style={{
            width: "50%",
            marginLeft: '49%',
            fontSize: "1.1rem",
            height: "12%",
          }}
          variant="primary"
          type="submit"
          className="btn-primary"
        >
          Submit
        </Button>
      </Row>
    </Form>
  );
};
