import React, { useState, useEffect, createContext } from "react";
import { db, auth } from "../configs/firebase";

import { collection, onSnapshot, getDoc, doc } from "firebase/firestore";

export const CurrentUserContext = createContext(null);

export const CurrentUserProvider = ({ children }) => {
   const [currentUser, setCurrentUser] = useState(null);

   useEffect(() => {
      const curentUserId = auth.currentUser?.uid;
      if (curentUserId) {
         const unsubscribe = onSnapshot(doc(db, "users", curentUserId), (snapshot) => {
            setCurrentUser(snapshot.data());
         });
         return () => unsubscribe();
      } else {
         setCurrentUser(null);
      }
   }, []);

   return (
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
         {children}
      </CurrentUserContext.Provider>
   );
};