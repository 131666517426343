import React, { useState } from 'react';
import {
   Button,
   TextField,
   FormControlLabel,
   Checkbox,
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   useMediaQuery,
   useTheme
} from '@material-ui/core';

export const AddEvent = () => {
   const [formState, setFormState] = useState({
      title: '',
      description: '',
      eventType: '',
      coverImage: '',
      priceAdults: '',
      priceKids: '',
      currencySymbol: '',
      currencyTicker: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      adultsAllowed: false,
      childrenAllowed: false,
      externalRegistrationLink: '',
      externalRegistrationButtonTitle: '',
      isExternalRegistrationOnly: false,
      isPaid: false,
      isRegistrationActive: false,
      maxParticipants: '',
      venueName: '',
      latitude: '',
      longitude: '',
      address: '',
      startingTime: '',
      endingTime: ''
   });

   const [currentStep, setCurrentStep] = useState(1);
   const theme = useTheme();
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormState((prevState) => ({
         ...prevState,
         [name]: value
      }));
   };

   const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      setFormState((prevState) => ({
         ...prevState,
         [name]: checked
      }));
   };

   const handleNextStep = () => {
      setCurrentStep((prevStep) => prevStep + 1);
   };

   const isNextButtonDisabled = () => {
      // Add validation logic here to check if all required fields are filled with valid data
      // For example:
      if (currentStep === 1 && !formState.title) {
         return true;
      }

      // Add more validation logic for other steps here

      return false;
   };

   return (
      <Dialog open={true} fullScreen={fullScreen} fullWidth maxWidth="md">
         <DialogTitle>Create Event</DialogTitle>
         <DialogContent>
            {currentStep === 1 && (
               <div>
                  <TextField
                     label="Title"
                     name="title"
                     value={formState.title}
                     onChange={handleInputChange}
                  />
                  <TextField
                     label="Description"
                     name="description"
                     value={formState.description}
                     onChange={handleInputChange}
                  />
                  <TextField
                     label="Event Type"
                     name="eventType"
                     value={formState.eventType}
                     onChange={handleInputChange}
                  />
                  <TextField
                     label="Cover Image"
                     name="coverImage"
                     value={formState.coverImage}
                     onChange={handleInputChange}
                  />
               </div>
            )}
            {currentStep === 2 && (
               <div>
                  {/* Add form inputs for step 2 here */}
                  <FormControlLabel
                     control={
                        <Checkbox
                           name="adultsAllowed"
                           checked={formState.adultsAllowed}
                           onChange={handleCheckboxChange}
                        />
                     }
                     label="Adults Allowed"
                  />
               </div>
            )}
            {/* Add more steps here */}
            {currentStep === 3 && (
               <div>
                  {/* Add form inputs for final step here */}
               </div>
            )}
         </DialogContent>
         <DialogActions>
            {currentStep < 3 ? (
               <Button onClick={handleNextStep} disabled={isNextButtonDisabled()}>
                  Next
               </Button>
            ) : (
               <Button>Submit</Button>
            )}
         </DialogActions>
      </Dialog>
   );
};