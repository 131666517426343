import { NavigationBar } from "./components/NavigationBar";
import ResponsiveNavbar from "./components/ResponsiveNavbar";
import Container from "react-bootstrap/Container";
import "./App.css";
import { useEffect } from "react";
import { DashBoard } from "./components/DashBoard";
import { useNavigate } from "react-router-dom";


function App() {
  let navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/");
    }

    if (!authToken) {
      navigate("/Login");
    }
  }, [navigate]);

  return (
    <>
      <ResponsiveNavbar />
      <Container
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <Container className="p-3">
          <h1 style={{ textAlign: "center" }}>
            Welcome, Back to Admin Dashboard
          </h1>
          <Container className="p-5 mb-4 bg-light rounded-3">
            <DashBoard />
          </Container>
        </Container>
      </Container>
    </>
  );
}

export default App;
