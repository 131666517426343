import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { AiOutlineFolderOpen, AiOutlineLink } from "react-icons/ai";
import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button';

//firebase
import { db } from "../configs/firebase";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";

export const BannersListItem = ({ banner }) => {
  const deactivateBanner = () => {
    const docRef = doc(db, "banners", banner.id);
    updateDoc(docRef, {
      active: false,
    });
  }

  return (
    <Card style={{ width: "46%" }}>
      <Card.Img variant="top" src={banner?.image} />
      <Card.Body>
        <Card.Title
          style={{
            fontSize: "0.8rem",
            width: "50%",
          }}
        >
          {banner?.campaignName}
        </Card.Title>
        <ListGroup>
          {banner?.link?.url ? (
            <ListGroup.Item>
              <Card.Link
                variant="primary"
                href={banner.link.url}
                className="btn btn-primary"
                style={{
                  opacity: "0.9",
                  height: "20px",
                  width: "40px",
                  position: "relative",
                }}
                hover={{
                  opacity: "1",
                }}
              >
                <AiOutlineLink
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    position: "absolute",
                    left: "33%",
                    top: "11%",
                  }}
                />
              </Card.Link>
            </ListGroup.Item>
          ) : null}
          <ListGroup.Item
            style={{
              display: 'inline-block',
              justifyContent: 'space-between'
            }}>
            <span
              style={{ display: 'inline-block', float: 'left' }}>
              Start Date
            </span>
            <Badge
              style={{ display: 'inline-block', float: 'right' }}
              bg="primary">{(new Date(banner.startTime)).toLocaleDateString()}
            </Badge>
          </ListGroup.Item>
          <ListGroup.Item
            style={{
              display: 'inline-block',
              justifyContent: 'space-between'
            }}>
            <span
              style={{ display: 'inline-block', float: 'left' }}>
              End Date
            </span>
            <Badge
              style={{ display: 'inline-block', float: 'right' }}
              bg="danger">{(new Date(banner.endTime)).toLocaleDateString()}
            </Badge>
          </ListGroup.Item>
          <ListGroup.Item
            style={{
              display: 'inline-block',
              justifyContent: 'space-between'
            }}>
            <span
              style={{ display: 'inline-block', float: 'left' }}>
              Views
            </span>
            <Badge
              style={{ display: 'inline-block', float: 'right' }}
              bg="success">{banner.seenBy.length}
            </Badge>
          </ListGroup.Item>
        </ListGroup>
        <Button
          variant="danger"
          style={{
            marginTop: '10px',
          }}
          onClick={deactivateBanner}
        >Deactivate Banner</Button>
      </Card.Body>
    </Card>
  );
};
