import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';

import { ChurchContext } from '../contexts/ChurchContext';


export const SelectEvent = ({ events, selectedEvent, setSelectedEvent }) => {
   const [options, setOptions] = useState(null);

   const { church } = useContext(ChurchContext);

   const handleChange = (selected) => {
      console.log(selected);
      if (selected.value) {
         setSelectedEvent(selected.value);
      } else {
         setSelectedEvent(null);
      }
   }

   useEffect(() => {
      if (events) {
         const options = events.map(event => {
            let label = `${event.title} - (${event.startingTime.toDate().toLocaleDateString()})`
            return {
               label: label,
               value: event.id,
            }
         });
         options.unshift({ label: "Select Event", value: null });
         setOptions(options);
      }
   }, [events]);


   if (!options) {
      return (
         <p>Loading...</p>
      )
   }
   return (
      <Select
         options={options}
         name="event"
         isSearchable={false}
         placeholder="Select Event"
         autoFocus={true}
         onChange={handleChange}
         defaultValue={options[0]}
      />
   );
};