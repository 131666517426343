// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
// import Container from "react-bootstrap/Container";
import "../../components/css/ResourcesList.css";
import "../../components/css/CoursesGrid.css";
// import { NavigationBar } from "../../components/NavigationBar";

import { useState, useEffect } from "react";
// import { db } from "../../configs/firebase";
// import { collection, onSnapshot } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import axios from 'axios';

export const Shorts = () => {
   const [shorts, setShorts] = useState([]);
   const API_KEY = "AIzaSyBIX7C7b7_-uADbgwQUH6a58YyMwNPwLmc";
   const CHANNEL_ID = "UCER2LBsm4hB_Kxte63RoftQ";

   const navigate = useNavigate();
   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/ConnectGroups");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   useEffect(() => {
      const fetchShorts = async () => {
         // Use the `search.list` method of the YouTube API
         // to get a list of videos in the specified channel
         // that are marked as Shorts
         const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
            params: {
               key: API_KEY,
               channelId: CHANNEL_ID,
               type: 'video',
               videoDuration: 'short',
            },
         });
         const data = response.data;

         // Set the list of shorts in state
         setShorts(data.items);
      };

      fetchShorts();
   }, []);

   return (
      <div>
         {shorts.map(short => (
            <div key={short.id.videoId}>
               <h1>{short.snippet.title}</h1>
               <iframe
                  title={short.snippet.title}
                  src={`https://www.youtube.com/embed/${short.id.videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
               />
            </div>
         ))}
      </div>
   );
};