import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { AddNotification } from "../forms/AddNotification";
import { NotificationsList } from "../../components/NotificationsList";

import { useState, useEffect } from "react";

import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";

// Notifications
// import { Expo } from 'expo-server-sdk';

import { useNavigate } from "react-router-dom";

export const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  // const [selectedRoles, setSelectedRoles] = useState([]);
  // const [roles, setRoles] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);

  // let expo = new Expo();

  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/Notifications");
    } else {
      navigate("/Login");
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "notifications"),
      (snapshot) => {
        const notificationsList = snapshot.docs.map((notificationItem) => {
          return {
            ...notificationItem.data(),
            id: notificationItem.id,
          };
        });
        setNotifications(notificationsList);
      }
    );
    return () => unsubscribe();
  }, []);
  // Get Roles
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
  //     const roles = [];
  //     const userRolesList = snapshot.docs.map((doc) => {
  //       return {
  //         id: doc.id,
  //         categories: [...doc.data().categories],
  //       };
  //     });

  //     userRolesList.forEach((user) => {
  //       user.categories.forEach((category) => {
  //         if (!roles.includes(category)) {
  //           roles.push(category);
  //         }
  //       });
  //     });
  //     setRoles(roles);
  //   });
  //   return () => unsubscribe();
  // }, []);

  return (
    <>
      <ResponsiveNavbar />
      <Container
        fluid
        style={{
          width: "100%",
          backgroundColor: "#f5f5f5",
          marginTop: "5%",
        }}
      >
        <Row>
          <Col>
            <h3 className="header text-center">Add Notification</h3>
            <AddNotification
            // roles={roles}
            // setRoles={setRoles}
            // selectedRoles={selectedRoles}
            // setSelectedRoles={setSelectedRoles}
            // selectedUsers={selectedUsers}
            // setSelectedUsers={setSelectedUsers}
            />
          </Col>
          <Col>
            <h3 className="header text-center">Notifications</h3>
            <NotificationsList notifications={notifications} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

// Can use this function below, OR use Expo's Push Notification Tool-> https://expo.dev/notifications
// async function sendPushNotification(expoPushToken, sound, title, body, data) {
//   const message = {
//     to: expoPushToken,
//     sound: sound,
//     title: title,
//     body: body,
//     data: { ...data },
//   };

//   await fetch("https://exp.host/--/api/v2/push/send", {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Accept-encoding": "gzip, deflate",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(message),
//   });
// }
