import Card from "react-bootstrap/Card";

export const ConnectGroupCard = ({ connectGroups, connectGroup, style }) => {
  return (
    <Card style={{ width: "15rem", ...style }}>
      <Card.Img
        variant="top"
        src={connectGroup.posterLink}
        style={{
          height: "10rem",
          objectFit: "cover",
        }}
      />
      <Card.Body
        style={{
          height: "150px",
        }}
      >
        <Card.Title
          style={{
            fontSize: "0.8rem",
            fontWeight: "bold",
            marginTop: "-10px",
          }}
        >
          {connectGroup.name}
        </Card.Title>
        <Card.Text
          style={{
            height: "40px",
            fontSize: "0.7rem",
            marginTop: "-10px",
          }}
        >
          {connectGroup.description?.length > 100
            ? connectGroup.description?.slice(0, 100) + "..."
            : connectGroup.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
