import "../../components/css/UnAuthorized.css";

export const UnAuthorized = () => {
  return (
    <div>
      <div>
        <img
          className="svg-img"
          src="img/undraw_security_on.svg"
          alt="security on"
        />
      </div>
      <div
        className="unAuthorized-text"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        <h1 className="unAuthorize-h1"> UnAuthorized</h1>
        <p className="unAuthorized-p">
          {" "}
          You are not authorized to use this system. Please, refer to Admin.
        </p>
        <button
          className="unAuthorized-btn"
          onClick={() => {
            window.location.href = "/Login";
          }}
        >
          Login
        </button>
      </div>
    </div>
  );
};
