import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// context
import { ChurchContext } from '../contexts/ChurchContext';
import { CurrentUserContext } from '../contexts/CurrentUserContext';

//firebase
import { auth } from "../configs/firebase";
import { signOut } from "firebase/auth";

// Helpers
import { getNames } from '../helpers/Validators';

const pages = ['Events', 'EventBooking'];
const navigationMap = {
   Events: '/EventRegistrationDashboard',
   EventBooking: '/EventBooking',
}
const settings = ['Dashboard', 'Locations', 'Register', 'Users', 'Logout'];
const settingsMap = {
   Register: '/SignUp',
   Users: '/Users',
   Dashboard: '/',
   Locations: '/Locations',
   Logout: '/Logout',
}
const navItems = ['Home', 'About', 'Contact'];
const drawerWidth = 240;

function ResponsiveNavbar(props) {
   const [anchorElNav, setAnchorElNav] = React.useState(null);
   const [anchorElUser, setAnchorElUser] = React.useState(null);
   const navigate = useNavigate();
   const { church, setChurch } = useContext(ChurchContext);
   const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
   const { fullName } = getNames(currentUser);
   const { enqueueSnackbar } = useSnackbar();

   const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
   };
   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   const { window } = props;
   const [mobileOpen, setMobileOpen] = React.useState(false);

   const handleDrawerToggle = () => {
      setMobileOpen((prevState) => !prevState);
   };

   const drawer = (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
         <Typography variant="h6" sx={{ my: 2 }}>
            MUI
         </Typography>
         <Divider />
         <List>
            {navItems.map((item) => (
               <ListItem key={item} disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' }}>
                     <ListItemText primary={item} />
                  </ListItemButton>
               </ListItem>
            ))}
         </List>
      </Box>
   );

   const container = window !== undefined ? () => window().document.body : undefined;
   return (
      <Box sx={{ display: 'flex' }}>
         <CssBaseline />
         <AppBar component="nav">
            <Container maxWidth="xl">
               <Toolbar disableGutters>
                  <Avatar alt="Church Logo" src={church?.branding?.appIcon} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                  <Typography
                     variant="h6"
                     noWrap
                     component="a"
                     href="/"
                     sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                     }}
                  >
                     HOME
                  </Typography>

                  <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                     <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                     >
                        <MenuIcon />
                     </IconButton>
                     <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                           display: { xs: 'block', md: 'none' },
                        }}
                     >
                        {pages.map((page) => (
                           <MenuItem key={page} onClick={() => {
                              handleCloseNavMenu();
                              navigate(`${navigationMap[page]}`);
                           }}>
                              <Typography textAlign="center">{page}</Typography>
                           </MenuItem>
                        ))}
                     </Menu>
                  </Box>
                  <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                  <Typography
                     variant="h5"
                     noWrap
                     component="a"
                     href=""
                     sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                     }}
                  >
                     HOME
                  </Typography>
                  <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                     {pages.map((page) => (
                        <Button
                           key={page}
                           onClick={() => {
                              handleCloseNavMenu();
                              navigate(`${navigationMap[page]}`);
                           }}
                           sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                           {page}
                        </Button>
                     ))}
                  </Box>

                  <Box sx={{ flexGrow: 0 }}>
                     <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                           <Avatar alt={fullName} src={currentUser?.profilePicture} />
                        </IconButton>
                     </Tooltip>
                     <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                     >
                        {settings.map((setting) => (
                           <MenuItem key={setting} onClick={() => {
                              handleCloseUserMenu();
                              if (setting === 'Logout') {
                                 signOut(auth)
                                    .then(() => {
                                       localStorage.removeItem("Auth Token");
                                       navigate("/Login");
                                    })
                                    .catch((error) => {
                                       console.log(error);
                                       enqueueSnackbar(error.message, { variant: 'error' });
                                    });
                              } else {
                                 navigate(`${settingsMap[setting]}`);
                              }
                           }}>
                              <Typography textAlign="center">{setting}</Typography>
                           </MenuItem>
                        ))}
                     </Menu>
                  </Box>
               </Toolbar>
            </Container>
         </AppBar>
         {
            props.children
         }
      </Box>
   );
}

ResponsiveNavbar.propTypes = {
   /**
    * Injected by the documentation to work in an iframe.
    * You won't need it on your project.
    */
   window: PropTypes.func,
};
export default ResponsiveNavbar;
