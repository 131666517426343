import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from 'react-select';

import { useState } from "react";

import { db } from "../configs/firebase";
import { doc, updateDoc, Timestamp, collection, addDoc } from "firebase/firestore";
import { Container } from "react-bootstrap";
import { useSnackbar } from "notistack";

//Helpers
import { validateEmail } from "../helpers/Validators";

const MySwal = withReactContent(Swal);

export const AddGuestModal = ({
   selectedEvent,
   guests,
   setGuests,
}) => {
   const { enqueueSnackbar } = useSnackbar();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [guest, setGuest] = useState({
      name: "",
      email: "",
      phone: "",
      age: "",
      sex: "",
      eventId: selectedEvent,
   });

   const ages = [
      { value: "", label: "Select Age" },
      { value: "Adult", label: "Adult" },
      { value: "Children", label: "Children" },
   ];

   const handleChange = (event) => {
      event.preventDefault();
      setGuest({
         ...guest,
         [event.target.name]: event.target.value,
      });
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      if (guest.name === "") {
         enqueueSnackbar("Please enter a name", {
            variant: "warning",
         });
         return;
      }

      if (guest.age === "") {
         enqueueSnackbar("Please select the age of your guest", {
            variant: "warning",
         });
         return;
      }
      if (guest.sex === "") {
         enqueueSnackbar("Please select the sex of your guest", {
            variant: "warning",
         });
         return;
      }
      if (guest.phone === "") {
         enqueueSnackbar("Please provide a phone number for us to contact your guest", {
            variant: "warning",
         });
         return;
      }
      if (guest.email === "") {
         enqueueSnackbar("Please provide an email address for us to contact your guest", {
            variant: "warning",
         });
         return;
      }

      //Validate email
      if (!validateEmail(guest.email)) {
         enqueueSnackbar("Please enter a valid email", {
            variant: "info",
         });
         return;
      }

      if (guests) {
         setGuests([...guests, guest]);
      } else {
         setGuests([guest]);
      }

      enqueueSnackbar("Guest added successfully", {
         variant: "success",
      });
      setGuest({
         name: "",
         email: "",
         phone: "",
         age: "",
         sex: "",
         eventId: selectedEvent,
      })
      handleClose();
   };


   const submitSermon = async (e) => {
      e.preventDefault();

      if (guest.name === "") {
         enqueueSnackbar("Please enter a sermon title", {
            variant: "info",
         });
         return;
      }

      let notification = {
         title: guest.name,
         message: `New sermon by ${guest.email} is available. ${guest.age}`,
         image: guest.sex,
         targetUsers: ['General'],
      }
      try {
         await addDoc(collection(db, "sermons"), guest);
         enqueueSnackbar("Sermon added successfully", {
            variant: "success",
         });
         setGuest({
            name: "",
            email: "",
            date: "",
            phone: "",
            age: "",
            sex: "",
            portraitCover: "",
            selectedEvent: '',
         });

         submitNotification(notification);

         MySwal.fire({
            title: "Success!",
            text: "Sermon has been added",
            icon: "success",
            confirmButtonText: "OK",
         });
      } catch (err) {
         MySwal.fire({
            title: "Error!",
            text: err.message,
            icon: "error",
            confirmButtonText: "OK",
         });
      }
   };

   const submitNotification = async (notification) => {
      const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
      try {
         await addDoc(collection(db, "notifications"), {
            ...notification,
            createdAt: date || null,
            createdBy: guest.email,
            scheduledTime: new Date(),
         }).then(() => {
            console.log("Notification added...");
         });
         enqueueSnackbar("Notification sent!", {
            variant: "success",
         });

      } catch (err) {
         enqueueSnackbar(err.message, {
            variant: "error",
         });
         console.log(err);
      }
   };

   const handleAgeChange = (selected) => {
      if (selected.value) {
         setGuest({
            ...guest,
            age: selected.value,
         });
      } else {
         setGuest({
            ...guest,
            age: "",
         });
      }
   }
   const handleSexChange = (selected) => {
      if (selected.value) {
         setGuest({
            ...guest,
            sex: selected.value,
         });
      } else {
         setGuest({
            ...guest,
            sex: "",
         });
      }
   }

   return (
      <>
         <Button
            variant="primary"
            onClick={handleShow}
            className="btn btn-primary"
            style={{
               width: "100%",
               height: "100%",
               margin: "auto",
               display: "block",
               justifyContent: "center",
               alignItems: "center",
            }}
            disabled={selectedEvent ? false : true}
         >
            Add Guest
         </Button>

         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Add Guest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form
                  style={{
                     width: "100%",
                     padding: "5%",
                     margin: "auto",
                     backgroundColor: "white",
                     borderRadius: "10px",
                     // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",

                  }}
               >
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label style={{
                        color: "black"
                     }} >Full Name</Form.Label>
                     <Form.Control
                        type="text"
                        defaultValue={guest.name}
                        name="name"
                        placeholder="name"
                        onChange={handleChange}
                        required
                     />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label style={{
                        color: "black"
                     }}>Email</Form.Label>
                     <Form.Control
                        type="email"
                        name="email"
                        defaultValue={guest.email}
                        placeholder="email"
                        onChange={handleChange}
                     />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label style={{
                        color: '#000'
                     }}>Contact</Form.Label>
                     <Form.Control
                        type="tel"
                        name="phone"
                        defaultValue={guest.phone}
                        placeholder="phone"
                        onChange={handleChange}
                     />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                     <Form.Label style={{
                        color: "black"
                     }} >Age Group</Form.Label>
                     <Select
                        options={ages}
                        name="age"
                        isSearchable={false}
                        placeholder="Select Age"
                        autoFocus={false}
                        onChange={handleAgeChange}
                        defaultValue={ages[0]}
                     />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                     <Form.Label style={{
                        color: "black"
                     }} >Sex</Form.Label>
                     <Select
                        options={[
                           { value: "", label: "Select Sex" },
                           { value: "Male", label: "Male" },
                           { value: "Female", label: "Female" }
                        ]}
                        name="sex"
                        isSearchable={false}
                        placeholder="Select Sex"
                        autoFocus={false}
                        onChange={handleSexChange}
                     />
                  </Form.Group>
                  <Button
                     style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        display: "block",
                        justifyContent: "center",
                        marginTop: "10%"
                     }}
                     className="btn btn-primary"
                     variant="primary"
                     onClick={handleSubmit}
                  >
                     Submit
                  </Button>
               </Form>
            </Modal.Body>
         </Modal>
      </>
   );
};
