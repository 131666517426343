import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const PrayerRequests = () => {
  // const [prayerRequests, setPrayerRequests] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/PrayerRequests");
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  return (
    <>
      <ResponsiveNavbar />
      <Container
        fluid
        style={{
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Row>
          <Col>
            <h3 className="header text-center">Respond</h3>
            {/* <Respond /> */}
          </Col>
          <Col>
            <h3 className="header text-center">Prayer Requests</h3>
            {/* <PrayerRequestsStack /> */}
          </Col>
          <Col>
            <h3 className="header text-center">Prayer Requests</h3>
            {/* <PrayerRequestsStackTwo /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};
