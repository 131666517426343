import React, { useState, useContext, useEffect, useRef } from 'react'
import { Container, ProgressBar } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select';
import { Paper } from '@mui/material';
import ReactLoading from "react-loading";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Timestamp, collection, addDoc, onSnapshot, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../configs/firebase";
import uuid from 'react-uuid';

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

//Context
import { ChurchContext } from "../../contexts/ChurchContext.js";
import { EventRegistrantsContext } from "../../contexts/EventRegistrantsContext.js";

import { SelectEvent } from "../../components/SelectEvent";
import { NoEvents } from '../../components/NoEvents';
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { PaystackTransactionsTable } from "../../components/PaystackTransactionsTable";

// mui datagrid
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as MuiSelect } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

// Helpers
import { validateEmail, formatPhoneNumber } from '../../helpers/Validators';
import { turnGuestToRegistrant } from "../../helpers/EventRegistration";
import { AddEventModal } from "../../components/AddEventModal";

const MySwal = withReactContent(Swal);

const columns = [
   { field: 'id', headerName: 'ID', width: 90 },
   {
      field: 'name',
      headerName: 'Name',
      width: 250,
   },
   {
      field: 'ticketNumber',
      headerName: 'Ticket Number',
      width: 250,
   },
   {
      field: 'price',
      headerName: 'Price',
      width: 160,
   },
   {
      field: 'status',
      headerName: 'Status',
      width: 160,
   },
   {
      field: 'reference',
      headerName: 'Reference',
      width: 200,
   },
   {
      field: 'sex',
      headerName: 'Sex',
      width: 150,
   },
   {
      field: 'age',
      headerName: 'Age',
      width: 110,
   },
   {
      field: 'email',
      headerName: 'Email',
      width: 250,
   },
   {
      field: 'phone',
      headerName: 'Phone',
      width: 250,
   },
   {
      field: 'registeredVia',
      headerName: 'Registered Via',
      width: 250,
   }
];

export default function EventRegistrationDashboard() {
   const { enqueueSnackbar } = useSnackbar();
   const navigate = useNavigate();

   const {
      registrants: eventRegistrants,
      setRegistrants: setEventRegistrants,
      setTotalPrice,
      setRegistraa,
      registraa,
      setSelectedEvent: setSelectedFunction,
      selectedEvent: selectedFunction,
   } = useContext(EventRegistrantsContext);
   const [events, setEvents] = useState([]);
   const [selectedEvent, setSelectedEvent] = useState(null);
   const [eventDocument, setEventDocument] = useState(null);
   const [rows, setRows] = useState([]);
   const [registeredPeople, setRegisteredPeople] = useState([]);
   const [viewEvents, setViewEvents] = useState(true);
   const [viewRegistrants, setViewRegistrants] = useState(true);
   const [viewTransactions, setViewTransactions] = useState(false);
   const [open, setOpen] = useState(false);

   const { church } = useContext(ChurchContext);
   const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
   const isBigScreen = useMediaQuery({ minWidth: 1824 })
   const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
   const isPortrait = useMediaQuery({ orientation: 'portrait' })
   const isRetina = useMediaQuery({ minResolution: '2dppx' })

   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/EventRegistrationDashboard");
      } else {
         navigate("/Login");
      }
   }, []);

   useEffect(() => {
      const unsubscribe = onSnapshot(collection(db, "events"), (snapshot) => {
         const eventsList = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
         setEvents(eventsList);
      });

      return () => unsubscribe();
   }, []);

   useEffect(() => {
      if (selectedEvent) {
         console.log("Selected Event", selectedEvent);
         let eventDocument = events.find((event) => event.id === selectedEvent);
         setEventDocument(eventDocument);
         let registrants = eventDocument?.registrants?.map((registrant, index) => {
            return {
               id: index,
               name: registrant.name,
               sex: registrant?.sex ? registrant.sex : "N/A",
               age: registrant.age,
               ticketNumber: registrant.ticketNumber,
               reference: registrant?.payment?.reference,
               email: registrant?.email ? registrant.email : "N/A",
               phone: registrant?.phone ? registrant.phone : "N/A",
               isGuest: registrant?.isGuest,
               isRegisteringGuests: registrant?.isRegisteringGuests,
               isPaid: registrant?.isPaid,
               date: registrant?.date.toDate().toLocaleString(),
               time: registrant?.date.toDate().toLocaleTimeString(),
               paymentStatus: registrant?.payment?.status,
               price: registrant?.price ? registrant.price : 0,
               transactionId: registrant?.transactionId,
               registeredBy: registrant?.registeredBy ? registrant.registeredBy : "N/A",
               registrationId: registrant?.registrationId,
               registeredVia: registrant?.registeredVia ? registrant.registeredVia : "Mobile App",
               userId: registrant?.userId ? registrant.userId : "N/A",
            };
         });
         setRegisteredPeople(registrants);
         console.log("Registered People", registrants);
      } else {
         setRegisteredPeople([])
         setEventDocument(null)
         setRows([])
      }
   }, [selectedEvent, events]);


   useEffect(() => {
      if (registeredPeople?.length > 0) {
         let rows = registeredPeople.map((person, index) => {
            let tel = formatPhoneNumber(person.phone);
            console.log("Tel", tel);
            return {
               id: index,
               name: person.name,
               ticketNumber: person.ticketNumber,
               reference: person.reference,
               sex: person.sex,
               age: person.age,
               price: Number(person.price).toLocaleString("en-US", {
                  style: "currency",
                  currency: "ZAR",
               }),
               status: person.isPaid ? "Paid" : "Not Paid",
               email: person.email,
               phone: tel,
               registeredVia: person.registeredVia,
            }
         })

         setRows(rows)
      }
   }, [registeredPeople])

   function CustomToolbar() {
      return (
         <GridToolbarContainer>
            <GridToolbarExport />
         </GridToolbarContainer>
      );
   }

   function NoRowsOverlay() {
      return (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <h3>No Registrants</h3>
         </div>
      );
   }


   return (
      <>
         <ResponsiveNavbar />
         <Container
            spacing={2}
            style={{
               width: isDesktopOrLaptop ? "60%" : "100%",
               justifyContent: "space-around",
               marginTop: isDesktopOrLaptop ? "5%" : "15%",
            }}>
            <Button
               style={{
                  backgroundColor: viewRegistrants ? "#3f11b5" : "#3f51b5",
                  color: "white",
                  width: isDesktopOrLaptop ? "30%" : "100",
                  alignSelf: "center",
                  margin: "1%",
                  opacity: viewRegistrants ? 1 : 0.5
               }}
               onClick={() => {
                  setViewTransactions(false);
                  setViewRegistrants(true);
               }}
            >
               Event Registrants
            </Button>
            <Button
               style={{
                  backgroundColor: viewTransactions ? "#3f11b5" : "#3f51b5",
                  color: "white",
                  width: isDesktopOrLaptop ? "30%" : "100",
                  alignSelf: "center",
                  margin: "1%",
                  opacity: viewTransactions ? 1 : 0.5
               }}
               onClick={() => {
                  if (!selectedEvent) {
                     MySwal.fire({
                        title: "Event Not Selected",
                        text: "Please Select an Event First to View Transactions",
                        icon: "info",
                        confirmButtonText: "CLOSE",
                     });
                  } else {
                     setViewRegistrants(false);
                     setViewTransactions(true);
                  }
               }}
            >
               Transactions
            </Button>
            <MuiButton
               style={{
                  backgroundColor: '#3f51b5',
                  color: "white",
                  float: "right",
                  marginTop: "1%",
               }}
               variant="contained"
               startIcon={<AddCircleOutlineIcon />}
               onClick={() => {
                  setOpen(true);
               }}
            >
               Create
            </MuiButton>
            <AddEventModal open={open} setOpen={setOpen} />
         </Container>
         <Container
            style={{
               padding: "10px",
               borderRadius: "10px",
               alignSelf: "center",
               width: isDesktopOrLaptop ? "60%" : "100%",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               marginTop: "1%"
            }}
         >
            <div className="col-lg-12 grid-margin stretch-card">
               <div className="card">
                  <div className="card-body">
                     <Row>
                        <Col>
                           <h4 className="card-title">{viewRegistrants ? "Event Registrants" : "All Transactions"}</h4>
                           <p className="card-description">
                              {eventDocument ? eventDocument?.title : "Select an event"}
                           </p>
                        </Col>
                        <Col>
                           <SelectEvent
                              events={events}
                              selectedEvent={selectedEvent}
                              setSelectedEvent={setSelectedEvent}
                           />
                        </Col>
                     </Row>
                     <div className="table-responsive">
                        <Box sx={{ width: '100%' }}>
                           {
                              viewRegistrants && (
                                 <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    autoHeight={true}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    density="comfortable"
                                    components={{
                                       Toolbar: CustomToolbar,
                                       NoRowsOverlay: NoRowsOverlay,
                                    }}
                                    compomentProps={{
                                       toolbar: {
                                          value: "Export To Csv",
                                          onClick: () => { console.log("Export to csv") },
                                          style: { backgroundColor: "red" },
                                          csvOptions: {
                                             fileName: `${eventDocument ? eventDocument?.title : ""}Registrants`,
                                             allColumns: true,
                                             onlySelected: true,
                                             separator: ",",
                                             ignoreHeader: false,
                                             withBOM: true,
                                             utf8WithBom: true,
                                             exportMode: "selected",
                                          },
                                       },
                                    }}
                                    sx={{
                                       boxShadow: 2,
                                       '& .MuiDataGrid-cell:hover': {
                                          color: 'primary.main',
                                       },
                                       minHeight: 400,
                                    }}
                                 />
                              )
                           }
                           {
                              viewTransactions && (
                                 <PaystackTransactionsTable
                                    selectedEvent={eventDocument}
                                    registrants={registeredPeople}
                                    successfulRegistrations={registeredPeople}
                                 />
                              )
                           }
                        </Box>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </>
   )
}