import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import "./css/Gif.css";

// context
import { ChurchContext } from "../contexts/ChurchContext";

export const NoEvents = () => {
   const { church } = useContext(ChurchContext);
   return (
      <div
         className='hero'
      >
         <p
            style={{
               marginLeft: "2%",
               fontSize: "2rem",
               width: "20%",
               textAlign: "center",
               fontWeight: "bold",
               textShadow: "2px 1px 4px #000000",
            }}
         >
            There are no event available right now. Please check back later.
         </p>
         <div className="get-app">
            <div
               style={{
                  marginRight: "2%",
                  cursor: "pointer",
               }}
            >
               <Alert.Link
                  href={church?.playStoreLink}
                  target='_blank'
                  rel='noopener noreferrer'
               >
                  <img
                     src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                     alt='Get it on Google Play'
                     style={{
                        height: "59px",
                        marginTop: "-6%",
                     }}
                  />
               </Alert.Link>
            </div>
            <div
               style={{
                  marginRight: "2%",
                  cursor: "pointer",
               }}
            >
               <Alert.Link
                  href={church?.appStoreLink}
                  target='_blank'
                  rel='noopener noreferrer'
               >
                  <img
                     src='https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2015-06-16&kind=iossoftware&bubble=ios_apps'
                     alt='Download on the App Store'
                  />
               </Alert.Link>
            </div>
         </div>
      </div >
   );
};