import { useState, useEffect, useRef, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from 'react-select';

import { SelectEvent } from "../../components/SelectEvent";
import { AddGuest } from "./AddGuest";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Timestamp, collection, addDoc, onSnapshot, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db } from "../../configs/firebase";
import uuid from 'react-uuid';

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

//Context
import { ChurchContext } from "../../contexts/ChurchContext.js";
import { EventRegistrantsContext } from "../../contexts/EventRegistrantsContext.js";

// Helpers
import { validateEmail } from '../../helpers/Validators';
import { turnGuestToRegistrant } from "../../helpers/EventRegistration";


export const EventBookingForm = ({ events }) => {

   const { enqueueSnackbar } = useSnackbar();
   const MySwal = withReactContent(Swal);
   const navigate = useNavigate();
   const { church } = useContext(ChurchContext);
   const {
      registrants: eventRegistrants,
      setRegistrants: setEventRegistrants,
      setTotalPrice,
      setRegistraa,
      registraa,
      setSelectedEvent: setSelectedFunction,
      selectedEvent: selectedFunction,
   } = useContext(EventRegistrantsContext);
   const [guests, setGuests] = useState([]);
   const [selectedEvent, setSelectedEvent] = useState(events[0].id);

   const [interestedGuest, setInterestedGuest] = useState({
      name: "",
      email: "",
      date: "",
      phone: "",
      age: "",
      sex: "",
      eventId: selectedEvent,
   });

   const ages = [
      { value: "", label: "Select Age" },
      { value: "Adult", label: "Adult" },
      { value: "Child", label: "Child" },
   ];

   useEffect(() => {
      setInterestedGuest({
         ...interestedGuest,
         eventId: selectedEvent,
      })
   }, [selectedEvent]);

   const handleChange = (e) => {
      console.log(e.target.name);
      if (e.target && e.target.name === "date") {
         setInterestedGuest({
            ...interestedGuest,
            [e.target.name]: Timestamp.fromDate(new Date(e.target.value)),
         });

      } else if (e.target && e.target.name) {
         setInterestedGuest({
            ...interestedGuest,
            [e.target.name]: e.target.value,
         });
      } else if (e?.value) {
         setInterestedGuest({
            ...interestedGuest,
            name: e.snippet.title,
            phone: e.value,
         });
      } else {
         setInterestedGuest({
            ...interestedGuest,
            selectedEvent: e,
         });
      }
   };
   const submitSermon = async (e) => {
      e.preventDefault();

      if (interestedGuest.name === "") {
         enqueueSnackbar("Please enter a sermon title", {
            variant: "info",
         });
         return;
      }

      let notification = {
         title: interestedGuest.name,
         message: `New sermon by ${interestedGuest.email} is available. ${interestedGuest.age}`,
         image: interestedGuest.sex,
         targetUsers: ['General'],
      }
      try {
         await addDoc(collection(db, "sermons"), interestedGuest);
         enqueueSnackbar("Sermon added successfully", {
            variant: "success",
         });
         setInterestedGuest({
            name: "",
            email: "",
            date: "",
            phone: "",
            age: "",
            sex: "",
            portraitCover: "",
            selectedEvent: '',
         });

         submitNotification(notification);

         MySwal.fire({
            title: "Success!",
            text: "Sermon has been added",
            icon: "success",
            confirmButtonText: "OK",
         });
      } catch (err) {
         MySwal.fire({
            title: "Error!",
            text: err.message,
            icon: "error",
            confirmButtonText: "OK",
         });
      }
   };

   const submitNotification = async (notification) => {
      const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
      try {
         await addDoc(collection(db, "notifications"), {
            ...notification,
            createdAt: date || null,
            createdBy: interestedGuest.email,
            scheduledTime: new Date(),
         }).then(() => {
            console.log("Notification added...");
         });
         enqueueSnackbar("Notification sent!", {
            variant: "success",
         });

      } catch (err) {
         enqueueSnackbar(err.message, {
            variant: "error",
         });
         console.log(err);
      }
   };

   const proceedToPayment = async (e) => {
      e.preventDefault();
      if (interestedGuest.name === "") {
         enqueueSnackbar("Please enter a name", {
            variant: "info",
         });
         return;
      }
      if (interestedGuest.email === "") {
         enqueueSnackbar("Please enter an email", {
            variant: "info",
         });
         return;
      }
      //Validate email
      if (!validateEmail(interestedGuest.email)) {
         enqueueSnackbar("Please enter a valid email", {
            variant: "info",
         });
         return;
      }

      if (interestedGuest.phone === "") {
         enqueueSnackbar("Please enter a phone number", {
            variant: "info",
         });
         return;
      }
      if (interestedGuest.age === "") {
         enqueueSnackbar("Please select an age group", {
            variant: "info",
         });
         return;
      }

      console.log({ interestedGuest });
      console.log({ guests });

      let event = events.find(event => event.id === interestedGuest.eventId);
      let guestRegistrants = null;
      if (guests?.length > 0) {
         // Every guest registered via website
         guestRegistrants = guests.map(guest => {
            let person = {
               ...guest,
               registeredVia: "Website",
               isGuest: true,
            }
            return turnGuestToRegistrant({ guest: person, selectedEvent: event });
         });
      }
      console.log(guestRegistrants);

      let interestedGuestRegistrant = turnGuestToRegistrant({
         guest: interestedGuest,
         selectedEvent: event
      });
      interestedGuestRegistrant = {
         ...interestedGuestRegistrant,
         registeredVia: "Website",
         isRegisteringGuests: guestRegistrants?.length > 0 ? true : false,
      };
      console.log("==================================================================================");
      console.log(interestedGuestRegistrant);

      // TODO Add guests to interestedGuest object after the registration and payment

      // Calculate total price
      let { total: totalPrice, registrants: allRegistranst } = calculateTotalPrice({ interestedGuest: interestedGuestRegistrant, guestRegistrants });

      console.log("==================================================================================");
      console.log(totalPrice, allRegistranst);
      console.log("==================================================================================");

      setEventRegistrants(allRegistranst);
      setTotalPrice(totalPrice);
      setRegistraa(interestedGuestRegistrant);
      setSelectedFunction(event);

      // Proceed to payment
      navigate("/BookingSummaryTable", {
         state: {
            totalPrice,
            allRegistranst,
            success: true,
         },
      });

      return {
         totalPrice,
         allRegistranst,
         success: true,
      }
   }

   const handleAgeChange = (selected) => {
      if (selected.value) {
         setInterestedGuest({
            ...interestedGuest,
            age: selected.value,
         });
      } else {
         setInterestedGuest({
            ...interestedGuest,
            age: "",
         });
      }
   }
   const handleSexChange = (selected) => {
      if (selected.value) {
         setInterestedGuest({
            ...interestedGuest,
            sex: selected.value,
         });
      } else {
         setInterestedGuest({
            ...interestedGuest,
            sex: "",
         });
      }
   }


   function calculateTotalPrice({ interestedGuest, guestRegistrants }) {
      let total = 0;
      let registrants = [];
      if (interestedGuest) {
         total += Number(interestedGuest.price);
         registrants.push(interestedGuest)
      }
      if (guestRegistrants) {
         guestRegistrants.forEach(registrant => {
            total += Number(registrant.price);
            registrants.push(registrant)
         });
      }
      return { total, registrants };
   };


   return (
      <Form
         onSubmit={submitSermon}
         style={{
            width: "100%",
            padding: "5%",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",

         }}
      >
         <h2 className="header text-center" >Book Event</h2>
         <Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label style={{
                  color: "black"
               }}>Select Event</Form.Label>
               <SelectEvent
                  events={events}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label style={{
                  color: "black"
               }} >Full Name</Form.Label>
               <Form.Control
                  type="text"
                  defaultValue={interestedGuest.name}
                  name="name"
                  placeholder="name"
                  onChange={handleChange}
                  required
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label style={{
                  color: "black"
               }}>Email</Form.Label>
               <Form.Control
                  type="email"
                  name="email"
                  defaultValue={interestedGuest.email}
                  placeholder="email"
                  onChange={handleChange}
                  required
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
               <Form.Label style={{
                  color: '#000'
               }}>Contact</Form.Label>
               <Form.Control
                  type="tel"
                  name="phone"
                  defaultValue={interestedGuest.phone}
                  placeholder="phone"
                  onChange={handleChange}
                  required
               />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
               <Form.Label style={{
                  color: "black"
               }} >Age Group</Form.Label>
               <Select
                  options={ages}
                  name="age"
                  isSearchable={false}
                  placeholder="Select Age"
                  autoFocus={false}
                  onChange={handleAgeChange}
                  defaultValue={ages[0]}
               />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
               <Form.Label style={{
                  color: "black"
               }} >Sex</Form.Label>
               <Select
                  options={[
                     { value: "", label: "Select Sex" },
                     { value: "Male", label: "Male" },
                     { value: "Female", label: "Female" }
                  ]}
                  name="sex"
                  isSearchable={false}
                  placeholder="Select Age"
                  autoFocus={false}
                  onChange={handleSexChange}
               />
            </Form.Group>
         </Row>
         <Row
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            {/* <Col md="auto" lg style={{ marginTop: "5%" }}>
               <AddGuest
                  guest={interestedGuest}
                  setGuest={setInterestedGuest}
                  guests={guests}
                  setGuests={setGuests}
                  events={events}
                  selectedEvent={selectedEvent}
               />
            </Col> */}
            <Col md="auto" lg style={{ marginTop: "5%" }}>
               <Button
                  style={{
                     width: "100%",
                     height: "100%",
                     margin: "auto",
                     display: "block",
                     justifyContent: "center",
                  }}
                  disabled={(interestedGuest.sex && interestedGuest.age) ? false : true}
                  className="btn btn-primary"
                  variant="primary"
                  onClick={proceedToPayment}
               >
                  Proceed to Payment
               </Button>
            </Col>
         </Row>
      </Form >
   );
};

