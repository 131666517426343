import * as React from 'react';
import {
   Container,
   Button,
   Box,
   Typography,
   Modal,
   TextField,
   Checkbox,
   FormControlLabel,
} from '@mui/material';
import { Form, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { GoogleAutoComplete } from './GoogleAutoComplete';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";
import { useState } from 'react';
import { addDoc, collection, updateDoc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, deleteObject, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage, db } from '../configs/firebase';

import { formatLink } from '../helpers/Validators';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const style = {
   bgcolor: 'background.paper',
   borderRadius: '5px',
   boxShadow: 24,
   p: 4,
   overflowY: 'scroll',
   maxHeight: '90vh',

   /* Hide scrollbar for Chrome, Safari and Opera */
   '-ms-overflow-style': 'none',
   /* IE and Edge */
   scrollbarWidth: 'none',
   /* Firefox */
   '&::-webkit-scrollbar': {
      display: 'none'
   }
};
const MySwal = withReactContent(Swal);

export const AddEventModal = ({ setEvent, event, open, setOpen }) => {
   const { enqueueSnackbar } = useSnackbar();
   const coverPhotoRef = React.useRef(null);
   const [imageUrl, setImageUrl] = useState(null);
   let imageFile = null;

   const [adultsAllowed, setAdultsAllowed] = useState(false);
   const [childrenAllowed, setChildrenAllowed] = useState(false);
   const [contactPerson, setContactPerson] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [currencySymbol, setCurrencySymbol] = useState('R');
   const [currencyTicker, setCurrencyTicker] = useState('ZAR');
   const [description, setDescription] = useState('');
   const [endingTime, setEndingTime] = useState(new Date());
   const [startingTime, setStartingTime] = useState(new Date());
   const [externalLink, setExternalLink] = useState('');
   const [externalRegistrationButton, setExternalRegistrationButton] = useState('');
   const [externalRegistrationLink, setExternalRegistrationLink] = useState('');
   const [image, setImage] = useState('');
   const [isExternalRegistrationOnly, setIsExternalRegistrationOnly] = useState(false);
   const [isPaid, setIsPaid] = useState(false);
   const [isRegistrationActive, setIsRegistrationActive] = useState(false);
   const [maxParticipants, setMaxParticipants] = useState('');
   const [adultsPrice, setAdultsPrice] = useState('');
   const [childrenPrice, setChildrenPrice] = useState('');
   const [title, setTitle] = useState('');
   const [type, setType] = useState('');
   const [address, setAddress] = useState('');
   const [latitude, setLatitude] = useState('');
   const [longitude, setLongitude] = useState('');
   const [venueName, setVenueName] = useState('');
   const [imageUploadProgress, setImageUploadProgress] = useState(null);

   const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
   const isBigScreen = useMediaQuery({ minWidth: 1824 })
   const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
   const isPortrait = useMediaQuery({ orientation: 'portrait' })
   const isRetina = useMediaQuery({ minResolution: '2dppx' })

   const handleClose = () => setOpen(false);
   const handleAddressChange = (e) => {
      if (e.target.name === 'address') setAddress(e.target.value);
      if (e.target.name === 'latitude') setLatitude(e.target.value);
      if (e.target.name === 'longitude') setLongitude(e.target.value);
      if (e.target.name === 'venueName') setVenueName(e.target.value);
   }

   const isReadyToSubmitForm = () => {
      if (!title) {
         enqueueSnackbar("Title is required", {
            variant: "warning",
         });
         return false;
      }
      if (!description) {
         enqueueSnackbar("Description is required", {
            variant: "warning",
         });
         return false;
      }
      if (!address) {
         enqueueSnackbar("Address is required", {
            variant: "warning",
         });
         return false;
      }
      if (!latitude) {
         enqueueSnackbar("Latitude is required", {
            variant: "warning",
         });
         return false;
      }
      if (!longitude) {
         enqueueSnackbar("Longitude is required", {
            variant: "warning",
         });
         return false;
      }
      if (!venueName) {
         enqueueSnackbar("Venue name is required", {
            variant: "warning",
         });
         return false;
      }
      if (!contactPerson) {
         enqueueSnackbar("Contact person is required", {
            variant: "warning",
         });
         return false;
      }
      if (!email) {
         enqueueSnackbar("Email is required", {
            variant: "warning",
         });
         return false;
      }
      if (!phone) {
         enqueueSnackbar("Phone is required", {
            variant: "warning",
         });
         return false;
      }
      if (!startingTime) {
         enqueueSnackbar("Starting time is required", {
            variant: "warning",
         });
         return false;
      }
      if (!endingTime) {
         enqueueSnackbar("Ending time is required", {
            variant: "warning",
         });
         return false;
      }
      if (!maxParticipants) {
         enqueueSnackbar("Max participants is required", {
            variant: "warning",
         });
         return false;
      }
      if (!adultsPrice) {
         enqueueSnackbar("Adults price is required", {
            variant: "warning",
         });
         return false;
      }
      if (!childrenPrice) {
         enqueueSnackbar("Children price is required", {
            variant: "warning",
         });
         return false;
      }
      if (!type) {
         enqueueSnackbar("Type is required", {
            variant: "warning",
         });
         return false;
      }
      if (!adultsAllowed && !childrenAllowed) {
         enqueueSnackbar("Adults or children should be allowed", {
            variant: "warning",
         });
         return false;
      }
      if (isPaid && !currencySymbol) {
         enqueueSnackbar("Currency symbol is required", {
            variant: "warning",
         });
         return false;
      }
      if (isPaid && !currencyTicker) {
         enqueueSnackbar("Currency ticker is required", {
            variant: "warning",
         });
         return false;
      }
      if (isExternalRegistrationOnly && !externalLink) {
         setExternalLink(`${window.location.origin}/EventBooking`);
         console.log({ externalLink: `${window.location.origin}/EventBooking` });
      }
      if (isExternalRegistrationOnly && !externalRegistrationButton) {
         setExternalRegistrationButton("Register Now");
      }
      if (isExternalRegistrationOnly && !externalRegistrationLink) {
         setExternalRegistrationLink(`${window.location.origin}/EventBooking`);
         console.log({ externalRegistrationLink: `${window.location.origin}/EventBooking` });
      }
      if (!imageUrl) {
         enqueueSnackbar("Image is required", {
            variant: "warning",
         });
         return false;
      }
      return true;
   };

   const isValidDates = (startingTime, endingTime) => {
      const today = new Date();
      const startDate = new Date(startingTime);
      const endDate = new Date(endingTime);

      console.log({ today, startDate, endDate });

      if (startDate < today) {
         MySwal.fire({
            title: "Warning!",
            text: "Start date should be greater than today",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return false;
      }
      if (endDate < startDate) {
         MySwal.fire({
            title: "Warning!",
            text: "End date should be greater than start date",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return false;
      }
      return true;
   };

   const handleSubmit = async () => {
      setOpen(false);
      let event = {
         adultsAllowed,
         childrenAllowed,
         contactDetails: {
            contactPerson,
            email,
            phone,
         },
         currency: {
            symbol: 'R',
            ticker: 'ZAR',
         },
         image: imageUrl,
         description,
         endingTime: new Date(endingTime),
         startingTime: new Date(startingTime),
         externalLink: externalLink ? formatLink(externalLink) : `${window.location.origin}/EventBooking`,
         externalRegistrationButton,
         externalRegistrationLink: externalRegistrationLink ? formatLink(externalRegistrationLink) : `${window.location.origin}/EventBooking`,
         isExternalRegistrationOnly,
         isPaid,
         isRegistrationActive,
         maxParticipants: Number(maxParticipants),
         price: {
            adultsPrice,
            childrenPrice,
         },
         title,
         type,
         venue: {
            address,
            latitude,
            longitude,
            name: venueName,
         }
      };
      console.log({ event });
      if (!isValidDates(startingTime, endingTime)) return;
      if (!isReadyToSubmitForm()) return;

      try {
         const eventRef = collection(db, 'events');
         await addDoc(eventRef, event);
         enqueueSnackbar("Event added successfully", {
            variant: "success",
         });
         handleClose();
      } catch (error) {
         console.log(error);
         enqueueSnackbar(error.message, {
            variant: "error",
         });
         handleClose();
      } finally {
         handleClose();
      }
   };

   async function handleCoverPhotoUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a cover image",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 1024 * 1024 * 1) {
         enqueueSnackbar("Image file size should be less than 1MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `events/cover-images/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setImageUrl(downloadURL);

               enqueueSnackbar("Cover photo uploaded successfully", {
                  variant: "success",
               });
               setImageUploadProgress(null);
            });
         }
      );
   };

   return (
      <div>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
         >
            <Box sx={{ ...style, width: isDesktopOrLaptop ? '40%' : '100%' }}>
               <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography style={{ margin: "1%", color: 'gray' }} id="modal-modal-title" variant="h4" component="h4">
                     Create New Event✨
                  </Typography>
                  <IconButton
                     style={{
                        backgroundColor: 'red',
                        color: "white",
                        float: "right",
                        margin: "1%",
                     }}
                     aria-label="close"
                     size="small"
                     onClick={() => {
                        handleClose();
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               </div>
               <Form.Group style={{ margin: "1%" }}>
                  <Form.Control
                     type="text"
                     placeholder="Title"
                     id="title"
                     label="Title"
                     defaultValue={title}
                     onChange={(e) => setTitle(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="text"
                     placeholder="Event Type"
                     id="type"
                     label="Type"
                     value={type}
                     onChange={(e) => setType(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     placeholder="Description"
                     id="description"
                     label="Description"
                     onChange={(e) => setDescription(e.target.value)}
                     required
                     as="textarea"
                     name="description"
                     defaultValue={description}
                     rows={15}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Label style={{
                     color: "black"
                  }}>
                     Cover Photo in Landscape
                  </Form.Label>
                  {/* Upload Cover Image to Firebase Firestore */}
                  <input
                     required
                     ref={coverPhotoRef}
                     encType="multipart/form-data"
                     type="file"
                     name="coverImage"
                     id="coverImage"
                     accept="image/*"
                     style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid #000",
                        borderRadius: "5px",
                        padding: "10px",
                        color: "#000",
                        marginBottom: "1%",
                        fontSize: "16px",
                        outline: "none",
                        cursor: "pointer"
                     }}
                     onChange={e => {
                        imageFile = e.target.files[0];
                        handleCoverPhotoUpload(imageFile);
                     }}
                  />
               </Form.Group>
               <Form.Group style={{ margin: '1%' }} >
                  <Form.Label style={{ margin: '1%', color: "black", fontWeight: '700' }}>
                     VENUE DETAILS
                  </Form.Label>
                  <GoogleAutoComplete
                     address={address}
                     setAddress={setAddress}
                     latitude={latitude}
                     setLatitude={setLatitude}
                     longitude={longitude}
                     setLongitude={setLongitude}
                     venueName={venueName}
                     setVenueName={setVenueName}
                     handleAddressChange={handleAddressChange}
                  />
                  <Form.Control
                     id="venue"
                     type="text"
                     label="Venue Name"
                     placeholder='Venue Name'
                     name="venueName"
                     defaultValue={venueName}
                     onChange={(e) => handleAddressChange(e)}
                     style={{ marginBottom: "1%", marginTop: "1%" }}
                  />
                  <Form.Control
                     placeholder='Address'
                     id="address"
                     type="text"
                     label="Address"
                     name="address"
                     defaultValue={address}
                     onChange={(e) => handleAddressChange(e)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="text"
                     label="Latitude"
                     placeholder="Latitude"
                     id="latitude"
                     name="latitude"
                     defaultValue={latitude}
                     onChange={(e) => handleAddressChange(e)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="text"
                     label="Longitude"
                     placeholder="Longitude"
                     id="longitude"
                     name="longitude"
                     defaultValue={longitude}
                     onChange={(e) => handleAddressChange(e)}
                     style={{ marginBottom: "1%" }}
                  />
               </Form.Group>
               <Form.Group style={{ margin: '1%' }}>
                  <Form.Label style={{ margin: '1%', color: "black", fontWeight: '700' }}>
                     CONTACT DETAILS
                  </Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Contact Person"
                     id="contactPerson"
                     label="Contact Person"
                     defaultValue={contactPerson}
                     onChange={(e) => setContactPerson(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="email"
                     placeholder="Email"
                     id="email"
                     label="Email"
                     defaultValue={email}
                     onChange={(e) => setEmail(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="tel"
                     placeholder="Phone"
                     id="phone"
                     label="Phone"
                     defaultValue={phone}
                     onChange={(e) => setPhone(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
               </Form.Group>
               <Form.Group style={{ margin: '1%' }} >
                  <Form.Label style={{ margin: '1%', color: "black", fontWeight: '700' }}>
                     REGISTRATION DETAILS
                  </Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="External Link"
                     id="externalLink"
                     label="External Link"
                     defaultValue={externalLink}
                     onChange={(e) => {
                        setExternalLink(e.target.value);
                        setExternalRegistrationLink(e.target.value);
                     }}
                     style={{ marginBottom: "1%" }}
                  />
                  <Form.Control
                     type="text"
                     placeholder="External Registration Button Title"
                     id="externalRegistrationButton"
                     label="External Registration Button"
                     defaultValue={externalRegistrationButton}
                     onChange={(e) => setExternalRegistrationButton(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
               </Form.Group>
               {/* Start Time */}
               <Form.Group style={{ margin: '1%' }}>
                  <Form.Label style={{ marginTop: '2%', marginLeft: '1%', color: "black", fontWeight: '700', display: 'block' }}>
                     EVENT DURATION
                  </Form.Label>
                  <Form.Label style={{
                     color: "black"
                  }}>
                     Starting Time
                  </Form.Label>
                  <Form.Control
                     type="datetime-local"
                     id="startingTime"
                     value={startingTime}
                     onChange={(e) => setStartingTime(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
               </Form.Group>
               {/* End Time */}
               <Form.Group style={{ margin: '1%' }} >
                  <Form.Label style={{
                     color: "black"
                  }}>
                     Ending Time
                  </Form.Label>
                  <Form.Control
                     type="datetime-local"
                     id="endingTime"
                     value={endingTime}
                     onChange={(e) => setEndingTime(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
               </Form.Group>
               <Form.Group style={{ margin: '1%' }}>
                  <Form.Label style={{ marginTop: '2%', marginLeft: '1%', color: "black", fontWeight: '700', display: 'block' }}>
                     EVENT PRICING & REGISTRATION
                  </Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Max Participants"
                     id="maxParticipants"
                     label="Max Participants"
                     defaultValue={maxParticipants}
                     onChange={(e) => setMaxParticipants(e.target.value)}
                     style={{ marginBottom: "1%" }}
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={isPaid}
                           onChange={(e) => setIsPaid(e.target.checked)}
                        />
                     }
                     label="Paid"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={adultsAllowed}
                           onChange={(e) => setAdultsAllowed(e.target.checked)}
                        />
                     }
                     label="Adults Allowed"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={childrenAllowed}
                           onChange={(e) => setChildrenAllowed(e.target.checked)}
                        />
                     }
                     label="Children Allowed"
                  />
                  {
                     isPaid ? (
                        adultsAllowed ? (
                           <Form.Control
                              type="text"
                              placeholder="Adults Price"
                              id="adultsPrice"
                              label="Adults Price"
                              value={adultsPrice}
                              onChange={(e) => setAdultsPrice(e.target.value)}
                              style={{ marginBottom: "1%" }}
                           />
                        ) : null
                     ) : null
                  }
                  {
                     isPaid ? (
                        childrenAllowed ? (
                           <Form.Control
                              type="text"
                              placeholder="Children Price"
                              id="childrenPrice"
                              label="Children Price"
                              value={childrenPrice}
                              onChange={(e) => setChildrenPrice(e.target.value)}
                              style={{ marginBottom: "1%" }}
                           />
                        ) : null
                     ) : null
                  }
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={isExternalRegistrationOnly}
                           onChange={(e) => setIsExternalRegistrationOnly(e.target.checked)}
                        />
                     }
                     label="External Registration Only"
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={isRegistrationActive}
                           onChange={(e) => setIsRegistrationActive(e.target.checked)}
                        />
                     }
                     label="Registration Active"
                  />
               </Form.Group>
               {
                  imageUploadProgress ? (<ProgressBar now={imageUploadProgress} label={`${parseInt(imageUploadProgress, 10)}%`} />) : null
               }
               <Button variant="contained" style={{ marginBottom: "2%", float: 'right', width: '50%' }} onClick={handleSubmit}>
                  Submit
               </Button>
            </Box>
         </Modal>
      </div >
   );
};