import { useState, useEffect, useRef, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import SelectSermonAudio from "../../components/SelectSermonAudio.jsx";
import { SelectSermon } from "../../components/SelectSermon";

import fetch from 'node-fetch';
import _ from 'lodash';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Timestamp, collection, addDoc, onSnapshot, updateDoc, doc, serverTimestamp } from "firebase/firestore";
import { db, auth, storage } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { useSnackbar } from "notistack";

import { ChurchContext } from "../../contexts/ChurchContext.js";


export const AddSermon = () => {

  const { enqueueSnackbar } = useSnackbar();
  const MySwal = withReactContent(Swal);

  // const { church } = useContext(ChurchContext);
  const [sermons, setSermons] = useState([]);

  const [useYoutubeCover, setUseYoutubeCover] = useState(false);
  let coverPhotoFile = null;
  let portraitCoverFile = null;
  let coverPhotoRef = useRef(null);
  let portraitCoverRef = useRef(null);
  const [coverPhotoUploadProgress, setCoverPhotoUploadProgress] = useState(null);
  const [portraitCoverUploadProgress, setPortraitCoverUploadProgress] = useState(null);

  const [church, setChurch] = useState(null);
  const [gotChurch, setGotChurch] = useState(false);

  async function fetchVideos(apiKey, channelId, maxResults) {
    getSermonsList(apiKey, channelId, maxResults)
      .then(async (sermons) => {
        let cleanedSermons = await Promise.all(sermons.map(async (sermon) => {
          let snippet = sermon.snippet;
          let videoId = sermon?.id?.videoId ? sermon.id.videoId : false;
          let description = await getSermonDescriptionById(apiKey, videoId)
            .then(desc => {
              return desc;
            })
            .catch(err => {
              console.log(err);
              enqueueSnackbar("Error getting sermon description from YouTube", { variant: "error" });
            });
          snippet = {
            description: description,
            publishedAt: snippet.publishedAt,
            thumbnail: snippet.thumbnails.medium.url,
            title: snippet.title,
          }
          if (videoId) {
            return {
              value: sermon.id.videoId,
              label: (_.startCase(unescapeHtml(sermon.snippet.title))),
              snippet
            };
          }
        })
        );
        // Cache cleaned sermons to firebase
        let definedVideos = [...cleanedSermons.filter(sermon => sermon !== undefined)];
        console.log("definedVideos Sermons:...............................................")
        console.log(definedVideos);
        try {
          updateDoc(doc(db, "church", church.id), {
            cachedYoutubeVideos: {
              videos: [...definedVideos],
              latestCacheDate: serverTimestamp(),
            },
          });
        } catch (err) {
          console.log(err);
          enqueueSnackbar("Error caching sermons from YouTube", { variant: "error" });
        }
        definedVideos.unshift({ value: "", label: "No Sermon Video Selected" })
        setSermons(definedVideos);
      })
      .catch(err => {
        enqueueSnackbar("Error getting sermons from YouTube", { variant: "error" });
        console.log(err);
      });
  };

  // Church  Data from db
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "church"), (snapshot) => {
      const churchData = snapshot.docs[0].data();
      churchData.id = snapshot.docs[0].id;
      setChurch(churchData);
      setGotChurch(true);
    });
    return () => unsubscribe();
  }, []);

  //get sermons from youtube
  useEffect(() => {

    // Check if the cached videos are up to date
    if (gotChurch) {
      if (!church?.cachedYoutubeVideos?.latestCacheDate) {

        // Get the latest Cached YouTube Shorts data from db
        const today = new Date();
        const todayDate = today.getDate();
        const todayMonth = today.getMonth();
        const todayYear = today.getFullYear();

        const latestCacheDate = new Date(church?.cachedYoutubeVideos?.latestCacheDate);
        const latestCacheDateDate = latestCacheDate.getDate();
        const latestCacheDateMonth = latestCacheDate.getMonth();
        const latestCacheDateYear = latestCacheDate.getFullYear();
        if (todayDate === latestCacheDateDate && todayMonth === latestCacheDateMonth && todayYear === latestCacheDateYear) {
          setSermons(church?.cachedYoutubeVideos?.videos);
        } else {
          fetchVideos(church?.youtube?.API_KEY, church?.youtube?.CHANNEL_ID, 100);
        }
      } else {
        fetchVideos(church?.youtube?.API_KEY, church?.youtube?.CHANNEL_ID, 100);
      }
    }
  }, [gotChurch]);


  // const apiKey = ""; //TODO: Add your YouTube API key from .env here
  // const channelId = ""; // TODO: Add your YouTube channel ID from db here
  // const maxResults = 100;

  const [sermon, setSermon] = useState({
    title: "",
    speaker: "",
    date: "",
    url: null,
    description: "",
    coverPhoto: "",
    portraitCover: "",
    sermonAudioUrl: null,
  });

  const isReadyToSubmitForm = () => {
    return (
      sermon.title !== "" &&
      sermon.speaker !== "" &&
      sermon.date !== "" &&
      sermon.url !== "" &&
      sermon.description !== "" &&
      sermon.coverPhoto !== ""
    );
  };

  const handleChange = (e) => {
    console.log(e.target.name);
    if (e.target && e.target.name === "date") {
      setSermon({
        ...sermon,
        [e.target.name]: Timestamp.fromDate(new Date(e.target.value)) ? Timestamp.fromDate(new Date(e.target.value)) : serverTimestamp(),
      });
    } else if (e.target && e.target.name === "coverPhoto") {
      coverPhotoFile = e.target.files[0];
      console.log(coverPhotoFile);

      //if the file size is greater than 1MB, show error
      if (coverPhotoFile.size > 1000000) {
        enqueueSnackbar("Cover image size must be less than 1MB", {
          variant: "error",
        });
        return;
      }

      if (coverPhotoFile) {
        handleCoverPhotoUpload(coverPhotoFile);
      }

    } else if (e.target && e.target.name === "portraitCover") {
      portraitCoverFile = e.target.files[0];
      console.log(portraitCoverFile);

      //if the file size is greater than 1MB, show error
      console.log({ size: portraitCoverFile.size })
      if (portraitCoverFile.size > 1000000) {
        enqueueSnackbar("Cover image size must be less than 1MB", {
          variant: "error",
        });
        return;
      }

      if (portraitCoverFile) {
        handlePortraitCoverUpload(portraitCoverFile);
      }

    } else if (e.target && e.target.name) {
      setSermon({
        ...sermon,
        [e.target.name]: e.target.value,
      });
    } else if (e?.value) {
      setSermon({
        ...sermon,
        title: e.snippet.title,
        url: e.value,
      });
    } else {
      setSermon({
        ...sermon,
        sermonAudioUrl: e,
      });
    }
    console.log(sermon);
  };
  const submitSermon = async (e) => {
    e.preventDefault();

    if (sermon.title === "") {
      enqueueSnackbar("Please enter a sermon title", {
        variant: "info",
      });
      return;
    }

    if (!coverPhotoFile && sermon.coverPhoto === "") {
      enqueueSnackbar("Please upload a cover photo", {
        variant: "info",
      });
      return;
    }

    if (!portraitCoverFile && sermon.portraitCover === "") {
      enqueueSnackbar("Please upload a portrait cover photo", {
        variant: "info",
      });
      return;
    }

    if (sermon.coverPhoto === "" && coverPhotoFile) {
      handleCoverPhotoUpload(coverPhotoFile);
    }

    if (sermon.portraitCover === "" && portraitCoverFile) {
      handlePortraitCoverUpload(portraitCoverFile);
    }

    let notification = {
      title: sermon.title,
      message: `New sermon by ${sermon.speaker} is available. ${sermon.description}`,
      image: sermon.coverPhoto,
      targetUsers: ['General'],
    }
    try {
      await addDoc(collection(db, "sermons"), sermon);
      enqueueSnackbar("Sermon added successfully", {
        variant: "success",
      });
      setSermon({
        title: "",
        speaker: "",
        date: "",
        url: "",
        description: "",
        coverPhoto: "",
        portraitCover: "",
        sermonAudioUrl: '',
      });

      submitNotification(notification);

      MySwal.fire({
        title: "Success!",
        text: "Sermon has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const submitNotification = async (notification) => {
    const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
    try {
      await addDoc(collection(db, "notifications"), {
        ...notification,
        createdAt: date || null,
        createdBy: auth.currentUser.uid,
        scheduledTime: new Date(),
      }).then(() => {
        console.log("Notification added...");
      });
      enqueueSnackbar("Notification sent!", {
        variant: "success",
      });

    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
      console.log(err);
    }
  };

  async function handleCoverPhotoUpload(file) {
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `sermons/auto-uploaded/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setCoverPhotoUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setSermon({
            ...sermon,
            coverPhoto: downloadURL,
          });

          enqueueSnackbar("Cover photo uploaded successfully", {
            variant: "success",
          });
          setCoverPhotoUploadProgress(null);
        });
      }
    );
  };

  async function handlePortraitCoverUpload(file) {
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `sermons/auto-uploaded/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPortraitCoverUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setSermon({
            ...sermon,
            portraitCover: downloadURL,
          });

          enqueueSnackbar("Portrait cover photo uploaded successfully", {
            variant: "success",
          });

          setPortraitCoverUploadProgress(null);
        });
      }
    );
  };

  return (
    <Form
      onSubmit={submitSermon}
      style={{
        width: "80%",
        padding: "5%",
        margin: "auto",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",

      }}
    >
      <h2 className="header text-center" >Add Sermon</h2>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }} >Sermon Title</Form.Label>
          <Form.Control
            type="text"
            defaultValue={sermon.title}
            name="title"
            placeholder="title"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }}>Speaker</Form.Label>
          <Form.Control
            type="text"
            name="speaker"
            defaultValue={sermon.speaker}
            placeholder="speaker"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: '#000'
          }}>Sermon Youtube Id</Form.Label>
          <Form.Control
            type="text"
            name="url"
            defaultValue={sermon.url}
            placeholder="ID"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          {/* Sermon Video */}
          <Form.Label style={{
            color: "black"
          }}>Sermon Video</Form.Label>
          <SelectSermon handleChange={handleChange} setSermon={setSermon} sermons={sermons} sermon={sermon} />
        </Form.Group>
        {
          church?.audioSermons ? (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{
                color: "black"
              }}>Sermon Audio</Form.Label>
              <SelectSermonAudio
                setSermon={setSermon}
                sermon={sermon}

              />
            </Form.Group>
          ) : null
        }
        {
          coverPhotoUploadProgress ? (<ProgressBar now={coverPhotoUploadProgress} label={`${parseInt(coverPhotoUploadProgress, 10)}%`} />) : null
        }
        {
          church?.audioSermons?.rssFeed && (
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{
                color: "black"
              }}>Sermon Audio</Form.Label>
              <SelectSermonAudio
                sermon={sermon}
                setSermon={setSermon}
              />
            </Form.Group>
          )
        }
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }}>Sermon Cover Photo in Landscape</Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          {
            sermon.coverPhoto ? (
              <div style={{
                display: "flex",
                flexDirection: "column",
              }}>
                <img src={sermon.coverPhoto} alt="cover" style={{
                  height: "5%",
                  marginBottom: "1%",
                  marginTop: "2%",
                  borderRadius: "5px",
                  width: "20%"
                }} />
                <Button variant="danger" onClick={() => {
                  setSermon({
                    ...sermon,
                    coverPhoto: null
                  })
                }
                }
                  style={{
                    width: "20%",
                  }}
                >
                  Remove
                </Button>
              </div>
            ) : (
              <input
                required
                ref={coverPhotoRef}
                encType="multipart/form-data"
                type="file"
                name="coverPhoto"
                accept="image/*"
                onChange={(e) => handleChange(e)}
                style={{
                  height: "50px",
                  width: "100%",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  padding: "10px",
                  color: "#000",
                  fontSize: "16px",
                  outline: "none",
                  cursor: "pointer"
                }}
              />
            )
          }
        </Form.Group>
        {
          portraitCoverUploadProgress ? (<ProgressBar now={portraitCoverUploadProgress} label={`${parseInt(portraitCoverUploadProgress, 10)}%`} />) : null
        }
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }}>Sermon Cover Photo in Portrait</Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          {
            sermon.portraitCover ? (
              <div style={{
                display: "flex",
                flexDirection: "column",
              }}>
                <img src={sermon.portraitCover} alt="cover" style={{
                  height: "5%",
                  marginBottom: "1%",
                  marginTop: "2%",
                  borderRadius: "5px",
                  width: "20%"
                }} />
                <Button variant="danger"
                  onClick={() => {
                    setSermon({
                      ...sermon,
                      portraitCover: null
                    })
                  }
                  }
                  style={{
                    width: "20%",
                  }}
                >
                  Remove
                </Button>
              </div>
            ) : (
              <input
                ref={portraitCoverRef}
                encType="multipart/form-data"
                type="file"
                name="portraitCover"
                accept="image/*"
                onChange={(e) => handleChange(e)}
                style={{
                  height: "50px",
                  width: "100%",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  padding: "10px",
                  color: "#000",
                  fontSize: "16px",
                  outline: "none",
                  cursor: "pointer"
                }}
              />
            )
          }
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{
            color: "black"
          }} >Date</Form.Label>
          <Form.Control
            required
            type="datetime-local"
            name="date"
            defaultValue={sermon.date}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{
            color: "black"
          }} >Sermon Description</Form.Label>
          <Form.Control
            required
            as="textarea"
            name="description"
            defaultValue={sermon.description}
            rows={15}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          style={{
            width: "97%",
            height: "25%",
            margin: "auto",
            fontSize: "1.2rem"
          }}
          disabled={!isReadyToSubmitForm()}
          variant="primary"
          type="submit"
          className="btn-primary"
        >
          Submit
        </Button>
      </Row>
    </Form >
  );
};

function unescapeHtml(unsafe) {
  return unsafe
    .replace(/&#39;/g, "\'")
    .replace(/&quot;/g, '\"')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&rsquo;/g, '\'')
    .replace(/&ldquo;/g, '\"')
    .replace(/&rdquo;/g, '\"')
    .replace(/&mdash;/g, '-')
    .replace(/&hellip;/g, '...')
    .replace(/&eacute;/g, 'e')
    .replace(/&ndash;/g, '-')
    .replace(/&uuml;/g, 'u')
    .replace(/&auml;/g, 'a')
    .replace(/&ouml;/g, 'o')
    .replace(/&Ouml;/g, 'O')
    .replace(/&Auml;/g, 'A')
    .replace(/&Uuml;/g, 'U')
    .replace(/&szlig;/g, 'ss')
    .replace(/&iexcl;/g, 'i')
    .replace(/&iquest;/g, 'i')
    .replace(/&i;/g, 'i')
    .replace(/&iacute;/g, 'i')
    .replace(/&ntilde;/g, 'n')
}

// Video Sermons
async function getSermonsList(apiKey, channelId, maxResults) {
  // const uri = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=UCyA-S_Uqz2Q-Bp5-TK8IAZw&part=snippet,id&order=date&maxResults=100`;
  const uri = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=${maxResults}`;
  const response = await fetch(uri);
  const { items } = await response.json();
  return items;
};

// get sermon description by id
async function getSermonDescriptionById(apiKey, id) {
  const uri = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${id}&key=${apiKey}`;
  const response = await fetch(uri);
  const { items } = await response.json();
  // console.log(items);
  return items[0]?.snippet?.description;
}

// NEW - AIzaSyDXWUJZzJz45lbcT6p5tDDV7MSV33nTzQY
// OLD - AIzaSyBdcYSdUgc_8ZtcKMba7Sj9mJJ5hKWB1YU
// COAH - AIzaSyC2szErq8vxq_au0XAJVIh8IcK2RIrHUCk