import { db } from "../configs/firebase";
import { doc, updateDoc, collection, getDoc, serverTimestamp, addDoc, setDoc, arrayUnion, arrayRemove } from "firebase/firestore";

// Id
import uuid from "react-uuid";

// Get the current number of registrants
async function getRegistrantsCount(selectedEvent) {
   const eventRef = doc(db, "events", selectedEvent?.id);
   const eventDoc = await getDoc(eventRef);
   const event = eventDoc.data();
   return event?.registrants?.length ? event?.registrants?.length : 0;
}

// Generate Ticaket Number
async function generateTicketNumber({ church, selectedEvent, registrantName }) {
   const currentNumberofRegistrants = await getRegistrantsCount(selectedEvent);
   const registrantAccronym = registrantName.split(' ').map((word) => word[0]).join('').toUpperCase();
   const eventAccronym = selectedEvent?.title?.split(' ').map((word) => word[0]).join('').toUpperCase();
   const churchAccronym = church?.name?.split(" ").map((word) => word[0]).join("").toUpperCase();
   const ticketNumber = `${churchAccronym}-${eventAccronym}-${registrantAccronym}-${String(currentNumberofRegistrants + 1).padStart(4, "0")}`;
   return ticketNumber;
}

// Convert user to registrant
function turnUserToRegistrant({ currentUser, selectedEvent }) {
   let firstName;
   let lastName;

   if (currentUser?.name && currentUser.surname === undefined) {
      const nameArray = currentUser.name.split(" ");
      firstName = nameArray[0];
      lastName = nameArray[1];
   } else {
      firstName = currentUser?.name || "Anonymous";
      lastName = currentUser?.surname || "";
   }

   return {
      name: `${firstName} ${lastName}`,
      phone: currentUser?.phone,
      email: currentUser?.email,
      sex: null,
      age: 'Adult',
      price: selectedEvent?.price?.adultsPrice || 0,
      userId: currentUser?.id,
      registrationId: uuid(),
      event: {
         id: selectedEvent?.id,
         title: selectedEvent?.title,
         image: selectedEvent?.image,
         startingTime: selectedEvent?.startingTime,
         endingTime: selectedEvent?.endingTime,
      },
      isPaid: false,
      isAttended: false,
      isCancelled: false,
      isRegistered: true,
      isVerified: false,
      isGuest: false,
   };
}

// Convert guest to registrant
function turnGuestToRegistrant({ guest, selectedEvent }) {
   return {
      name: guest?.name,
      phone: guest?.phone,
      email: guest?.email,
      sex: guest?.sex,
      age: guest?.age,
      price: guest?.age?.toLowerCase() === "adult" ? (selectedEvent?.price?.adultsPrice || 0) : (selectedEvent?.price?.childrenPrice || 0),
      userId: null,
      registrationId: uuid(),
      event: {
         id: selectedEvent?.id,
         title: selectedEvent?.title,
         image: selectedEvent?.image,
         startingTime: selectedEvent?.startingTime,
         endingTime: selectedEvent?.endingTime,
      },
      isPaid: false,
      isAttended: false,
      isCancelled: false,
      isRegistered: true,
      isVerified: false,
      isGuest: true,
   };
}

// Update event with new registrant for a paid event
async function updatePaidEventWithNewRegistrant({
   selectedEvent,
   registrant,
   interestedGuest,
   church,
   payment
}) {
   console.log("=================================CREATING TICKET NUMBER========================================")
   const ticketNumber = await generateTicketNumber({ selectedEvent, registrantName: registrant?.name, church });

   //Add ticket number to registrant
   registrant.ticketNumber = ticketNumber;
   registrant.date = new Date();
   console.log({ ticketNumber })

   console.log("=================================ADDING TRANSACTION TO DB========================================")


   // Add transaction to currentUser's Transactions
   let newTransaction;
   let transactionId = uuid();

   newTransaction = {
      id: transactionId,
      event: {
         id: selectedEvent?.id,
         title: selectedEvent?.title,
         image: selectedEvent?.image,
         startingTime: selectedEvent?.startingTime,
         endingTime: selectedEvent?.endingTime,
         isPaid: true,
      },
      date: new Date(),
      userId: null,
      registeredBy: {
         name: interestedGuest?.name,
         phone: interestedGuest?.phone,
         email: interestedGuest?.email,
      },
      payment: payment,
   };
   const transactionRef = doc(db, "transactions", newTransaction.id);
   let transactionCreated = await setDoc(transactionRef, newTransaction);
   console.log({ transactionCreated })

   console.log("=================================ADDING REGISTRANT TO EVENT========================================")
   const eventRef = doc(db, "events", selectedEvent?.id);
   let newRegistrant = {
      ...registrant,
      isPaid: true,
      transactionId: transactionId,
      payment: payment,
      registeredBy: {
         name: interestedGuest?.name,
         phone: interestedGuest?.phone,
         email: interestedGuest?.email,
      }
   };

   let addedRegistrant = await updateDoc(eventRef, {
      registrants: arrayUnion(newRegistrant),
   });
   console.log({ addedRegistrant })

   console.log("=================================ADDING REGISTRANT TO EVENT-REGISTRANTS COLLECTION========================================")

   // Add registrant to event-registrants collection
   const eventRegistrantsRef = collection(db, "event-registrants");
   let addedEventRegistrant = await addDoc(eventRegistrantsRef, {
      ...registrant,
      isPaid: true,
      transactionId
   });

   console.log({ addedEventRegistrant })
   return {
      success: true,
      message: "You have successfully registered for this event",
      ticketNumber: ticketNumber,
      transactionId,
   };
}

// Update event with new registrant for a free event
async function updateFreeEventWithNewRegistrant({ selectedEvent, registrant, church, currentUser }) {
   const ticketNumber = await generateTicketNumber({ church, selectedEvent, registrantName: registrant?.name });

   //Add ticket number to registrant
   registrant.ticketNumber = ticketNumber;
   registrant.date = new Date();
   let reg = null;

   if (registrant?.isGuest && registrant?.ticketNumber) {
      // Update event-registrants collection with new registrant
      console.log("====================================================================================================\n")
      console.log("Guest Registration\n")
      console.log("====================================================================================================\n")
      // Add event to currentUser's Registred Events
      if (!currentUser?.registeredEvents?.some(event => event?.id === selectedEvent?.id)) {
         let { success: registrationIsPassed, message } = await updateCurrentUserRegisteredEvents({ selectedEvent, currentUser });
         console.log("====================================================================================================\n")
         console.log({ RegisteringEventToUser: registrationIsPassed })
         console.log({ registrationIsPassed, message })
         console.log("====================================================================================================\n")
      }
      // Update event-registrants collection currentUser's doc with new registrant
      const { success, registeredPerson: person } = await updateCurrentUserEventRegistrantsDoc({ selectedEvent, registeredPerson: registrant, currentUser });
      reg = person;
      console.log("====================================================================================================\n")
      console.log({ updateCurrentUserEventRegistrantsDoc: success, person })
      console.log("====================================================================================================\n")

      if (success) {
         const { registeredPerson: reg3, message: msg } = await updateEventWithNewRegistrant({ selectedEvent, registeredPerson: reg });
         reg = reg3;
         console.log("====================================================================================================\n")
         console.log({ reg3: reg3, msg3: msg })
         console.log("====================================================================================================\n")
      } else {
         return {
            success: false,
            message: "An error occured while registering for this event",
            registeredPerson: null,
         }
      }

      return {
         success: true,
         message: "You have successfully registered for this event",
         registeredPerson: reg,
      }

   } else if (!registrant?.isGuest && registrant?.ticketNumber && registrant?.userId) {
      console.log("userID: ", registrant?.userId)
      console.log("====================================================================================================")
      // Update event-registrants collection currentUser's doc with new registrant
      console.log({ reg })
      const { success, registeredPerson: person } = await updateCurrentUserEventRegistrantsDoc({ selectedEvent, registeredPerson: registrant, currentUser });
      console.log({ reg2: person })
      reg = person;
      console.log("====================================================================================================")
      if (success) {
         console.log({ success })
         // Add event to currentUser's Registred Events
         let { success: registrationIsPassed, message } = await updateCurrentUserRegisteredEvents({ selectedEvent, currentUser });
         console.log({ registrationIsPassed, message })
         const { registeredPerson: reg3, message: msg } = await updateEventWithNewRegistrant({ selectedEvent, registeredPerson: reg });
         reg = reg3;
         console.log({ reg3: reg3, msg3: msg })
         console.log("====================================================================================================")

      } else {
         return {
            success: false,
            message: "An error occured while registering for this event",
            registeredPerson: null,
         }
      }

      return {
         success: true,
         message: "You have successfully registered for this event",
         registeredPerson: reg,
      }

   }
}

// Update event-registrants collection with new registrant
async function updateEventRegistrantsCollection({ registrant }) {
   try {
      const addedRegistrantId = await addDoc(collection(db, "event-registrants"), registrant);
      console.log("====================================================================================================")
      console.log("updateEventRegistrantsCollection addedRegistrantId: ", addedRegistrantId)
      return {
         success: true,
         message: "Registration successful",
         registeredPerson: {
            ...registrant,
            eventRegistrantId: addedRegistrantId.id,
         },
      }
   } catch (error) {
      console.log(error);
      return {
         success: false,
         message: error.message,
         registeredPerson: null,
      };
   }
}

// Update Event with new registeredPerson
async function updateEventWithNewRegistrant({ selectedEvent, registeredPerson }) {
   console.log("====================================================================================================")
   console.log("updateEventWithNewRegistrant registeredPerson: ", registeredPerson)
   try {
      if (selectedEvent?.registrants) {
         await updateDoc(doc(db, "events", selectedEvent?.id), {
            registrants: [...selectedEvent?.registrants,
            {
               id: registeredPerson?.isGuest ? registeredPerson?.eventRegistrantId : registeredPerson?.userId,
               name: registeredPerson?.name,
               age: registeredPerson?.age,
               email: registeredPerson?.email,
            }
            ],
         });
      } else {
         await updateDoc(doc(db, "events", selectedEvent?.id), {
            registrants: [
               {
                  id: registeredPerson?.isGuest ? registeredPerson?.eventRegistrantId : registeredPerson?.userId,
                  name: registeredPerson?.name,
                  age: registeredPerson?.age,
                  email: registeredPerson?.email,
               }
            ],
         });
      }

      return {
         success: true,
         message: "Registration successful",
         registeredPerson: registeredPerson,
      }
   } catch (error) {
      return {
         success: false,
         message: error.message,
         registeredPerson: null,
      };
   }
}

// Update currentUser's registeredEvents with new event
async function updateCurrentUserRegisteredEvents({ selectedEvent, currentUser }) {

   let registeredEventsIds = currentUser?.registeredEvents ? currentUser?.registeredEvents.map(event => event?.id) : [];
   if (registeredEventsIds.includes(selectedEvent?.id)) {
      return {
         success: false,
         message: "You have already registered for this event",
      };
   } else {
      try {
         const userRef = doc(db, "users", currentUser?.id);
         const userDoc = await getDoc(userRef);
         const user = userDoc.data();
         let eventToRegister = {
            id: selectedEvent?.id,
            title: selectedEvent?.title,
            image: selectedEvent?.image,
            startingTime: selectedEvent?.startingTime,
            endingTime: selectedEvent?.endingTime,
         }

         if (!user?.registeredEvents) {
            await updateDoc(userRef, {
               registeredEvents: [eventToRegister],
            });
            return {
               success: true,
               message: "You have successfully registered for this event",
            };
         } else {
            let newRegisteredEvents = [...user?.registeredEvents, eventToRegister];
            await updateDoc(userRef, {
               registeredEvents: newRegisteredEvents,
            });
            return {
               success: true,
               message: "You have successfully registered for this event",
            };
         }
      } catch (error) {
         console.log(error);
         return {
            success: false,
            message: "An error occured while registering for this event: " + error.message,
         };
      }
   }
}

// Update currentUser's event-registrants collection doc with new registrant
async function updateCurrentUserEventRegistrantsDoc({ selectedEvent, currentUser, registeredPerson }) {
   const currentUserEventRegistrantsDocRef = doc(db, "event-registrants", currentUser?.id);
   const currentUserEventRegistrantsDoc = await getDoc(currentUserEventRegistrantsDocRef);

   if (registeredPerson?.isGuest) {
      let eventRegistrantsRef = collection(db, "event-registrants");
      // Add a new document with a generated id.
      try {
         const newDocRef = await addDoc(eventRegistrantsRef, registeredPerson);
         console.log("New document created with id: ", newDocRef.id);
         registeredPerson.eventRegistrantId = newDocRef.id;
         let selectedEventDetails;
         if (currentUserEventRegistrantsDoc.exists()) {
            // get selectedEvent details from currentUser event-registrants doc
            selectedEventDetails = currentUserEventRegistrantsDoc.data()[selectedEvent?.id];
            if (selectedEventDetails && registeredPerson?.isGuest) {
               // Update selectedEvent details in currentUser event-registrants doc
               try {
                  if (selectedEventDetails?.guestList) {
                     await updateDoc(currentUserEventRegistrantsDocRef, {
                        [selectedEvent?.id]: {
                           ...selectedEventDetails,
                           guestList: [...selectedEventDetails?.guestList, registeredPerson],
                        },
                     });
                  } else {
                     await updateDoc(currentUserEventRegistrantsDocRef, {
                        [selectedEvent?.id]: {
                           ...selectedEventDetails,
                           guestList: [registeredPerson],
                        },
                     });
                  }
               } catch (error) {
                  console.log(error);
                  return {
                     success: false,
                     message: error.message,
                     registeredPerson: registeredPerson,
                  };
               }
            } else {
               // Add selectedEvent details to currentUser event-registrants doc
               try {
                  await updateDoc(currentUserEventRegistrantsDocRef, {
                     [selectedEvent?.id]: {
                        registeredPerson,
                        guestList: [],
                        title: selectedEvent?.title,
                        image: selectedEvent?.image,
                        startingTime: selectedEvent?.startingTime,
                        endingTime: selectedEvent?.endingTime,
                     },
                  });
               } catch (error) {
                  console.log(error);
                  return {
                     success: false,
                     message: error.message,
                     registeredPerson: registeredPerson,
                  };
               }
            }
         } else {
            // Add currentUser event-registrants doc
            try {
               await setDoc(currentUserEventRegistrantsDocRef, {
                  [selectedEvent?.id]: {
                     registeredPerson,
                     guestList: [],
                     title: selectedEvent?.title,
                     image: selectedEvent?.image,
                     startingTime: selectedEvent?.startingTime,
                     endingTime: selectedEvent?.endingTime,
                  },
               });
            } catch (error) {
               console.log(error);
               return {
                  success: false,
                  message: error.message,
                  registeredPerson: registeredPerson,
               };
            }

            try {
               await updateDoc(currentUserEventRegistrantsDocRef, {
                  [selectedEvent?.id]: {
                     ...selectedEventDetails,
                     guestList: [...selectedEventDetails?.guestList, registeredPerson],
                  },
               });
            } catch (error) {
               console.log(error);
               return {
                  success: false,
                  message: error.message,
                  registeredPerson: registeredPerson,
               };
            }
         }
      } catch (error) {
         console.log(error);
         return {
            success: false,
            message: error.message,
            registeredPerson: null,
         };
      }
   }
   return {
      success: true,
      message: "Registration successful",
      registeredPerson: registeredPerson,
   };
}

// Compile registrants for a paid event and proceed to payment gateway
function compileRegistrantsForPaidEvent({
   selectedEvent,
   currentUser,
   guests,
   isUserBooked
}) {
   let newRegistrants = [];

   if (!isUserBooked) {
      let userRegistrant = turnUserToRegistrant({ currentUser, selectedEvent });
      userRegistrant.isPaid = true;
      newRegistrants = [...newRegistrants, userRegistrant];
   }

   if (guests?.length > 0) {
      let guestRegistrants = guests.map((guest) => {
         let guestRegistrant = turnGuestToRegistrant({ guest, selectedEvent });
         guestRegistrant = { ...guestRegistrant, registeredBy: currentUser?.id };
         guestRegistrant.isPaid = true;
         return guestRegistrant;
      });
      newRegistrants = [...newRegistrants, ...guestRegistrants];
   }

   return newRegistrants;
}

// Compile registrants for a free event
function compileRegistrantsForFreeEvent({
   selectedEvent,
   currentUser,
   guests,
   isUserBooked
}) {
   let newRegistrants = [];

   if (!isUserBooked) {
      let userRegistrant = turnUserToRegistrant({ currentUser, selectedEvent });
      userRegistrant.isFree = true;
      newRegistrants = [...newRegistrants, userRegistrant];
   }

   if (guests?.length > 0) {
      let guestRegistrants = guests.map((guest) => {
         let guestRegistrant = turnGuestToRegistrant({ guest, selectedEvent });
         guestRegistrant = { ...guestRegistrant, registeredBy: currentUser?.id };
         guestRegistrant.isFree = true;
         return guestRegistrant;
      });
      newRegistrants = [...newRegistrants, ...guestRegistrants];
   }



   // console.log({ newRegistrants });
   return newRegistrants;
}

// Check if user is already booked for event
function isUserBooked({ currentUser, selectedEvent }) {
   return currentUser?.registrant?.registeredEvents?.some((event) => event?.id === selectedEvent?.id);
}

// Add booked registrant to   


export {
   getRegistrantsCount,
   generateTicketNumber,
   turnUserToRegistrant,
   turnGuestToRegistrant,
   updatePaidEventWithNewRegistrant,
   updateFreeEventWithNewRegistrant,
   compileRegistrantsForPaidEvent,
   compileRegistrantsForFreeEvent,
   isUserBooked,
};

