import { createContext, useState, useContext, useEffect } from "react";
import { db } from "../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";

export const RolesContext = createContext([]);

export const RolesProvider = ({ children }) => {
   const [roles, setRoles] = useState([]);

   useEffect(() => {
      const unsubscribe = onSnapshot(collection(db, "roles"), (snapshot) => {
         const roles = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
         }));
         setRoles(roles);
      });
      return () => unsubscribe();
   }, []);
};