import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { AddResource } from "../forms/AddResource";
import { ResourcesList } from "../../components/ResourcesList";


import { useState, useEffect } from "react";

import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

//Context
import { RolesContext } from "../../components/RolesContext";

export const Resourses = () => {
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [file, setFile] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/Resources");
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "resources"), (snapshot) => {
      const resources = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setResources(resources);
      const tagsList = [];
      resources.forEach((resource) => {
        resource.tags.forEach((tag) => {
          if (!tagsList.includes(tag)) {
            tagsList.push(tag);
          }
        });
      });
      setTags(tagsList);
      console.log({ tagsList });
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <ResponsiveNavbar />
      <RolesContext.Provider value={{ selectedRoles, setSelectedRoles }}>
        <Container
          fluid
          style={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            marginTop: "9%",
          }}
        >
          <AddResource
            tags={tags}
            setTags={setTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          {/* <Col>
              <h3 className="header text-center">Resourse</h3>
              <ResourcesList resources={resources} />
            </Col> */}
        </Container>
      </RolesContext.Provider>
    </>
  );
};
