import React, { useRef } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

const libraries = ['places'];

export const GoogleAutoComplete = ({
   address,
   setAddress,
   latitude,
   setLatitude,
   longitude,
   setLongitude,
   venueName,
   setVenueName,
   handleAddressChange,

}) => {
   return (
      <GooglePlacesAutocomplete
         apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
         selectProps={{
            placeholder: 'Search Location...',
            onChange: function (selectedOption) {
               console.log(selectedOption);
               setAddress(selectedOption.value.description);
               console.log(selectedOption.value.structured_formatting?.main_text);
               setVenueName(selectedOption.value.structured_formatting?.main_text);
               geocodeByPlaceId(selectedOption.value.place_id)
                  .then(results => {
                     let lat = results[0].geometry.location.lat();
                     let lng = results[0].geometry.location.lng();
                     setLatitude(lat);
                     setLongitude(lng);

                     console.log({
                        lat,
                        lng
                     });
                  })
                  .catch(error => console.error(error));
               console.log({ address: address, latitude: latitude, longitude: longitude, venueName: venueName });
            }
         }}
         debounce={1000}
      />
   );
};