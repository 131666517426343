import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Timestamp, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { useSnackbar } from "notistack";
import ProgressBar from 'react-bootstrap/ProgressBar';


//helpers
import { parseYoutubeLink } from '../../helpers/youtube';

export const AddCourse = () => {
  const MySwal = withReactContent(Swal);
  const [course, setCourse] = useState({
    name: "",
    teacher: "",
    createdAt: "",
    chapters: [],
    description: "",
    posterLink: "",
    portraitPosterLink: "",
    registeredUsers: [],
    venue: "",
  });

  let { enqueueSnackbar } = useSnackbar();

  let posterLinkFile = null;
  let portraitPosterLinkFile = null;

  const [posterLinkUploadProgress, setPosterLinkUploadProgress] = useState(null);
  const [portraitPosterLinkUploadProgress, setPortraitPosterLinkUploadProgress] = useState(null);

  const posterLinkInput = useRef(null);
  const portraitPosterLinkInput = useRef(null);

  const handleChange = (e) => {
    e.preventDefault();
    console.log(course);
    if (e.target.name === "posterLink") {
      posterLinkFile = e.target.files[0];
      if (posterLinkFile && posterLinkFile.size > 1000000) {
        enqueueSnackbar("Poster image size should be less than 1MB", {
          variant: "warning",
        });
        return;
      }

      handlePosterUpload(posterLinkFile);
      console.log(course);
    }

    if (e.target.name === "portraitPosterLink") {
      portraitPosterLinkFile = e.target.files[0];
      if (portraitPosterLinkFile && portraitPosterLinkFile.size > 1000000) {
        enqueueSnackbar("Portrait Poster image size should be less than 1MB", {
          variant: "warning",
        });
        return;
      }

      handlePortraitPosterUpload(portraitPosterLinkFile);
      console.log(course);
    }

    if (e.target.name === "url") {
      const videoId = e.target.value;

      setCourse({
        ...course,
        [e.target.name]: videoId,
      });
      console.log(course);
    } else {
      setCourse({
        ...course,
        [e.target.name]: e.target.value,
      });
    }

  };

  const submitCourse = async (e) => {
    e.preventDefault();
    try {
      setCourse({
        ...course,
        createdAt: new Date(),
      });
      await addDoc(collection(db, "courses"), { ...course, createdAt: serverTimestamp() });

      MySwal.fire({
        title: "Success!",
        text: "Course has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  async function handlePosterUpload(file) {
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `courses/auto-uploaded/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPosterLinkUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setCourse({
            ...course,
            posterLink: downloadURL,
          });

          enqueueSnackbar("Cover photo uploaded successfully", {
            variant: "success",
          });
          setPosterLinkUploadProgress(null);
        });
      }
    );
  };

  async function handlePortraitPosterUpload(file) {
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `courses/auto-uploaded/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setPortraitPosterLinkUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setCourse({
            ...course,
            portraitPosterLink: downloadURL,
          });

          enqueueSnackbar("Portrait cover photo uploaded successfully", {
            variant: "success",
          });

          setPortraitPosterLinkUploadProgress(null);
        });
      }
    );
  };

  return (
    <Form onSubmit={submitCourse}>
      <Row>
        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Course Name
          </Form.Label>
          <Form.Control
            type="text"
            defaultValue={course.name}
            name="name"
            placeholder="title"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Teacher
          </Form.Label>
          <Form.Control
            type="text"
            name="teacher"
            defaultValue={course.teacher}
            placeholder="teacher"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Youtube Link For Introduction
          </Form.Label>
          <Form.Control
            type="text"
            name="url"
            defaultValue={course.url}
            placeholder="ID"
            onChange={handleChange}
          />
        </Form.Group>
        {
          posterLinkUploadProgress ? (<ProgressBar variant="info" now={posterLinkUploadProgress} label={`${parseInt(posterLinkUploadProgress, 10)}%`} />) : null
        }
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }}>Course Cover Photo in Landscape</Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          <input
            required
            ref={posterLinkInput}
            encType="multipart/form-data"
            type="file"
            name="posterLink"
            accept="image/*"
            onChange={(e) => handleChange(e)}
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #000",
              borderRadius: "5px",
              padding: "10px",
              color: "#000",
              fontSize: "16px",
              outline: "none",
              cursor: "pointer"
            }}
          />
        </Form.Group>
        {
          portraitPosterLinkUploadProgress ? (<ProgressBar variant="info" now={portraitPosterLinkUploadProgress} label={`${parseInt(portraitPosterLinkUploadProgress, 10)}%`} />) : null
        }
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label style={{
            color: "black"
          }}>Course Cover Photo in Portrait</Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          <input
            required
            ref={portraitPosterLinkInput}
            encType="multipart/form-data"
            type="file"
            name="portraitPosterLink"
            accept="image/*"
            onChange={(e) => handleChange(e)}
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #000",
              borderRadius: "5px",
              padding: "10px",
              color: "#000",
              fontSize: "16px",
              outline: "none",
              cursor: "pointer"
            }}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Venue
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            name="venue"
            defaultValue={course.venue}
            rows={3}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Course Description
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            name="description"
            defaultValue={course.description}
            rows={6}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row>
        <Button
          style={{
            width: "80%",
            margin: "auto",
          }}
          variant="primary"
          type="submit"
          className="btn-primary"
        >
          Submit
        </Button>
      </Row>
    </Form>
  );
};
