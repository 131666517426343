import { createTheme } from "@mui/material/styles";

const DarkTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    mode: "dark",
    primary: {
      dark: "#1c2c4c",
      main: "#fff",
      light: "#14213dff",
    },
    secondary: {
      main: "#14213dff",
      dark: "#10192e",
      light: "#1c2c4f",
    },
    accent: {
      main: "#fca311ff",
      primary: "#fca311ff",
      secondary: "#1270fd",
    },
    error: {
      main: "#dd0e0e",
      dark: "#b50d0d",
      light: "#f35e5e",
    },
    warning: {
      main: "#f4bb2a",
      dark: "#cb9306",
      light: "#f7bc26",
    },
    info: {
      main: "#1899aa",
      light: "#15c8e0",
      dark: "#146f7b",
    },
    success: {
      main: "#24a328",
      light: "#3adf3f",
      dark: "#136716",
    },
    text: {
      main: "#ffffff",
      dark: "#fca311ff",
      faded: "#b3b3b3",
    },
  },
});

export default DarkTheme;

// --black: #000000ff;
// --oxford-blue: #14213dff;
// --orange-web: #fca311ff;
// --platinum: #e5e5e5ff;
// --white: #ffffffff;
