// Mui
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import ReactLoading from "react-loading";

const RegistrationPaymentLoading = () => {
   return (
      <Container maxWidth="sm">
         <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <Stack spacing={2}>
               <Typography variant="h6" align="center">
                  Please wait while we process your registration. Keep this page open until you see the confirmation page to ensure your registration is complete.
               </Typography>
               <Typography variant="p" align="center">
                  Don't forget to
               </Typography>
               <ReactLoading type="spin" color="#000" height={100} width={100} />
               <Button variant="contained" color="primary" href="/registration">
                  Back to Registration
               </Button>
            </Stack>
         </Paper>
      </Container>
   )
}

const LoadingScreen = () => {
   return (
      <Container
         style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            display: "flex",
         }}
      >
         <Typography variant="h6" align="center">
            Please wait while we process your registration. Keep this page open until your Registration Ticket generates.
         </Typography>
         <ReactLoading type={"bubbles"} color={"blue"} height={100} width={100} />
      </Container>
   )
}

export { RegistrationPaymentLoading, LoadingScreen };