import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CourseSelection } from "./SelectionMenu";

import { useState } from "react";

import { db } from "../configs/firebase";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { Container } from "react-bootstrap";

const MySwal = withReactContent(Swal);

export const AddChapterModal = ({
  courses,
  course,
  setCourse,
  chapter,
  setChapter,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    event.preventDefault();
    setChapter({
      ...chapter,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const date = new Date();
    const timestamp = new Timestamp(Math.floor(date.getTime() / 1000), 0);
    try {
      setChapter({
        ...chapter,
        createdAt: timestamp,
      });
      const chapterRef = doc(db, "courses", course.id);
      updateDoc(chapterRef, {
        chapters: [...course.chapters, chapter],
      });
      MySwal.fire({
        title: "Success!",
        text: "Chapter has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
      setShow(false);
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-primary"
        style={{
          width: "80%",
          margin: "auto",
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5%"
        }}
      >
        Add Chapter
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Chapter to {"CourseName"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CourseSelection
            course={course}
            setCourse={setCourse}
            courses={courses}
          />
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Chapter Title</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                name="title"
                placeholder="title"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Lesson Youtube Video URL/ID</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                name="video"
                placeholder="ID"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Resources Google Drive/One Drive Link</Form.Label>
              <Form.Control
                type="text"
                autoFocus
                name="resources"
                placeholder="resources"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                name="notes"
                placeholder="notes"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit Chapter
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
