import Card from "react-bootstrap/Card";

export const CourseCard = ({ courses, course, style }) => {
  return (
    <Card style={{ width: "15rem", ...style }}>
      <Card.Img
        variant="top"
        src={course.posterLink}
        style={{
          height: "100px",
          objectFit: "cover",
        }}
      />
      <Card.Body
        style={{
          height: "150px",
        }}
      >
        <Card.Title
          style={{
            fontSize: "0.8rem",
            fontWeight: "bold",
            marginTop: "-10px",
          }}
        >
          {course.name}
        </Card.Title>
        <Card.Text
          style={{
            height: "40px",
            fontSize: "0.7rem",
            marginTop: "-10px",
          }}
        >
          {course?.description ? course?.description?.slice(0, 100) : ""}...
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
