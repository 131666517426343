import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConnectGroupSelection } from "./ConnectGroupSelection";

import { useState } from "react";

import { db } from "../configs/firebase";
import { doc, updateDoc, Timestamp } from "firebase/firestore";

const MySwal = withReactContent(Swal);

export const AddMemberModal = ({
  ConnectGroups,
  connectGroup,
  setConnectGroup,
  member,
  setMember,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleChange = (event) => {
  //   event.preventDefault();
  //   setMember({
  //     ...member,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const date = new Date();
    const timestamp = new Timestamp(Math.floor(date.getTime() / 1000), 0);
    try {
      setMember({
        ...member,
        createdAt: timestamp,
      });
      const memberRef = doc(db, "connect-groups", connectGroup.id);
      updateDoc(memberRef, {
        members: [...connectGroup.members, member],
      });
      MySwal.fire({
        title: "Success!",
        text: "Member has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
      setShow(false);
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn btn-primary"
        style={{
          width: "80%",
          margin: "10px auto 0 auto",
          position: "absolute",
          top: "30px",
          left: "80px",
        }}
      >
        Add member
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New member to {"connectGroupName"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConnectGroupSelection
            connectGroup={connectGroup}
            setConnectGroup={setConnectGroup}
            ConnectGroups={ConnectGroups}
          />
          <Form>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
