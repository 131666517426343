import { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, auth } from "../../configs/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Alert } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

export default function useForm(callback, validate) {
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validate(values));
    if (location.pathname === "/SignUp") {
      setIsSubmitting(true);
      try {
        await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        ).then((response) => {
          const usersRef = doc(db, "users", response.user.uid);
          const userRef = setDoc(usersRef, {
            username: values.username,
            email: values.email,
            password: values.password,
            password2: values.password2,
            role: "admin",
          });
          localStorage.setItem(
            "Auth Token",
            response._tokenResponse.refreshToken
          );
          localStorage.setItem("user", response.user);
          navigate("/");
        });
      } catch (error) {
        return (
          <>
            <Alert severity="error">Signup Failed!</Alert>
          </>
        );
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
      try {
        signInWithEmailAndPassword(auth, values.email, values.password).then(
          (response) => {
            //Get userID from users collection
            const usersRef = doc(db, "users", response.user.uid);
            getDoc(usersRef).then((doc) => {
              if (doc.data().role === "admin") {
                localStorage.setItem(
                  "Auth Token",
                  response._tokenResponse.refreshToken
                );
                localStorage.setItem(
                  "user",
                  JSON.stringify({ ...response.user, uid: response.user.uid })
                );
                navigate("/");
              } else {
                localStorage.setItem("Auth Token", "");
                navigate("/UnAuthorized");
              }
            });
          }
        );
      } catch (error) {
        return (
          <>
            <Alert severity="error">Login Failed!</Alert>
          </>
        );
      }
      setIsSubmitting(false);
    }
  };
  return { handleChange, handleSubmit, values, errors };
}
