import React, { useContext, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  SubmitButton,
  LightSubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";

// Notistack
import { useSnackbar } from "notistack";

// SweetAlert
import Swal from "sweetalert2";

// firebase
import { auth } from "../../configs/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
//context
import { ChurchContext } from "../../contexts/ChurchContext";

export function SignupForm({ submitForm }) {
  const { switchToSignin } = useContext(AccountContext);
  const { enqueueSnackbar } = useSnackbar();
  const { church } = useContext(ChurchContext);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };
  const handlePasswordReset = (e) => {
    e.preventDefault();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar("Password reset email sent", {
          variant: "success",
        });
        Swal.fire({
          title: "Password Reset Email Sent!",
          text: "Please check your email, and follow the instructions to reset your password. Once you have reset your password, you can login with your new password.",
          icon: "success",
          confirmButtonText: "Login Now",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Close",
          backdrop: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: true,
          background: "#fff",
          confirmButtonColor: "#308",
        }).then((result) => {
          if (result.isConfirmed) {
            switchToSignin();
          }
        });
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          enqueueSnackbar("No user found with that email", {
            variant: "error",
          });
          Swal.fire({
            title: "User Not Found",
            text: "No user with that email was found. Please make sure your email address is correct and try again.",
            icon: "error",
            confirmButtonText: "Try Again",
            showConfirmButton: true,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: true,
            background: "#fff",
            confirmButtonColor: "#308",
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      });

    setEmail("");
  };


  return (
    <BoxContainer>
      <FormContainer
        onSubmit={handlePasswordReset}
        noValidate
      >
        <Input
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </FormContainer>
      <Marginer direction="vertical" margin={40} />
      <LightSubmitButton onClick={handlePasswordReset}>Send Email</LightSubmitButton>
      <Marginer direction="vertical" margin="1em" />
      <MutedLink
        onClick={switchToSignin}
      >
        Already have an account?
        <BoldLink onClick={switchToSignin}>
          Signin
        </BoldLink>
        <Marginer direction="vertical" margin="8em" />
      </MutedLink>
      {/* Copyright */}
      <p
        style={{
          color: "#cccccc",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        {`© ${church?.name ? church.name : ""} ${new Date().getFullYear()}`}
      </p>
    </BoxContainer>
  );
}
