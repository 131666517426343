import React, { createContext, useState, useEffect } from 'react';
//firebase
import { auth, db } from "../configs/firebase";
import { onSnapshot, collection } from "firebase/firestore";

export const ChurchContext = createContext(null);

export const ChurchProvider = ({ children }) => {
   const [church, setChurch] = useState(null);

   useEffect(() => {
      const unsubscribe = onSnapshot(collection(db, "church"), (snapshot) => {
         const churchList = snapshot.docs.map((churchItem) => {
            return {
               ...churchItem.data(),
               id: churchItem.id,
            };
         });
         setChurch(churchList[0]);
      });
      return () => unsubscribe();
   }, []);



   return (
      <ChurchContext.Provider
         value={{ church, setChurch }
         }>
         {children}
      </ChurchContext.Provider>
   );
}