import "./Counters.css";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
export const Counter = ({ variable, quantity, styles, icon }) => {
  return (
    <Col xs={12} md={6} lg={4}>
      <Link to={variable !== "Events" ? `/${variable?.replace(/\s+/g, "")}` : `/EventRegistrationDashboard`}>
        <div
          style={{
            position: "relative",
            ...styles,
          }}
          className="card-counter primary"
        >
          {icon}
          <span
            style={{
              position: "absolute",
              right: "35px",
              top: "20px",
              fontSize: "32px",
              display: "block",
              color: "#ffffff",
            }}
          >
            {quantity}
          </span>
          <span className="count-name">{variable}</span>
        </div>
      </Link>
    </Col>
  );
};
