import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import { AddSermon } from "../forms/AddSermon";
// import { SermonsStack } from "../../components/SermonsStack";
// import { SermonsStackTwo } from "../../components/SermonsStackTwo";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";

import { useState, useEffect } from "react";
import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";

import { useNavigate } from "react-router-dom";
import { ChurchContext } from '../../contexts/ChurchContext';

export const Sermons = () => {
  //   const [sermon, setSermon] = useState(null);
  const [sermons, setSermons] = useState([]);
  const [church, setChurch] = useState(null);

  let navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/Sermons");
    }

    if (!authToken) {
      navigate("/Login");
    }
  }, [navigate]);

  // useEffect(() => {
  //   const unsubscribe = onSnapshot(collection(db, "sermons"), (snapshot) => {
  //     const sermonsList = snapshot.docs.map((sermonItem) => {
  //       return {
  //         ...sermonItem.data(),
  //         id: sermonItem.id,
  //       };
  //     });
  //     setSermons(sermonsList);
  //   });
  //   return () => unsubscribe();
  // }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "church"), (snapshot) => {
      const churchList = snapshot.docs.map((churchItem) => {
        return {
          ...churchItem.data(),
          id: churchItem.id,
        };
      });
      setChurch(churchList[0]);
    });
    return () => unsubscribe();
  }, []);

  return (
    <ChurchContext.Provider value={{ church, setChurch }}>
      <ResponsiveNavbar />
      <Container
        fluid
        style={{
          width: "60%",
          marginTop: "5%",
        }}
      >
        <AddSermon />
      </Container>
    </ChurchContext.Provider>
  );
};
