import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import SignUpForm from "../forms/SignUpForm";
import { AccountBox } from "../../components/accountBox";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export const SignUp = () => {
  // const [church, setChurch] = useState({
  //   name: "COAH",
  // });
  let navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/SignUp");
    }

    if (!authToken) {
      navigate("/Login");
    }
  }, [navigate]);
  return (
    <>
      <ResponsiveNavbar />
      <SignUpForm />
    </>
  );
};
