import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from 'react-bootstrap/ProgressBar';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";

import {
  Timestamp,
  collection,
  addDoc,
  GeoPoint
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../configs/firebase";

import { AddGroupLeaderModal } from "../../components/AddGroupLeaderModal";

export const AddConnectGroup = () => {
  const MySwal = withReactContent(Swal);

  const { enqueueSnackbar } = useSnackbar();
  let imageFile = null;
  const coverPhotoRef = useRef(null);
  const [imageUploadProgress, setImageUploadProgress] = useState(null);

  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
  });
  const [formInput, setFormInput] = useState({
    name: "",
    leaders: [],
    description: "",
    posterLink: "",
    street: "",
    suburb: "",
    city: "",
    province: "",
    state: "",
    country: "",
    zip: "",
  });
  const [validated, setValidated] = useState(false);
  const formRef = useRef(null);

  const handleChange = (e) => {
    if (e.target.name === "latitude" || e.target.name === "longitude") {
      setCoordinates({
        ...coordinates,
        [e.target.name]: e.target.value,
      });
    }
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    formRef.current.reset();
    setValidated(false);
  };
  const submitConnectGroup = async (e) => {
    e.preventDefault();
    const date = new Timestamp(Math.floor(Date.now() / 1000), 0);
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      await addDoc(collection(db, "connect-groups"), {
        name: formInput.name,
        leaders: formInput.leaders,
        contactEmail: formInput.contactEmail,
        contactNumber: formInput.contactNumber,
        members: [],
        anouncements: [],
        description: formInput.description,
        posterLink: formInput.posterLink,
        venue: {
          street: formInput.street,
          suburb: formInput.suburb,
          city: formInput.city,
          province: formInput.province,
          country: formInput.country,
          zip: formInput.zip,
          coordinates: coordinates.latitude && coordinates.longitude ? new GeoPoint(
            parseFloat(coordinates.latitude),
            parseFloat(coordinates.longitude)
          ) : null,
        },
        createdAt: date,
        createdBy: user.uid,
      }).then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      });
      setValidated(true);
      handleReset();

      MySwal.fire({
        title: "Success!",
        text: "Connect Group has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (err) {
      MySwal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  async function handleCoverPhotoUpload(file) {
    if (!file) {
      MySwal.fire({
        title: "Warning!",
        text: "Please select a cover image",
        icon: "warning",
        confirmButtonText: "CLOSE",
      });
      return;
    }

    if (file.size > 1000000) {
      enqueueSnackbar("Image file size should be less than 1MB", {
        variant: "warning",
      });
      return;
    }
    const fileName = `${new Date().getTime()}_${file.name}`;
    const storageRef = ref(storage, `newsletters/cover-images/${fileName}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImageUploadProgress(progress);
      },
      (error) => {
        console.log(error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormInput({
            ...formInput,
            posterLink: downloadURL
          })

          enqueueSnackbar("Cover photo uploaded successfully", {
            variant: "success",
          });
          setImageUploadProgress(null);
        });
      }
    );
  };

  return (
    <Form
      onSubmit={submitConnectGroup}
      ref={formRef}
      validated={validated}
      style={{
        width: "80%",
        height: "90vh",
        marginTop: "50px",
        marginBottom: "1rem",
        marginLeft: "10%",
        padding: "5%",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <Row>
        <Col>
          <Form.Group className="mb-1">
            <Form.Control
              type="text"
              defaultValue={formInput.name}
              name="name"
              placeholder="Group Name"
              onChange={handleChange}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            <AddGroupLeaderModal
              setConnectGroup={setFormInput}
              connectGroup={formInput}
            />
          </Form.Group>
        </Col>

        <Form.Group className="mb-1">
          <Form.Label style={{ color: '#000' }}>Group Contact Email</Form.Label>
          <Form.Control
            type="email"
            defaultValue={formInput.contactEmail}
            name="contactEmail"
            placeholder="Email"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-1">
          <Form.Label style={{ color: '#000' }}>Group Contact Number</Form.Label>
          <Form.Control
            type="tel"
            name="contactNumber"
            defaultValue={formInput.contactNumber}
            placeholder="Phone Number"
            onChange={handleChange}
            required
          />
        </Form.Group>

        {
          imageUploadProgress ? (<ProgressBar now={imageUploadProgress} label={`${parseInt(imageUploadProgress, 10)}%`} />) : null
        }
        <Form.Group className="mb-3" >
          <Form.Label style={{
            color: "black"
          }}>
            Cover Photo in Landscape
          </Form.Label>
          {/* Upload Cover Image to Firebase Firestore */}
          <input
            required
            ref={coverPhotoRef}
            encType="multipart/form-data"
            type="file"
            name="coverImage"
            id="coverImage"
            accept="image/*"
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #000",
              borderRadius: "5px",
              padding: "10px",
              color: "#000",
              fontSize: "16px",
              outline: "none",
              cursor: "pointer"
            }}
            onChange={e => {
              imageFile = e.target.files[0];
              handleCoverPhotoUpload(imageFile);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label
            style={{
              color: "black",
              fontSize: "1rem",
              marginTop: "-10px",
              fontWeight: "500",
            }}
          >
            Description
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            name="description"
            placeholder="Write a brief description about the group..."
            defaultValue={formInput.description}
            rows={6}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Label
          style={{
            color: "black",
            fontSize: "1rem",
            margin: "auto",
            fontWeight: "500",
          }}
        >
          Venue
        </Form.Label>
      </Row>

      <Form.Group className="mb-1">
        <Row>
          <Col>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                name="latitude"
                defaultValue={coordinates.latitude}
                placeholder="latitude"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                name="longitude"
                defaultValue={coordinates.longitude}
                placeholder="longitude"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                name="street"
                defaultValue={formInput.street}
                placeholder="Street"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                name="suburb"
                defaultValue={formInput.suburb}
                placeholder="Suburb"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                required
                name="city"
                defaultValue={formInput.city}
                placeholder="City"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                required
                name="province"
                defaultValue={formInput.province}
                placeholder="Province"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                required
                name="country"
                defaultValue={formInput.country}
                placeholder="Country"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                required
                name="zip"
                defaultValue={formInput.zip}
                placeholder="Zip"
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>
      <Row>
        <Button
          style={{
            width: "97%",
            margin: "auto",
            marginTop: "20px",
            fontSize: "1.2rem",
          }}
          disabled={!((formInput.leaders.length > 0) && formInput.posterLink)}
          variant="primary"
          type="submit"
          className="btn-primary"
        >
          Create Group
        </Button>
      </Row>
    </Form>
  );
};
