import { LoginForm } from "../forms/LoginForm";
import { AccountBox } from "../../components/accountBox";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export const Login = () => {
  let navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");

    if (authToken) {
      navigate("/");
    }

    if (!authToken) {
      navigate("/Login");
    }
  }, [navigate]);

  return (
    // <LoginForm
    //   submitForm={() => {
    //     console.log("Login form submitted");
    //   }}
    // />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: "0 auto",
      }}
    >
      <AccountBox />
    </div>
  );
};
