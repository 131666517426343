import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from '../../components/ResponsiveNavbar';
import { Form, Button, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { AddEvent } from '../forms/AddEvent';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";

import Select from "react-select";
import countries from "countries-list";


import { storage } from '../../configs/firebase';
import { db, auth } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const CreateNewEvent = () => {
   const navigate = useNavigate();
   const { enqueueSnackbar } = useSnackbar();

   const [eventTitle, setEventTitle] = useState("");
   const [countriesList, setCountriesList] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState("");
   const [event, setEvent] = useState({
      title: "",
      description: "",
      image: "",
      type: "Conference",
      startingTime: "",
      endingTime: "",
      currency: {
         symbol: "R",
         ticker: "ZAR"
      },
      price: {
         adultsPrice: 0,
         childrenPrice: 0
      },
      contactDetails: {
         email: "",
         phone: "",
         contactPerson: ""
      },
      adultsAllowed: true,
      childrenAllowed: true,
      externalLink: "",
      externalRegistrationLink: "",
      externalRegistrationButton: "",
      isExternalRegistrationOnly: false,
      isPaid: true,
      isRegistrationActive: true,
      maxParticipants: 100,
      registrants: [],
      venue: {
         name: "",
         latitude: 0,
         longitude: 0,
         address: ""
      }


   });
   const [address, setAddress] = useState({
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
   });

   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/CreateNewEvent");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   useEffect(() => {
      const countryCodes = Object.keys(countries.countries);
      const countryNames = countryCodes.map(code => {
         return {
            name: countries.countries[code].name,
            code: code,
            emoji: countries.countries[code].emoji,
            emojiU: countries.countries[code].emojiU
         }
      });
      console.log(countryNames);
      setCountriesList(countryNames.sort());
   }, []);


   const handleSubmit = async (e) => {
      e.preventDefault();
      let validAddress = {
         ...address,
         country: selectedCountry.value
      }
      console.log(validAddress);

      if (validAddress.street === "" || validAddress.city === "" || validAddress.state === "" || validAddress.country === "" || validAddress.zip === "" || eventTitle === "") {
         MySwal.fire({
            title: "Fields cannot be empty!",
            text: "Please fill in all the fields",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
      } else {
         console.log("Creating Doc")
         await addDoc(collection(db, "locations"), {
            ...validAddress,
            name: eventTitle
         }).then(() => {
            MySwal.fire({
               title: "New Branch Added",
               text: "New Location Added Successfully!",
               icon: "success",
               confirmButtonText: "CLOSE",
            });
         })
            .catch((error) => {
               console.log(error);
               MySwal.fire({
                  title: "Failed To Add New Location",
                  text: error.message,
                  icon: "error",
                  confirmButtonText: "CLOSE",
               });
            });
      }
   }

   return (
      <>
         <ResponsiveNavbar />
         <AddEvent />
         <Form
            onSubmit={handleSubmit}
            style={{
               width: "50%",
               margin: "4% auto",
               padding: "3%",
               backgroundColor: "white",
               borderRadius: "10px",
               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
               marginTop: "10%",
            }}
         >
            <h3 className="text-center mt-1">Add New Branch</h3>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Branch Name
               </Form.Label>
               <Form.Control
                  id="nameId"
                  type="text"
                  placeholder="Name"
                  required
                  value={eventTitle}
                  onChange={
                     (e) => {
                        setEventTitle(e.target.value);
                     }
                  }

               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Street
               </Form.Label>
               <Form.Control
                  id="streetId"
                  type="text"
                  placeholder="Street"
                  required
                  value={address.street}
                  onChange={
                     (e) => {
                        setAddress({ ...address, street: e.target.value });
                     }
                  }
               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  City
               </Form.Label>
               <Form.Control
                  id="cityId"
                  type="text"
                  placeholder="City"
                  required
                  value={address.city}
                  onChange={
                     (e) => {
                        setAddress({ ...address, city: e.target.value });
                     }
                  }
               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  State/Province
               </Form.Label>
               <Form.Control
                  id="stateId"
                  type="text"
                  placeholder="State/Province"
                  required
                  value={address.state}
                  onChange={
                     (e) => {
                        setAddress({ ...address, state: e.target.value });
                     }
                  }
               />
            </Form.Group>
            <Form.Group className="mb-3">
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Postal Code
               </Form.Label>
               <Form.Control
                  id="postalCodeId"
                  type="text"
                  placeholder="Zip Code"
                  required
                  value={address.zip}
                  onChange={
                     (e) => {
                        setAddress({ ...address, zip: e.target.value });
                     }
                  }
               />
            </Form.Group>

            <Select
               options={[
                  { value: "Select Country", label: "Select Country" },
                  ...countriesList.map((country) => {
                     return {
                        value: country.name,
                        label: country.name,
                     };
                  })
               ]}

               isClearable={true}
               isSearchable={true}
               backspaceRemovesValue={true}
               captureMenuScroll={true}
               autoFocus={true}
               required
               onChange={(selected) => {
                  if (selected === "Select Country") {
                     return;
                  } else {
                     setSelectedCountry(selected);
                  }
               }}
               placeholder="Select Country"
               name="country"
               value={selectedCountry}
            />
            <Button
               variant="primary"
               type={"submit"}
               className="btn btn-block"
               style={{
                  width: "50%",
                  height: "10%",
                  fontSize: "1.2rem",
                  marginLeft: "50%",
                  marginTop: "5%",
               }}>
               Submit
            </Button>
         </Form>
      </>
   );
};