import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { AddBanner } from "../forms/AddBanner";
import { BannersList } from "../../components/BannersList";

import { useState, useEffect } from "react";

import { db } from "../../configs/firebase";
import { collection, onSnapshot } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

export const BannerCampaigns = () => {
   const [banners, setBanners] = useState([]);
   const navigate = useNavigate();
   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/BannerCampaigns");
      } else {
         navigate("/Login");
      }
   }, []);

   useEffect(() => {
      const unsubscribe = onSnapshot(
         collection(db, "banners"),
         (snapshot) => {
            const bannersList = snapshot.docs.map((bannerItem) => {
               return {
                  ...bannerItem.data(),
                  id: bannerItem.id,
               };
            });
            setBanners(bannersList);
         }
      );
      return () => unsubscribe();
   }, []);

   return (
      <>
         <ResponsiveNavbar />
         <Container
            fluid
            style={{
               width: "100%",
               backgroundColor: "#f5f5f5",
               marginTop: "5%",
            }}
         >
            <Row>
               <Col>
                  <h3 className="header text-center">Post a new Banner</h3>
                  <AddBanner />
               </Col>
               <Col>
                  <h3 className="header text-center">Banners</h3>
                  <BannersList banners={banners} />
               </Col>
            </Row>
         </Container>
      </>
   );
};