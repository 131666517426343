import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { AiOutlineFolderOpen } from "react-icons/ai";
import Badge from "react-bootstrap/Badge";

export const NotificationsListItem = ({ notification }) => {

  return (
    <ListGroup.Item>
      <Card>
        <Card.Header>{notification.title}</Card.Header>
        <Card.Body>
          <Card.Title
            style={{
              fontSize: "0.8rem",
              width: "50%",
            }}
          >
            {notification?.title}
          </Card.Title>
          <Card.Text>{notification.message.slice(0, 150)}</Card.Text>
          {notification?.data?.notificationLink ? (
            <Card.Link
              variant="primary"
              href={notification.data.notificationLink}
              className="btn btn-primary"
              style={{
                opacity: "0.9",
                height: "20px",
                width: "40px",
                position: "relative",
              }}
              hover={{
                opacity: "1",
              }}
            >
              <AiOutlineFolderOpen
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  position: "absolute",
                  left: "33%",
                  top: "11%",
                }}
              />
            </Card.Link>
          ) : null}
          <Card.Text>
            {notification.targetRoles ? notification.targetRoles.map((tag, index) => {
              return (
                <Badge
                  pill
                  bg="primary"
                  style={{
                    marginRight: "0.5rem",
                  }}
                  key={index}
                >
                  {tag}
                </Badge>
              );
            }) : null}
          </Card.Text>
        </Card.Body>
      </Card>
    </ListGroup.Item>
  );
};
