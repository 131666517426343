import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Swal from "sweetalert2";


export const AddGroupLeaderModal = ({
   setConnectGroup,
   connectGroup,
}) => {
   const [show, setShow] = useState(false);
   // const [leaders, setLeaders] = useState([]);
   const [leader, setLeader] = useState(null);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);


   const handleChange = (event) => {
      event.preventDefault();
      if (event.target.value !== "") {
         setLeader({
            ...leader,
            [event.target.name]: event.target.value,
         });
      }
   };

   const handleAddLeader = (event) => {
      event.preventDefault();
      setConnectGroup({
         ...connectGroup,
         leaders: [...connectGroup.leaders, leader],
      });

      Swal.fire({
         title: "Success!",
         text: "Leader has been added",
         icon: "success",
         confirmButtonText: "OK",
      })

      handleClose();
   }
   return (
      <>
         <Button
            variant="primary"
            onClick={handleShow}
            className="btn btn-primary"
            style={{
               width: "100%",
            }}
         >
            Add Leader
         </Button>

         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Add New Chapter to {"CourseName"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label style={{ color: '#000' }}>Name</Form.Label>
                  <Form.Control
                     type="text"
                     autoFocus
                     name="leaderName"
                     placeholder="name"
                     onChange={handleChange}
                     required
                  />
               </Form.Group>
               <Row>
                  <Col>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{ color: '#000' }}>Email</Form.Label>
                        <Form.Control
                           type="email"
                           autoFocus
                           name="email"
                           placeholder="email"
                           onChange={handleChange}
                           required
                        />
                     </Form.Group>

                  </Col>
                  <Col>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label style={{ color: '#000' }}>Phone Number</Form.Label>
                        <Form.Control
                           type="tel"
                           autoFocus
                           name="phone"
                           placeholder="tel"
                           onChange={handleChange}
                           required
                        />
                     </Form.Group>
                  </Col>
               </Row>
               <Button variant="primary" type="submit" onClick={handleAddLeader}>
                  Add Leader
               </Button>
            </Modal.Body>
            <Modal.Footer>
               <Button type="button" variant="secondary" onClick={handleClose}>
                  Close
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   );
};
