import React, { useState, useEffect, useRef } from 'react';
import Container from "react-bootstrap/Container";
import { NavigationBar } from "../../components/NavigationBar";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import { Form, Button, Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSnackbar } from "notistack";

import { storage } from '../../configs/firebase';
import { db, auth } from "../../configs/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, addDoc, Timestamp } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const PdfDevotional = () => {
   const { enqueueSnackbar } = useSnackbar();
   const [pdfUrl, setPdfUrl] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);
   const [scheduledAt, setScheduledAt] = useState(new Date());

   let pdfFile = null;
   let imageFile = null;

   const coverPhotoRef = useRef(null);
   const pdfRef = useRef(null);

   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");

   const [pdfUploadProgress, setPdfUploadProgress] = useState(null);
   const [imageUploadProgress, setImageUploadProgress] = useState(null);

   const navigate = useNavigate();
   useEffect(() => {
      let authToken = localStorage.getItem("Auth Token");

      if (authToken) {
         navigate("/PdfDevotional");
      } else {
         navigate("/Login");
      }
   }, [navigate]);

   const isReadyToSubmitForm = () => (imageUrl && pdfUrl && title && scheduledAt) ? true : false;

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (title === "") {
         MySwal.fire({
            title: "Warning!",
            text: "Please enter title",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      // scheduledAt should be greater than current date
      let today = new Date();
      if (scheduledAt < today) {
         MySwal.fire({
            title: "Warning!",
            text: "You can not schedule a devotional in the past",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      let pdfDevotional = {
         title: title,
         description: description,
         date: Timestamp.fromDate(new Date(scheduledAt)),
         pdfUrl: pdfUrl,
         coverImage: imageUrl,
      }

      if (pdfUrl && imageUrl) {
         console.log("Creating Doc")
         await addDoc(collection(db, "pdf-devotionals"), {
            ...pdfDevotional,
         }).then(() => {
            MySwal.fire({
               title: "Success!",
               text: "Devotional Published Successfully!",
               icon: "success",
               confirmButtonText: "CLOSE",
            });
         })
            .catch((error) => {
               console.log(error);
               MySwal.fire({
                  title: "Failed To Publish Devotional",
                  text: error.message,
                  icon: "error",
                  confirmButtonText: "CLOSE",
               });
            });
      } else {
         MySwal.fire({
            title: "File Upload Failed",
            text: "Please try again later or contact support for assistance.",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
      }
   }

   async function handleCoverPhotoUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a cover image",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 1024 * 1024 * 1) {
         enqueueSnackbar("Image file size should be less than 1MB", {
            variant: "warning",
         });
         return;
      }
      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `pdf-devotionals/cover-images/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress =
               (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setImageUrl(downloadURL);

               enqueueSnackbar("Cover photo uploaded successfully", {
                  variant: "success",
               });
               setImageUploadProgress(null);
            });
         }
      );
   };

   async function handlePdfDevotionalsUpload(file) {
      if (!file) {
         MySwal.fire({
            title: "Warning!",
            text: "Please select a pdf file",
            icon: "warning",
            confirmButtonText: "CLOSE",
         });
         return;
      }

      if (file.size > 1024 * 1024 * 15) {
         enqueueSnackbar("PDF file size should be less than 15MB", {
            variant: "warning",
         });
         return;
      }

      const fileName = `${new Date().getTime()}_${file.name}`;
      const storageRef = ref(storage, `pdf-devotionals/pdf-files/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
         "state_changed",
         (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPdfUploadProgress(progress);
         },
         (error) => {
            console.log(error);
            enqueueSnackbar(error.message, {
               variant: "error",
            });
         },
         () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
               setPdfUrl(downloadURL);

               enqueueSnackbar("Portrait cover photo uploaded successfully", {
                  variant: "success",
               });

               setPdfUploadProgress(null);
            });
         }
      );
   };

   return (
      <>
         <ResponsiveNavbar />
         <Form
            onSubmit={handleSubmit}
            style={{
               width: "50%",
               margin: "10% auto",
               padding: "3%",
               backgroundColor: "white",
               borderRadius: "10px",
               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
         >
            <h3 className="text-center mt-1">Publish Devotional</h3>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Title
               </Form.Label>
               <Form.Control
                  id="titleId"
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={
                     (e) => {
                        setTitle(e.target.value);
                     }
                  }

               />
            </Form.Group>
            <Form.Group className="mb-3" >
               <Form.Label
                  style={{
                     color: "black"
                  }}
               >
                  Scheduled At
               </Form.Label>
               <Form.Control
                  id="dateId"
                  type="date"
                  name='scheduledAt'
                  value={scheduledAt}
                  onChange={
                     (e) => {
                        setScheduledAt(e.target.value);
                        let dt = new Date(e.target.value);
                        console.log({
                           date: e.target.value,
                           dt: dt
                        });
                     }
                  }

               />
            </Form.Group>
            {
               pdfUploadProgress ? (<ProgressBar now={pdfUploadProgress} label={`${parseInt(pdfUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3">
               <Form.Label style={{
                  color: "black"
               }}>Devotional Pdf file</Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={pdfRef}
                  encType="multipart/form-data"
                  type="file"
                  name="pdfFile"
                  id="pdfFile"
                  accept=".pdf"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     pdfFile = e.target.files[0];
                     handlePdfDevotionalsUpload(pdfFile);
                  }}
               />
            </Form.Group>
            {
               imageUploadProgress ? (<ProgressBar now={imageUploadProgress} label={`${parseInt(imageUploadProgress, 10)}%`} />) : null
            }
            <Form.Group className="mb-3" >
               <Form.Label style={{
                  color: "black"
               }}>
                  Cover Photo in Landscape
               </Form.Label>
               {/* Upload Cover Image to Firebase Firestore */}
               <input
                  required
                  ref={coverPhotoRef}
                  encType="multipart/form-data"
                  type="file"
                  name="coverImage"
                  id="coverImage"
                  accept="image/*"
                  style={{
                     height: "50px",
                     width: "100%",
                     border: "1px solid #000",
                     borderRadius: "5px",
                     padding: "10px",
                     color: "#000",
                     fontSize: "16px",
                     outline: "none",
                     cursor: "pointer"
                  }}
                  onChange={e => {
                     imageFile = e.target.files[0];
                     handleCoverPhotoUpload(imageFile);
                  }}
               />
            </Form.Group>
            <Button
               variant="primary"
               type={"submit"}
               disabled={!isReadyToSubmitForm()}
               className="btn btn-block"
               style={{
                  width: "50%",
                  height: "10%",
                  fontSize: "1.2rem",
                  marginLeft: "50%",
               }}>
               Upload
            </Button>
         </Form>
      </>
   );
};

const Preview = () => {
   return (
      <div id="preview">
      </div>
   )
}