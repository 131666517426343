import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import fetch from 'node-fetch';
import _ from 'lodash';

import { ChurchContext } from '../contexts/ChurchContext';


export default function SelectSermonAudio({ sermon, setSermon }) {
   const [options, setOptions] = useState(null);
   const [audio, setAudio] = useState(sermon.sermonAudioUrl);
   const corsProxy = "https://cors.eu.org/";

   const { church } = useContext(ChurchContext);

   const handleChange = (selected) => {
      if (selected.value) {
         setAudio(selected.value);
         setSermon({
            ...sermon,
            sermonAudioUrl: selected.value,
         });
      } else {
         setSermon({
            ...sermon,
            sermonAudioUrl: null,
         });
      }
   }

   //Immediately update the sermon audio url when the sermon is changed
   useEffect(() => {
      if (sermon) {
         setSermon({
            ...sermon,
            sermonAudioUrl: sermon.sermonAudioUrl
         });
      }
   }, [audio]);

   useEffect(() => {
      const fetchRssFeed = async () => {
         if (church) {
            let feedURL = corsProxy.concat(church.audioSermons.rssFeedURL);
            let json;
            fetch(feedURL)
               .then(response => {
                  return response.text();
               })
               .then(data => {
                  let parser = new DOMParser();
                  let xml = parser.parseFromString(data, "text/xml");

                  json = xmlToJson(xml);

                  const entries = json.rss.channel.item.slice(0, 100).map(item => {
                     let titleKey = Object.keys(item.title)[0];
                     return {
                        label: _.startCase(item.title[titleKey].toLowerCase()),
                        value: item.enclosure['@attributes'].url,
                        // type: item.enclosure['@attributes'].type,
                        // length: item.enclosure['@attributes'].length

                     };
                  });
                  entries.unshift({ label: 'No Audio Selected', value: null });
                  setOptions(entries);
               })
               .catch(error => {
                  console.log('error', error);
               });
         }

      };

      if (church?.audioSermons?.rssFeedURL) {
         fetchRssFeed();
      } else {
         setOptions([{ label: 'No Audio Selected', value: null }]);
      }
   }, [church]);

   if (!options) {
      return (
         <p>Loading...</p>
      )
   }
   return (
      <Select
         options={options}
         name="sermonAudioUrl"
         isSearchable={true} s
         placeholder="Select Sermon Audio"
         autoFocus={true}
         onChange={handleChange}
         defaultValue={options[0]}
      />
   );
};

// Changes XML to JSON
function xmlToJson(xml) {

   // Create the return object
   var obj = {};

   if (xml.nodeType === 1) { // element
      // do attributes
      if (xml.attributes.length > 0) {
         obj["@attributes"] = {};
         for (var j = 0; j < xml.attributes.length; j++) {
            var attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
         }
      }
   } else if (xml.nodeType === 3) { // text
      obj = xml.nodeValue;
   }

   // do children
   if (xml.hasChildNodes()) {
      for (var i = 0; i < xml.childNodes.length; i++) {
         var item = xml.childNodes.item(i);
         var nodeName = item.nodeName;
         if (typeof (obj[nodeName]) == "undefined") {
            obj[nodeName] = xmlToJson(item);
         } else {
            if (typeof (obj[nodeName].push) == "undefined") {
               var old = obj[nodeName];
               obj[nodeName] = [];
               obj[nodeName].push(old);
            }
            obj[nodeName].push(xmlToJson(item));
         }
      }
   }
   return obj;
};
